import React from "react";
import Loader from "../../common/Loader";
import FormLogin from "../../subcompo/sections-login/FormLogin";
import FormReset from "../../subcompo/sections-login/FormReset";
import FormChangePass from "../../subcompo/sections-login/FormChangePass";

function LoginForm(props) {
  return (
    <div className="tab-content tab-content-login">
      <FormLogin getLoggedInUser={props.getLoggedInUser} />
      <FormReset />
      <FormChangePass />
      <Loader />
    </div>
  );
}

export default LoginForm;
