import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Modal } from "react-bootstrap";
import { Auth } from "aws-amplify";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
const {
  UploadDashboardFile,
} = require("../subcompo/dashboard-components/UploadDashboardFile.jsx");
const {
  DownloadDashboardFiles,
} = require("../subcompo/dashboard-components/DownloadDashboardFiles.jsx");
let ismobile = window.innerWidth < 1600;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: true,
      rowDataList: [],
      userName: "user",
      scrollLeft: false,
      scrollRight: true,
      domain_messageid_files: {},
    };
  }

  componentDidMount = async () => {
    sessionStorage.clear();
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_AGPSAGENT_GROUP)) {
          this.setState({ userName });
        }
        if (groups.includes(process.env.REACT_APP_AGPSADMIN_GROUP)) {
          this.setState({ userName });
        }
      }
    } catch (error) {}
    this.updateDisplay();
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  updateComment = (e, rowData) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let { selected } = this.props;

    let index = rowData.tableData.id;
    let requestBody = {};
    requestBody.domain = rowDataList[index].domain;
    requestBody.comment = e.target.value;
    requestBody.tab = selected;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateDiscountData = (rowData) => {
    let requestBody = {
      user_email_id: rowData?.userEmail,
      uuid_carrier: rowData?.uuid_carrier,
      discountData: rowData?.discountData,
    };

    axios
      .post(awsUrl2 + "/api/updateSolveUserStatusTable", requestBody)
      .then((res) => {})
      .catch((err) => console.log("error: ", err));
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
    return "";
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    let len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };
  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        console.log(
          "firstcharater:",
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };
  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];
    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YYYY - hh:mm A");
    let momentB = moment(b[field], "M/D/YYYY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };
  updateDomainMessageidFiles = (domain, key, val, agpsArrayvalues) => {
    // Make copies of the state objects
    let { domain_messageid_files, rowDataList } = JSON.parse(
      JSON.stringify(this.state)
    );

    // Update domain_messageid_files
    if (agpsArrayvalues) {
      domain_messageid_files[domain][key] = agpsArrayvalues;
    } else {
      domain_messageid_files[domain][key] = val;
    }

    // Update rowDataList for the specific domain
    let updatedRowDataList = [...rowDataList];
    updatedRowDataList = updatedRowDataList.map((item) => {
      if (item.domain === domain) {
        return { ...item, [key]: val };
      }
      return item;
    });
    // Update the component state with the new values
    this.setState({ domain_messageid_files, rowDataList: updatedRowDataList });
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "1rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.9rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.75rem";
    }
  };
  source = (Source) => {
    if (Source == "hotschedules") {
      return "HotSchedules";
    } else if (Source == "affinity") {
      return "Affinity";
    } else {
      return Source;
    }
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    let { userName } = this.state;

    sessionStorage.setItem("domain", JSON.stringify(rowData.domain));
    // checking is it engaged tab data or campaigned tab data.
    if (rowData.isEngaged) {
      sessionStorage.setItem("preUwEngagedFlag", "true");
    }
    //  agpsfiles uploaded key and file type to show in ui.

    if (rowData.agpsFiles) {
      sessionStorage.setItem("agpsFiles", JSON.stringify(rowData.agpsFiles));
    }
    // setting the company profile details

    if (!rowData.companyProfile.descriptionOfOperations) {
      if (rowData.companyProfile.description_of_operations) {
        rowData.companyProfile.descriptionOfOperations = {};
        rowData.companyProfile.descriptionOfOperations["value"] =
          rowData.companyProfile.description_of_operations.value;
      }
    }
    if (!rowData.companyProfile.source) {
      rowData.companyProfile.source = {};
      rowData.companyProfile.source["value"] = rowData.source;
    }

    if (!rowData.companyProfile.fein) {
      if (rowData.fein) {
        rowData.companyProfile.fein = {};
        rowData.companyProfile.fein["value"] = rowData.fein.value;
      }
    }

    if (
      rowData.companyProfile &&
      rowData.companyProfile.fein &&
      rowData.companyProfile.fein.value
    ) {
      let value = rowData.companyProfile.fein.value + "";
      value = value.split("-").join("");
      let len = value.length,
        dummystr = "000000000";

      if (len > 0 && len < 9) {
        value = dummystr.slice(0, 9 - len) + "" + value;
        rowData.companyProfile.fein.value =
          value[0] + value[1] + "-" + value.slice(2);
        rowData.companyProfile.fein.errMsg = "";
      } else if (len > 9) {
        rowData.companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
        rowData.companyProfile.fein.error = true;
      } else if (len === 0) {
        rowData.companyProfile.fein.value = undefined;
        rowData.companyProfile.fein.errMsg = "";
      } else {
        rowData.companyProfile.fein.value =
          value[0] + value[1] + "-" + value.slice(2);
        rowData.companyProfile.fein.errMsg = "";
      }
    }

    if (!rowData.companyProfile.expectedStartDate)
      rowData.companyProfile.expectedStartDate = {};

    rowData.companyProfile.expectedStartDate.value =
      rowData.companyProfile.expectedStartDate &&
      rowData.companyProfile.expectedStartDate.value
        ? moment(rowData.companyProfile.expectedStartDate.value)
        : moment().date() === 1
        ? moment()
        : moment(moment().subtract(moment().date() - 1, "days")).add(
            1,
            "month"
          );

    if (!rowData.companyProfile.effectiveDate)
      rowData.companyProfile.effectiveDate = {};

    rowData.companyProfile.effectiveDate.value =
      rowData.companyProfile.effectiveDate &&
      rowData.companyProfile.effectiveDate.value
        ? moment(rowData.companyProfile.effectiveDate.value)
        : moment().date() === 1
        ? moment()
        : moment(moment().subtract(moment().date() - 1, "days")).add(
            1,
            "month"
          );

    if (!rowData.companyProfile.state1) {
      if (rowData.companyProfile.state) {
        rowData.companyProfile.state1 = {};
        rowData.companyProfile.state1["value"] =
          rowData.companyProfile.state.value;
      }
    }
    if (!rowData.companyProfile.state2) {
      if (rowData.companyProfile.state) {
        rowData.companyProfile.state2 = {};
        rowData.companyProfile.state2["value"] =
          rowData.companyProfile.state.value;
      }
    }
    if (!rowData.companyProfile.zipCode1) {
      if (rowData.companyProfile.zip) {
        rowData.companyProfile.zipCode1 = {};
        rowData.companyProfile.zipCode1["value"] =
          rowData.companyProfile.zip.value;
      }
    }
    if (!rowData.companyProfile.zipCode2) {
      if (rowData.companyProfile.zip) {
        rowData.companyProfile.zipCode2 = {};
        rowData.companyProfile.zipCode2["value"] =
          rowData.companyProfile.zip.value;
      }
    }

    if (!rowData.companyProfile.cityName1) {
      if (rowData.companyProfile.city) {
        rowData.companyProfile.cityName1 = {};
        rowData.companyProfile.cityName1["value"] =
          rowData.companyProfile.city.value;
      }
    }

    if (!rowData.companyProfile.cityName2) {
      if (rowData.companyProfile.city) {
        rowData.companyProfile.cityName2 = {};
        rowData.companyProfile.cityName2["value"] =
          rowData.companyProfile.city.value;
      }
    }
    if (!rowData.companyProfile.street1) {
      if (rowData.companyProfile.address) {
        rowData.companyProfile.street1 = {};
        rowData.companyProfile.street1["value"] =
          rowData.companyProfile.address.value;
      }
    }
    if (!rowData.companyProfile.street2) {
      if (rowData.companyProfile.address) {
        rowData.companyProfile.street2 = {};
        rowData.companyProfile.street2["value"] =
          rowData.companyProfile.address.value;
      }
    }
    if (rowData.domain) {
      rowData.companyProfile.domain = {};
      rowData.companyProfile.domain["value"] = rowData.domain;
    }

    // setting the company profile details.
    sessionStorage.setItem(
      "companyProfile",
      JSON.stringify(rowData.companyProfile)
    );

    // setting the contact detials
    sessionStorage.setItem(
      "contactDetails",
      JSON.stringify(rowData.email_specific)
    );

    // setting the payroll  in location and payroll

    sessionStorage.setItem("childrenLoc", JSON.stringify(rowData.childrenLoc));
    sessionStorage.setItem("UserEmail", JSON.stringify(userName));
    window.location.href = "/StageOne";
  };

  setTableData = () => {
    let {
      rowDataList,
      isLoading,
      discountDataObject,
      scrollLeft,
      scrollRight,
    } = JSON.parse(JSON.stringify(this.state));

    let { domain_messageid_files } = this.state;

    let { tabName } = this.props;

    let columns = {
      // tab1: [
      //   {
      //     title: "Company Name",
      //     field: "companyName",
      //     cellStyle: {
      //       padding: "6px 5px 6px 10px",
      //       minWidth: "270px",
      //       width: "110px",
      //       maxWidth: "110px",
      //       wordBreak: "break-word",
      //     },
      //     render: (rowData) => (
      //       <Clamp lines={2}>
      //         <span onClick={() => this.onClickCompanyName(rowData)}>
      //           <Link to={"/StageOne"}>
      //             {this.camelCase(rowData?.companyName)}
      //           </Link>
      //         </span>
      //       </Clamp>
      //     ),
      //   },
      //   {
      //     title: "Work Status",
      //     field: "agpsWorkStatus",
      //     filterPlaceholder: "Filter",
      //     render: (rowData) => (
      //       <Clamp lines={2}>{rowData?.agpsWorkStatus}</Clamp>
      //     ),
      //     cellStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "90px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "90px",
      //     },
      //   },
      //   {
      //     title: "State",
      //     field: "state",
      //     filterPlaceholder: "Filter",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterAll(term, rowData, field),
      //     cellStyle: {
      //       textTransform: "uppercase",
      //       padding: "6px 0 6px 10px",
      //       minWidth: "50px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "50px",
      //     },
      //   },
      //   {
      //     title: "# Emp",
      //     field: "total_emp",
      //     filterPlaceholder: "Filter",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterfunction(term, rowData, field),
      //     cellStyle: {
      //       padding: "6px 0",
      //       minWidth: "65px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0",
      //       whiteSpace: "nowrap",
      //       minWidth: "65px",
      //     },
      //   },

      //   {
      //     title: "Stage1 Comment",
      //     field: "agpsComment",
      //     render: (rowData) => (
      //       <textarea
      //         defaultValue={rowData?.agpsComment || ""}
      //         // onBlur={(e) => this.updateComment(e, rowData)}
      //         rows="2"
      //         style={{ width: "100%" }}
      //       ></textarea>
      //     ),

      //     cellStyle: {
      //       padding: "6px 15px 6px 10px",
      //       minWidth: "150px",
      //       width: "180px",
      //       maxWidth: "180px",
      //     },
      //   },
      //   {
      //     title: "Stage2 Comment",
      //     field: "comment",
      //     render: (rowData) => (
      //       <textarea
      //         defaultValue={rowData?.comment || ""}
      //         // onBlur={(e) => this.updateComment(e, rowData)}
      //         rows="2"
      //         style={{ width: "100%" }}
      //       ></textarea>
      //     ),

      //     cellStyle: {
      //       padding: "6px 15px 6px 10px",
      //       minWidth: "150px",
      //       width: "180px",
      //       maxWidth: "180px",
      //     },
      //   },

      //   {
      //     title: "Payroll",
      //     field: "payroll",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterfunction(term, rowData, field),
      //     render: (rowData) => (
      //       <Clamp lines={2}>{numeral(rowData?.payroll).format("$0,0")}</Clamp>
      //     ),
      //     cellStyle: {
      //       padding: "6px 0",
      //       minWidth: "120px",
      //       width: "120px",
      //       maxWidth: "80px",
      //       wordBreak: "break-all",
      //     },
      //     headerStyle: {
      //       padding: "6px 0",
      //       minWidth: "120px",
      //     },
      //   },

      //   {
      //     title: "Visit Count",
      //     field: "visitCount",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterfunction(term, rowData, field),

      //     cellStyle: {
      //       padding: "6px 15px 6px 10px",
      //       minWidth: "30px",
      //       width: "20px",
      //       maxWidth: "20px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0",
      //     },
      //   },
      //   {
      //     title: "Upload / Download",
      //     disableSortBy: true,
      //     cellStyle: {
      //       minWidth: "30px",
      //       width: "20px",
      //       maxWidth: "20px",
      //       padding: "6px 0",
      //     },
      //     render: (rowData) => (
      //       <button
      //         className="btn pt-0 "
      //         type="button"
      //         onClick={() => {
      //           let totalFiles =
      //             (domain_messageid_files[rowData.domain]?.dashboardFiles
      //               ?.length || 0) +
      //             (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
      //               0) +
      //             (domain_messageid_files[rowData.domain]?.agpsFiles?.length ||
      //               0) +
      //             (domain_messageid_files[rowData.domain]?.fileListToStore
      //               ?.length || 0);

      //           this.setState({
      //             showModel: true,
      //             modalTitle: <h1>{"Upload/Download"}</h1>,
      //             modalContent: (
      //               <>
      //                 <UploadDashboardFile
      //                   tabName={rowData.tabName}
      //                   domainName={rowData.domain}
      //                   dashboardFiles={
      //                     domain_messageid_files[rowData.domain]
      //                       .dashboardFiles || []
      //                   }
      //                   companyName={rowData.companyName}
      //                   messageID={rowData.messageID}
      //                   updateDomainMessageidFiles={
      //                     this.updateDomainMessageidFiles
      //                   }
      //                   instance={rowData.instance}
      //                 />
      //                 <hr></hr>
      //                 <DownloadDashboardFiles
      //                   tabName={rowData.tabName}
      //                   dashboardFiles={
      //                     domain_messageid_files[rowData.domain]
      //                       .dashboardFiles || []
      //                   }
      //                   preUwFiles={
      //                     domain_messageid_files[rowData.domain].preUwFiles ||
      //                     []
      //                   }
      //                   agpsFiles={
      //                     domain_messageid_files[rowData.domain].agpsFiles || []
      //                   }
      //                   fileListToStore={
      //                     domain_messageid_files[rowData.domain]
      //                       .fileListToStore || []
      //                   }
      //                   domainName={rowData.domain}
      //                   updateDomainMessageidFiles={
      //                     this.updateDomainMessageidFiles
      //                   }
      //                 />
      //               </>
      //             ),
      //           });
      //         }}
      //       >
      //         <img
      //           src={require("../../images/upload.png")}
      //           alt=""
      //           style={{ width: "50px" }}
      //         />
      //       </button>
      //     ),
      //   },
      //   {
      //     title: "Visit Date (ET)",
      //     field: "visitDate",
      //     filterPlaceholder: "Filter",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterAll(term, rowData, field),
      //     defaultSort: "desc",
      //     customSort: (a, b) => this.dateSort(a, b, "visitDate"),
      //     render: (rowData) => {
      //       if (rowData.visitDate) {
      //         let date = rowData?.visitDate.split("-")[0];
      //         let time = rowData?.visitDate.split("-")[1];
      //         return (
      //           <span>
      //             {date}
      //             <br></br>
      //             {time}
      //           </span>
      //         );
      //       } else {
      //         return "-";
      //       }
      //     },
      //     cellStyle: {
      //       wordBreak: "break-all",
      //       padding: "6px 0",
      //       minWidth: "100px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0",
      //       minWidth: "100px",
      //     },
      //   },

      //   {
      //     title: "Campaign Name",
      //     field: "latestCampaign",
      //     cellStyle: {
      //       padding: "6px 15px 6px 10px",
      //       minWidth: "140px",
      //       width: "140px",
      //       maxidth: "140px",
      //       wordBreak: "break-word",
      //     },
      //     render: (rowData) => (
      //       <Clamp lines={2}>{this.camelCase(rowData?.latestCampaign)}</Clamp>
      //     ),
      //   },
      //   {
      //     title: "Stage2 UW",
      //     field: "preUwName",
      //     filterPlaceholder: "Filter",
      //     cellStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "120px",
      //       wordBreak: "break-all",
      //     },
      //     render: (rowData) => (
      //       <Clamp lines={2}>{rowData?.preUwName || ""}</Clamp>
      //     ),
      //   },
      //   {
      //     title: " Stage2 Submit Date",
      //     field: "preUwDate",
      //     filterPlaceholder: "Filter",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterAll(term, rowData, field),

      //     customSort: (a, b) => this.dateSort(a, b, "preUwDate"),
      //     render: (rowData) => {
      //       if (rowData.preUwDate) {
      //         let date = rowData?.preUwDate.split("-")[0];
      //         let time = rowData?.preUwDate.split("-")[1];
      //         return (
      //           <span>
      //             {date}
      //             <br></br>
      //             {time}
      //           </span>
      //         );
      //       } else {
      //         return "-";
      //       }
      //     },
      //     cellStyle: {
      //       wordBreak: "break-all",
      //       padding: "6px 0",
      //       minWidth: "100px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0",
      //       minWidth: "100px",
      //     },
      //   },
      //   {
      //     title: "Stage1 UW",
      //     field: "agpsuserName",
      //     filterPlaceholder: "Filter",
      //     render: (rowData) => <Clamp lines={2}>{rowData?.agpsuserName}</Clamp>,
      //     cellStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "100px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "100px",
      //     },
      //   },

      //   {
      //     title: "Stage1 Submit Date",
      //     field: "agpsSubmittedDate",
      //     filterPlaceholder: "Filter",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterAll(term, rowData, field),
      //     cellStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "100px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "100px",
      //     },
      //   },
      //   {
      //     title: "uploaded date",
      //     field: "uploadedDate",
      //     filterPlaceholder: "Filter",
      //     customFilterAndSearch: (term, rowData, field) =>
      //       this.customFilterAll(term, rowData, field),
      //     cellStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "100px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "100px",
      //     },
      //   },
      //   {
      //     title: "Steps",
      //     field: "step",
      //     cellStyle: (e, rowData) => {
      //       let style = { padding: "6px 15px 6px 10px" };
      //       if (rowData.step === "Proposal Generated") {
      //         style.backgroundColor = "#5ADA38";
      //       } else if (rowData.step === "UW Portal") {
      //         style.backgroundColor = "#ffd737ac";
      //       } else if (rowData.step === "Approved") {
      //         style.backgroundColor = "#87FA4D";
      //       } else if (rowData.step === "Rejected") {
      //         style.backgroundColor = "#fa6d3e";
      //       } else if (rowData.step === "Quote Generated") {
      //         style.backgroundColor = "#87FA20";
      //       } else if (Number(rowData.visitCount) > 0) {
      //         style.backgroundColor = "#FFD737";
      //       } else if (rowData.step === "" || rowData.step === "-") {
      //         style.backgroundColor = "#FFF";
      //       } else {
      //         style.backgroundColor = "#34797D";
      //       }
      //       return style;
      //     },
      //   },
      //   {
      //     title: "Domain",
      //     field: "domain",
      //     filterPlaceholder: "Filter",
      //     render: (rowData) => <Clamp lines={2}>{rowData?.domain}</Clamp>,

      //     cellStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "170px",
      //     },
      //     headerStyle: {
      //       padding: "6px 0 6px 10px",
      //       minWidth: "170px",
      //     },
      //   },
      //   {
      //     title: "Source",
      //     field: "source",
      //     filterPlaceholder: "Filter",
      //     cellStyle: {
      //       padding: "6px 15px 6px 10px",
      //       minWidth: "170px",
      //     },
      //     render: (rowData) => (
      //       <Clamp lines={1}>{this.source(rowData?.source) || ""}</Clamp>
      //     ),
      //   },
      // ],
      tab2: [
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "270px",
            width: "110px",
            maxWidth: "110px",
            wordBreak: "break-word",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span onClick={() => this.onClickCompanyName(rowData)}>
                <Link to={"/StageOne"}>
                  {this.camelCase(rowData?.companyName)}
                </Link>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Work Status",
          field: "agpsWorkStatus",
          filterPlaceholder: "Filter",
          render: (rowData) => (
            <Clamp lines={2}>{rowData?.agpsWorkStatus}</Clamp>
          ),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "90px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "90px",
          },
        },
        {
          title: "State",
          field: "state",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "65px",
          },
          headerStyle: {
            padding: "6px 0",
            whiteSpace: "nowrap",
            minWidth: "65px",
          },
        },

        {
          title: "Comments",
          field: "agpsComment",
          render: (rowData) => (
            <textarea
              defaultValue={rowData?.agpsComment || ""}
              // onBlur={(e) => this.updateComment(e, rowData)}
              rows="2"
              style={{ width: "100%" }}
            ></textarea>
          ),

          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
            width: "180px",
            maxWidth: "180px",
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) => (
            <Clamp lines={2}>{numeral(rowData?.payroll).format("$0,0")}</Clamp>
          ),
          cellStyle: {
            padding: "6px 0",
            minWidth: "120px",
            width: "120px",
            maxWidth: "80px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "120px",
          },
        },

        // {
        //   title: "Visit Count",
        //   field: "visitCount",
        //   customFilterAndSearch: (term, rowData, field) =>
        //     this.customFilterfunction(term, rowData, field),
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "30px",
        //     width: "20px",
        //     maxWidth: "20px",
        //   },
        //   headerStyle: {
        //     padding: "6px 0",
        //   },
        // },
        {
          title: "Upload / Download",
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "20px",
            maxWidth: "20px",
            padding: "6px 0",
          },
          render: (rowData) => (
            <button
              className="btn pt-0 "
              type="button"
              onClick={() => {
                let totalFiles =
                  (domain_messageid_files[rowData.domain]?.dashboardFiles
                    ?.length || 0) +
                  (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                    0) +
                  (domain_messageid_files[rowData.domain]?.agpsFiles?.length ||
                    0) +
                  (domain_messageid_files[rowData.domain]?.fileListToStore
                    ?.length || 0);

                this.setState({
                  showModel: true,
                  modalTitle: <h1>{"Upload/Download"}</h1>,
                  modalContent: (
                    <>
                      <UploadDashboardFile
                        tabName={rowData?.tabName}
                        domainName={rowData?.domain}
                        dashboardFiles={
                          domain_messageid_files?.[rowData.domain]
                            ?.dashboardFiles || []
                        }
                        companyName={rowData.companyName}
                        messageID={rowData.messageID}
                        updateDomainMessageidFiles={
                          this.updateDomainMessageidFiles
                        }
                        instance={rowData?.instance}
                      />
                      <hr></hr>
                      <DownloadDashboardFiles
                        tabName={rowData.tabName}
                        dashboardFiles={
                          domain_messageid_files?.[rowData.domain]
                            ?.dashboardFiles || []
                        }
                        preUwFiles={
                          domain_messageid_files?.[rowData.domain]
                            ?.preUwFiles || []
                        }
                        agpsFiles={
                          domain_messageid_files?.[rowData.domain]?.agpsFiles ||
                          []
                        }
                        fileListToStore={
                          domain_messageid_files?.[rowData.domain]
                            ?.fileListToStore || []
                        }
                        domainName={rowData?.domain}
                        updateDomainMessageidFiles={
                          this.updateDomainMessageidFiles
                        }
                      />
                    </>
                  ),
                });
              }}
            >
              <img
                src={require("../../images/upload.png")}
                alt=""
                style={{ width: "50px" }}
              />
            </button>
          ),
        },
        // {
        //   title: "Visit Date (ET)",
        //   field: "visitDate",
        //   filterPlaceholder: "Filter",
        //   customFilterAndSearch: (term, rowData, field) =>
        //     this.customFilterAll(term, rowData, field),
        //   defaultSort: "desc",
        //   customSort: (a, b) => this.dateSort(a, b, "visitDate"),
        //   render: (rowData) => {
        //     if (rowData.visitDate) {
        //       let date = rowData?.visitDate.split("-")[0];
        //       let time = rowData?.visitDate.split("-")[1];
        //       return (
        //         <span>
        //           {date}
        //           <br></br>
        //           {time}
        //         </span>
        //       );
        //     } else {
        //       return "-";
        //     }
        //   },
        //   cellStyle: {
        //     wordBreak: "break-all",
        //     padding: "6px 0",
        //     minWidth: "100px",
        //   },
        //   headerStyle: {
        //     padding: "6px 0",
        //     minWidth: "100px",
        //   },
        // },

        // {
        //   title: "Campaign Name",
        //   field: "latestCampaign",
        //   cellStyle: {
        //     padding: "6px 15px 6px 10px",
        //     minWidth: "140px",
        //     width: "140px",
        //     maxidth: "140px",
        //     wordBreak: "break-word",
        //   },
        //   render: (rowData) => (
        //     <Clamp lines={2}>{this.camelCase(rowData?.latestCampaign)}</Clamp>
        //   ),
        // },
        {
          title: "Stage2 UW",
          field: "preUwName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "90px",
            wordBreak: "break-all",
          },
          render: (rowData) => (
            <Clamp lines={2}>{rowData?.preUwName || ""}</Clamp>
          ),
        },
        {
          title: " Stage2 Submit Date",
          field: "preUwDate",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "preUwDate"),
          render: (rowData) => {
            if (rowData.preUwDate) {
              let date = rowData?.preUwDate.split("-")[0];
              let time = rowData?.preUwDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "100px",
          },
        },
        {
          title: "Stage1 UW",
          field: "agpsuserName",
          filterPlaceholder: "Filter",
          render: (rowData) => <Clamp lines={2}>{rowData?.agpsuserName}</Clamp>,
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "100px",
          },
        },

        {
          title: "Stage1 Submit Date",
          field: "agpsSubmittedDate",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "100px",
          },
        },
        {
          title: "uploaded date",
          field: "uploadedDate",
          defaultSort: "desc",
          customSort: (a, b) => this.dateSort(a, b, "uploadedDate"),
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "100px",
          },
        },
        {
          title: "Steps",
          field: "step",
          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else if (rowData.step === "" || rowData.step === "-") {
              style.backgroundColor = "#FFF";
            } else {
              style.backgroundColor = "#34797D";
            }
            return style;
          },
        },
        {
          title: "Domain",
          field: "domain",
          filterPlaceholder: "Filter",
          render: (rowData) => <Clamp lines={2}>{rowData?.domain}</Clamp>,

          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "170px",
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{this.source(rowData?.source) || ""}</Clamp>
          ),
        },
      ],
    };

    return (
      <>
        <div style={{ position: "relative" }}>
          {scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}

          <MaterialTable
            isLoading={isLoading}
            icons={tableIcons}
            data={rowDataList}
            columns={columns[tabName]}
            options={{
              exportButton: true,
              stickyHeader: true,
              doubleHorizontalScroll: true,
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, rowDataList.length],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            detailPanel={
              tabName === "tab2"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <div style={{ padding: "25px" }}>
                            <TableContainer component={Paper}>
                              <Table size="small" className="sub_tab2">
                                <TableHead
                                  style={{
                                    background: "#00000066",
                                    color: "whitesmoke",
                                  }}
                                >
                                  <TableRow>
                                    <TableCell>Contact Name</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Email</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rowData.email_specific ? (
                                    Object.values(rowData.email_specific).map(
                                      (element, index) => {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell scope="row">
                                              {element.companyProfile &&
                                              element.companyProfile
                                                .contact_name
                                                ? element.companyProfile
                                                    .contact_name.value
                                                : ""}
                                            </TableCell>
                                            <TableCell scope="row">
                                              {element.companyProfile &&
                                              element.companyProfile
                                                .contact_number
                                                ? element.companyProfile.contact_number.value.replace(
                                                    /(\d{3})(\d{3})(\d{4})/,
                                                    "($1) $2-$3"
                                                  )
                                                : ""}
                                            </TableCell>
                                            <TableCell scope="row">
                                              {
                                                element.companyProfile.emailId
                                                  .value
                                              }
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )
                                  ) : (
                                    <TableRow key={0}>
                                      <TableCell scope="row">
                                        {rowData.contactName}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.phoneNumber
                                          ? rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "($1) $2-$3"
                                            )
                                          : ""}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.userEmail}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        );
                      },
                    },
                  ]
                : tabName === "tab1"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <div style={{ padding: "25px" }}>
                            <TableContainer component={Paper}>
                              <Table size="small" className="sub_tab1">
                                <TableHead
                                  style={{
                                    background: "#00000066",
                                    color: "whitesmoke",
                                  }}
                                >
                                  <TableRow>
                                    <TableCell>Contact Name</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Visit Count</TableCell>
                                    {/* <TableCell>Campaign Name</TableCell> */}
                                    <TableCell>Steps</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rowData.email_specific ? (
                                    Object.values(rowData.email_specific).map(
                                      (element, index) => {
                                        if (element.companyProfile) {
                                          let step;
                                          if (
                                            Number(
                                              element.proposalGeneratedCount
                                            ) > 0
                                          ) {
                                            step = "Proposal Generated";
                                          } else if (element.quote_status) {
                                            if (
                                              element.quote_status ===
                                              "uwportal"
                                            ) {
                                              step = "UW Portal";
                                            } else if (
                                              element.quote_status ===
                                              "proceed_uw"
                                            ) {
                                              step = "Approved";
                                            } else if (
                                              element.quote_status === "reject"
                                            ) {
                                              step = "Rejected";
                                            }
                                          } else if (
                                            Number(
                                              element.quoteGeneratedCount
                                            ) > 0
                                          ) {
                                            step = "Quote Generated";
                                          } else if (
                                            Number(element.visitCount) > 0
                                          ) {
                                            if (element.formStage) {
                                              if (element.formStage === "two") {
                                                if (
                                                  element.nonEligibilityList
                                                ) {
                                                  step = "Not Eligible";
                                                } else {
                                                  step = "UW Questions";
                                                }
                                              } else if (
                                                element.formStage === "one"
                                              ) {
                                                step = "Company Profile";
                                              }
                                            } else {
                                              step = "Landing Page";
                                            }
                                          } else if (
                                            element.companyProfile.status &&
                                            element.companyProfile.status.value
                                          ) {
                                            step =
                                              element.companyProfile.status
                                                .value;
                                          } else {
                                            step = "-";
                                          }
                                          return (
                                            <TableRow key={index}>
                                              <TableCell scope="row">
                                                {element.companyProfile &&
                                                element.companyProfile
                                                  .contact_name
                                                  ? element.companyProfile
                                                      .contact_name.value
                                                  : ""}
                                              </TableCell>
                                              <TableCell scope="row">
                                                {element.companyProfile &&
                                                element.companyProfile
                                                  .contact_number
                                                  ? element.companyProfile.contact_number.value.replace(
                                                      /(\d{3})(\d{3})(\d{4})/,
                                                      "($1) $2-$3"
                                                    )
                                                  : ""}
                                              </TableCell>
                                              <TableCell scope="row">
                                                {
                                                  element.companyProfile.emailId
                                                    .value
                                                }
                                              </TableCell>
                                              <TableCell scope="row">
                                                {element.visitCount || 0}
                                              </TableCell>
                                              <TableCell scope="row">
                                                {step}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <TableRow key={0}>
                                      <TableCell scope="row">
                                        {rowData.contactName}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.phoneNumber
                                          ? rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "($1) $2-$3"
                                            )
                                          : ""}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.userEmail}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.visitCount}
                                      </TableCell>
                                      {/* <TableCell scope="row">
                                    {rowData.latestCampaign || ""}
                                  </TableCell> */}
                                      <TableCell scope="row">
                                        {rowData.step || ""}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        );
                      },
                    },
                  ]
                : false
            }
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      </>
    );
  };

  updateAgentDisplay = async () => {
    axios
      .get(awsUrl2 + "/api/getAgentDashboardDataAdmin")
      .then((res) => {
        let userDataList = [];
        let rowData;
        let rowDataList = [];
        let step;

        userDataList = res.data;

        for (let userData of userDataList) {
          let indicationCost = 0;

          if (userData.quote_status === "reject") {
            step = "Rejected";
          } else if (userData.quote_status === "proceed_uw") {
            step = "Approved";
          } else if (
            userData.doc_status !== "not_uploaded" ||
            userData.nolossGenerated
          ) {
            step = "Submitted";
          } else if (
            userData.doc_status === "not_uploaded" ||
            !userData.doc_status
          ) {
            step = "Docs Pending";
          } else {
            step = "-";
          }

          for (let stateIndex in userData.prospect_addresses) {
            for (let classCodeData of userData.prospect_addresses[stateIndex]
              .classCodes) {
              indicationCost = numeral(indicationCost)
                .add(classCodeData.payroll)
                .value();
            }
          }

          rowData = {
            visitDate: userData.date,
            userEmail: userData.user_email_id,
            uuid: userData.uuid,
            phoneNumber: userData.phoneNumber || userData.phone_number,
            indicationCost,
            effectiveDate: moment(userData.effective_date).format("x"),
            contactName: userData.contact_person,
            companyName: userData.company_name || userData.companyName,
            domain: userData.domain,
            quote_status: userData.quote_status,
            doc_status: userData.doc_status,
            agent_email: userData.agent_email,
            step,
            uuid_carrier: userData.uuid_carrier,
          };
          rowDataList.push(rowData);
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getNonEligibleData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplay = async () => {
    let { tabName } = this.props;
    let requestBody = {
      tab: tabName,
      first_time_loading: "true",
    };
    let last_key;
    let rowDataList = [];
    let domain_messageid_files = JSON.parse(
      JSON.stringify(this.state.domain_messageid_files)
    );

    await new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
        .then((res) => {
          let rowData;
          let isEngaged;
          let userDataList = res.data.data;
          last_key = res.data.lastEvaluatedKey;
          if (requestBody["tab"] == "tab1") {
            isEngaged = true;
          } else if (requestBody["tab"] == "tab2") {
            isEngaged = false;
          }
          for (let userData of userDataList) {
            let quote_status;
            let excluded_status, email_specific_status;
            try {
              let emodStatesData = userData.emodStatesData;
              let payrollData = userData.payrollData;
              let companyProfile = userData.companyProfile;

              let payroll = 0,
                emod = 1;

              for (let email in userData.email_specific) {
                let db_quote_status =
                  userData.email_specific[email].quote_status;
                let db_excluded =
                  userData.email_specific[email].nonEligibilityList;
                if (db_excluded && db_excluded.length > 0) {
                  excluded_status = true;
                }

                if (
                  userData.email_specific[email].companyProfile &&
                  userData.email_specific[email].companyProfile.status &&
                  userData.email_specific[email].companyProfile.status.value &&
                  !email_specific_status
                ) {
                  email_specific_status =
                    userData.email_specific[email].companyProfile.status.value;
                }

                if (
                  (!quote_status && db_quote_status) ||
                  (quote_status === "reject" && db_quote_status) ||
                  db_quote_status === "proceed_uw"
                ) {
                  quote_status = db_quote_status;
                }
              }

              if (
                emodStatesData &&
                emodStatesData[Object.keys(emodStatesData)[0]].rate1
              ) {
                emod =
                  emodStatesData[Object.keys(emodStatesData)[0]].rate1.value;
              }

              if (payrollData) {
                for (let state in payrollData) {
                  for (let pay in payrollData[state].classCodesInfo) {
                    let pyrll = numeral(
                      payrollData[state].classCodesInfo[pay].payroll.value
                    ).value();

                    payroll += parseInt(pyrll);
                  }
                }
              }

              let step;
              if (Number(userData.proposalGeneratedCount) > 0) {
                step = "Proposal Generated";
              } else if (userData.quote_status) {
                if (userData.quote_status === "uwportal") {
                  step = "UW Portal";
                } else if (userData.quote_status === "proceed_uw") {
                  step = "Approved";
                } else if (userData.quote_status === "reject") {
                  step = "Rejected";
                }
              } else if (Number(userData.quoteGeneratedCount) > 0) {
                step = "Quote Generated";
              } else if (Number(userData.visitCount) > 0) {
                if (userData.formStage) {
                  if (userData.formStage === "two") {
                    if (userData.nonEligibilityList) {
                      step = "Not Eligible";
                    } else {
                      step = "UW Questions";
                    }
                  } else if (userData.formStage === "one") {
                    step = "Company Profile";
                  }
                } else {
                  step = "Landing Page";
                }
              } else if (email_specific_status) {
                step = email_specific_status;
              } else {
                step = "-";
              }
              let instance;
              if (userData.isFourth) {
                instance = "fourth";
              } else if (userData.isSolve) {
                instance = "solve";
              } else {
                instance = "affinity";
              }

              let total_emp = 0;
              if (userData.payrollData) {
                let childrenLoc = userData.payrollData;
                for (let locIndex in childrenLoc) {
                  let loc = childrenLoc[locIndex];
                  for (let ccIndex in loc.classCodesInfo) {
                    let cc = loc.classCodesInfo[ccIndex];
                    total_emp =
                      total_emp +
                      Number(cc?.ft?.value || 0) +
                      Number(cc?.pt?.value || 0);
                  }
                }
              }

              rowData = {
                messageID: userData.messageID,
                dashboardFiles: userData.dashboardFiles || [],
                visitDate:
                  userData.timestamp &&
                  moment(Number(userData.timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YYYY - hh:mm A"),
                userEmail: companyProfile.emailId
                  ? companyProfile.emailId.value
                  : "",
                signedUp: userData.signedUp === "true" ? "Yes" : "No",
                quoteGenerated: userData.quoteGenerated || "",
                proposalGenerated: userData.proposalGenerated || "",
                phoneNumber: companyProfile.phoneNumber
                  ? companyProfile.phoneNumber.value
                  : "",
                effectiveDate:
                  companyProfile &&
                  companyProfile &&
                  companyProfile.effectiveDate
                    ? moment(companyProfile.effectiveDate.value).format("x")
                    : "",
                payroll: payroll,
                indicationCost: userData.indicationCost,
                emod: emod && Number(emod) ? parseFloat(emod).toFixed(2) : "-",
                step,
                contactName:
                  (companyProfile.primaryContact
                    ? companyProfile.primaryContact.value
                    : "") ||
                  userData.contactName ||
                  "",
                companyName:
                  (companyProfile.company_name
                    ? companyProfile.company_name.value
                    : "") ||
                  (companyProfile.companyName
                    ? companyProfile.companyName.value
                    : "") ||
                  userData.companyName ||
                  "",
                comment: userData?.comment || "",
                agpsComment: userData?.companyProfile?.agpsComment?.value || "",
                domain: userData.domain,
                visitCount: userData.visitCount || "0",
                email_specific: userData.email_specific,
                latestCampaign: userData.latestCampaign || "",
                tabName,
                instance,
                zohoDate: userData.zywave_uploaded_date || "1991-1-1",

                companyProfile: userData.companyProfile || "",
                fein: userData.fein,
                childrenLoc: userData.payrollData,
                isEngaged: isEngaged,
                agpsFiles: userData?.agpsFiles || [],
                source: userData?.source || "",
                preUwName: userData?.preUwData?.userId?.split("@")[0] || "",
                preUwDate:
                  userData.preUwDate &&
                  moment(Number(userData.preUwDate))
                    .utcOffset("-0500")
                    .format("M/D/YYYY - hh:mm A"),
                total_emp,
                uploadedDate: userData?.UploadedDate
                  ? moment(userData?.UploadedDate, "x").format("M/D/YYYY")
                  : "-",

                state:
                  userData?.companyProfile?.state1?.value || userData?.state,
                agpsWorkStatus: userData.agpsWorkStatus || "",
                agpsSubmittedDate: userData?.agpsSubmittedDate
                  ? moment(userData?.agpsSubmittedDate, "x").format("M/D/YYYY")
                  : "-",
                agpsuserName: userData?.agpsuserName || "",
              };

              if (rowData.email_specific) {
                Object.values(rowData.email_specific).map((email) => {
                  if (
                    email.companyProfile &&
                    email.companyProfile.contact_number &&
                    email.companyProfile.contact_number.value
                  ) {
                    email.companyProfile.contact_number.value = this.appendRemaining(
                      email.companyProfile.contact_number.value.toString()
                    );
                  }
                });
              } else {
                if (rowData.phoneNumber)
                  rowData.phoneNumber = this.appendRemaining(
                    rowData.phoneNumber.toString()
                  );
              }

              domain_messageid_files[rowData.domain] = {};
              if (rowData.messageID)
                domain_messageid_files[rowData.domain]["messageID"] =
                  rowData.messageID;
              if (rowData.dashboardFiles)
                domain_messageid_files[rowData.domain]["dashboardFiles"] =
                  rowData.dashboardFiles;

              if (userData.preUwFiles) {
                let _preUwFiles = [];
                userData.preUwFiles.forEach((ele) => {
                  _preUwFiles.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "preUwFiles"
                ] = _preUwFiles;
              }

              // agps files to show in dashboard
              if (userData.agpsFiles) {
                let _agpsFiles = [];
                userData.agpsFiles.forEach((ele) => {
                  _agpsFiles.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "agpsFiles"
                ] = _agpsFiles;
              }

              if (userData.fileListToStore) {
                let _fileListToStore = [];
                userData.fileListToStore.forEach((ele) => {
                  _fileListToStore.push(ele.key);
                });
                domain_messageid_files[userData.domain][
                  "fileListToStore"
                ] = _fileListToStore;
              }

              rowDataList.push(rowData);
            } catch (error) {
              console.log("error: ", error);
            }
          }
          // console.log("rowDataList: ", rowDataList);

          this.setState({ rowDataList, domain_messageid_files });
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
          resolve();
          // });
        });
    });

    do {
      if (last_key) {
        requestBody["lastEvaluatedKey"] = last_key;
      }

      delete requestBody["first_time_loading"];

      await new Promise((resolve, reject) => {
        axios
          .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
          .then((res) => {
            let rowData;
            let isEngaged;
            let userDataList = res.data.data;
            last_key = res.data.lastEvaluatedKey;
            if (requestBody["tab"] == "tab1") {
              isEngaged = true;
            } else if (requestBody["tab"] == "tab2") {
              isEngaged = false;
            }
            for (let userData of userDataList) {
              let quote_status;
              let excluded_status, email_specific_status;
              try {
                let emodStatesData = userData.emodStatesData;
                let payrollData = userData.payrollData;
                let companyProfile = userData.companyProfile;

                let payroll = 0,
                  emod = 1;

                for (let email in userData.email_specific) {
                  let db_quote_status =
                    userData.email_specific[email].quote_status;
                  let db_excluded =
                    userData.email_specific[email].nonEligibilityList;
                  if (db_excluded && db_excluded.length > 0) {
                    excluded_status = true;
                  }

                  if (
                    userData.email_specific[email].companyProfile &&
                    userData.email_specific[email].companyProfile.status &&
                    userData.email_specific[email].companyProfile.status
                      .value &&
                    !email_specific_status
                  ) {
                    email_specific_status =
                      userData.email_specific[email].companyProfile.status
                        .value;
                  }

                  if (
                    (!quote_status && db_quote_status) ||
                    (quote_status === "reject" && db_quote_status) ||
                    db_quote_status === "proceed_uw"
                  ) {
                    quote_status = db_quote_status;
                  }
                }

                if (
                  emodStatesData &&
                  emodStatesData[Object.keys(emodStatesData)[0]].rate1
                ) {
                  emod =
                    emodStatesData[Object.keys(emodStatesData)[0]].rate1.value;
                }

                if (payrollData) {
                  for (let state in payrollData) {
                    for (let pay in payrollData[state].classCodesInfo) {
                      let pyrll = numeral(
                        payrollData[state].classCodesInfo[pay].payroll.value
                      ).value();

                      payroll += parseInt(pyrll);
                    }
                  }
                }

                let step;
                if (Number(userData.proposalGeneratedCount) > 0) {
                  step = "Proposal Generated";
                } else if (userData.quote_status) {
                  if (userData.quote_status === "uwportal") {
                    step = "UW Portal";
                  } else if (userData.quote_status === "proceed_uw") {
                    step = "Approved";
                  } else if (userData.quote_status === "reject") {
                    step = "Rejected";
                  }
                } else if (Number(userData.quoteGeneratedCount) > 0) {
                  step = "Quote Generated";
                } else if (Number(userData.visitCount) > 0) {
                  if (userData.formStage) {
                    if (userData.formStage === "two") {
                      if (userData.nonEligibilityList) {
                        step = "Not Eligible";
                      } else {
                        step = "UW Questions";
                      }
                    } else if (userData.formStage === "one") {
                      step = "Company Profile";
                    }
                  } else {
                    step = "Landing Page";
                  }
                } else if (email_specific_status) {
                  step = email_specific_status;
                } else {
                  step = "-";
                }
                let instance;
                if (userData.isFourth) {
                  instance = "fourth";
                } else if (userData.isSolve) {
                  instance = "solve";
                } else {
                  instance = "affinity";
                }

                let total_emp = 0;
                if (userData.payrollData) {
                  let childrenLoc = userData.payrollData;
                  for (let locIndex in childrenLoc) {
                    let loc = childrenLoc[locIndex];
                    for (let ccIndex in loc.classCodesInfo) {
                      let cc = loc.classCodesInfo[ccIndex];
                      total_emp =
                        total_emp +
                        Number(cc?.ft?.value || 0) +
                        Number(cc?.pt?.value || 0);
                    }
                  }
                }

                rowData = {
                  messageID: userData.messageID,
                  dashboardFiles: userData.dashboardFiles || [],
                  visitDate:
                    userData.timestamp &&
                    moment(Number(userData.timestamp))
                      .utcOffset("-0500")
                      .format("M/D/YYYY - hh:mm A"),
                  userEmail: companyProfile?.emailId
                    ? companyProfile.emailId.value
                    : "",
                  signedUp: userData.signedUp === "true" ? "Yes" : "No",
                  quoteGenerated: userData.quoteGenerated || "",
                  proposalGenerated: userData.proposalGenerated || "",
                  phoneNumber: companyProfile?.phoneNumber
                    ? companyProfile.phoneNumber.value
                    : "",
                  effectiveDate:
                    companyProfile &&
                    companyProfile &&
                    companyProfile.effectiveDate
                      ? moment(companyProfile.effectiveDate.value).format("x")
                      : "",
                  payroll: payroll,
                  indicationCost: userData.indicationCost,
                  emod:
                    emod && Number(emod) ? parseFloat(emod).toFixed(2) : "-",
                  step,
                  contactName:
                    (companyProfile?.primaryContact
                      ? companyProfile.primaryContact.value
                      : "") ||
                    userData.contactName ||
                    "",
                  companyName:
                    (companyProfile?.companyName
                      ? companyProfile.companyName.value
                      : "") ||
                    userData.companyName ||
                    "",
                  comment: userData?.comment || "",
                  agpsComment:
                    userData?.companyProfile?.agpsComment?.value || "",
                  domain: userData.domain,
                  visitCount: userData.visitCount || "0",
                  email_specific: userData.email_specific,
                  latestCampaign: userData.latestCampaign || "",
                  tabName,
                  instance,
                  zohoDate: userData.zywave_uploaded_date || "1991-1-1",

                  companyProfile: userData.companyProfile || "",
                  fein: userData.fein,
                  childrenLoc: userData.payrollData,
                  isEngaged: isEngaged,
                  agpsFiles: userData?.agpsFiles || [],
                  source: userData?.source || "",
                  preUwName: userData?.preUwData?.userId || "",
                  preUwDate:
                    userData.preUwDate &&
                    moment(Number(userData.preUwDate))
                      .utcOffset("-0500")
                      .format("M/D/YYYY - hh:mm A"),
                  total_emp,
                  uploadedDate: userData?.UploadedDate
                    ? moment(userData?.UploadedDate, "x").format("M/D/YYYY")
                    : "-",

                  state:
                    userData?.companyProfile?.state1?.value || userData?.state,
                  agpsWorkStatus: userData.agpsWorkStatus || "",
                  agpsSubmittedDate: userData?.agpsSubmittedDate
                    ? moment(userData?.agpsSubmittedDate, "x").format(
                        "M/D/YYYY"
                      )
                    : "-",
                  agpsuserName: userData?.agpsuserName || "",
                };

                if (rowData.email_specific) {
                  Object.values(rowData.email_specific).map((email) => {
                    if (
                      email.companyProfile &&
                      email.companyProfile.contact_number &&
                      email.companyProfile.contact_number.value
                    ) {
                      email.companyProfile.contact_number.value = this.appendRemaining(
                        email.companyProfile.contact_number.value.toString()
                      );
                    }
                  });
                } else {
                  if (rowData.phoneNumber)
                    rowData.phoneNumber = this.appendRemaining(
                      rowData.phoneNumber.toString()
                    );
                }

                domain_messageid_files[rowData.domain] = {};
                if (rowData.messageID)
                  domain_messageid_files[rowData.domain]["messageID"] =
                    rowData.messageID;
                if (rowData.dashboardFiles)
                  domain_messageid_files[rowData.domain]["dashboardFiles"] =
                    rowData.dashboardFiles;

                if (userData.preUwFiles) {
                  let _preUwFiles = [];
                  userData.preUwFiles.forEach((ele) => {
                    _preUwFiles.push(ele.key);
                  });
                  domain_messageid_files[userData.domain][
                    "preUwFiles"
                  ] = _preUwFiles;
                }

                // agps files to show in dashboard
                if (userData.agpsFiles) {
                  let _agpsFiles = [];
                  userData.agpsFiles.forEach((ele) => {
                    _agpsFiles.push(ele.key);
                  });
                  domain_messageid_files[userData.domain][
                    "agpsFiles"
                  ] = _agpsFiles;
                }

                if (userData.fileListToStore) {
                  let _fileListToStore = [];
                  userData.fileListToStore.forEach((ele) => {
                    _fileListToStore.push(ele.key);
                  });
                  domain_messageid_files[userData.domain][
                    "fileListToStore"
                  ] = _fileListToStore;
                }

                rowDataList.push(rowData);
              } catch (error) {
                console.log("error: ", error);
              }
            }
            // console.log("rowDataList: ", rowDataList);

            this.setState({ rowDataList, domain_messageid_files });
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          })
          .finally(() => {
            this.setState({ isLoading: false });
            resolve();
            // });
          });
      });
    } while (last_key);
  };

  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
        this.setState({ scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  render() {
    let { selected, tabName } = this.props;
    let { showModel, modalTitle, modalContent } = this.state;
    return (
      <div
        className={`Admin-Dashboard ${tabName}`}
        style={
          tabName !== selected ? { display: "none", fontSize: "12px" } : {}
        }
      >
        <div ref={this.myRef}>{this.setTableData()}</div>
        <Modal
          show={showModel}
          onHide={() => {
            this.setState({
              showModel: false,
              modalTitle: null,
              modalContent: null,
            });
          }}
          dialogClassName="modal-full"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DashboardAdmin;
