import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home3 from "./component/pages/Home3";
import AgentSignup from "./component/pages/AgentSignup";
import AgentAgreement from "./component/pages/AgentAgreement";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import ValidateDashboard from "./component/pages/ValidateDashboard";
import AgentDashboard from "./component/pages/AgentDashboard";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import ProgramInfo from "./component/pages/ProgramInfo";
import NewDomain from "./component/pages/NewDomain";
import DemoTool from "./component/pages/DemoTool";
import Preuwstageonepage from "./component/pages/Preuwstageonepage";
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <PrivateGroupRoute
            exact
            path="/Validate"
            component={ValidateDashboard}
            allowedGroup={[process.env.REACT_APP_ADMIN_GROUP]}
          />
          <PrivateGroupRoute
            allowedGroup={[
              process.env.REACT_APP_AGPSAGENT_GROUP,
              process.env.REACT_APP_ADMIN_GROUP,
            ]}
            exact
            path="/AdminDashboard"
            component={Tabs}
          />

          <Route
            exact
            // allowedGroup={[process.env.REACT_APP_AGENT_GROUP]}
            path="/Quote"
            component={NewQuote}
          />

          <PrivateGroupRoute
            allowedGroup={[process.env.REACT_APP_AGENT_GROUP]}
            exact
            path="/AgentDashboard"
            component={AgentDashboard}
          />

          <AntiPrivateRoute path="/Login" component={Login} />
          <PrivateGroupRoute
            exact
            allowedGroup={[
              process.env.REACT_APP_AGENT_GROUP,
              process.env.REACT_APP_ADMIN_GROUP,
            ]}
            path="/StageOne"
            component={Preuwstageonepage}
          />
          <Route
            exact
            // allowedGroup={[process.env.REACT_APP_AGENT_GROUP]}
            path="/program-info"
            component={ProgramInfo}
          />

          <Route exact path="/NewDomain" component={NewDomain} />
          <Route
            // allowedGroup={[process.env.REACT_APP_CARRIER_GROUP]}
            exact
            path="/PanelUW"
            component={PanelUW}
          />
          <Route exact path="/schedule-demo" component={DemoTool} />
          <Route path="/" component={Home3} />
        </Switch>
      </Router>
    );
  }
}

export default App;
