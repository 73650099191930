import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const DropUploadZone = ({ fileType, setFiles }) => {
  const [fileName, setFileName] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFileName(acceptedFiles[0]?.name);
    setFiles(acceptedFiles[0]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "text/csv",
  });
  const additionalClass = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : "";
  return (
    <div className="Drop">
      <div className="filetype">Upload {fileType} Files</div>
      <div
        {...getRootProps({ isDragAccept, isDragReject })}
        className={`dropzone ${additionalClass}`}
      >
        <span className="dragActive">{isDragActive ? "📂" : "📁"}</span>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
        <span className="files">{fileName}</span>
      </div>
    </div>
  );
};

export default DropUploadZone;
