import React, { useState } from "react";
import axios from "axios";
import { demoToolUrl } from "../../../config";
import NumberFormat from "react-number-format";

function InputForm(props) {
  let [formData, updateFormData] = useState({
    name: { value: "" },
    company: { value: "" },
    email: { value: "" },
    phone: { value: "" },
  });
  let [formSubmitted, updateFormSubmitted] = useState(false);
  let [formSubmissionLoading, updateFormSubmissionLoading] = useState(false);

  const handleFormUpdate = (e) => {
    let { id: key, value } = e.target;
    let data = { ...formData };
    data[key].value = value;
    updateFormData(data);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    updateFormSubmissionLoading(true);
    let requestBody = {
      name: formData?.name?.value,
      email: formData?.email?.value,
      company: formData?.company?.value,
      phone: formData?.phone?.value,
      source: "affinity",
    };

    try {
      await axios.post(demoToolUrl + "/api/submitDemoRequest", requestBody);
    } catch (error) {
      console.log(error);
    }
    updateFormSubmitted(true);
    updateFormSubmissionLoading(false);
  };

  return (
    <div className="demo-input-form-section d-flex flex-column">
      <div className="input-form-header text-center">Schedule A Free Demo</div>
      <div className="form-container">
        {formSubmitted ? (
          <div className="form-success text-center">
            Thank you for your interest. Our team will get in touch with you
            shortly.
          </div>
        ) : (
          <form
            onChange={handleFormUpdate}
            onSubmit={handleFormSubmit}
            className="d-flex flex-column"
          >
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="name">Name</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name.value}
                  placeholder="Name"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="email">Email</label>
                <input
                  required
                  type="email"
                  className="form-control"
                  id="email"
                  value={formData.email.value}
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="company">Company</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="company"
                  value={formData.company.value}
                  placeholder="Company Name"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="phone">Phone</label>
                <NumberFormat
                  required
                  value={formData.phone.value}
                  className="form-control"
                  id="phone"
                  format="(###) ###-####"
                  placeholder="Enter Phone"
                />
              </div>
            </div>
            <button type="submit" className="align-self-center btn">
              {formSubmissionLoading && (
                <span
                  class="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default InputForm;
