import React from "react";
import DashboardAdmin from "./DashboardAdmin";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { logout } from "../../utils/common";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab2",
      userName: "user",
    };
  }

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_AGPSAGENT_GROUP)) {
          this.setState({ isLoggedIn: true, userName });
        }

        if (groups.includes(process.env.REACT_APP_AGPSADMIN_GROUP)) {
          this.setState({ isLoggedIn: true, userName, AdminLogin: true });
        }
      }
    } catch (error) {}
  };
  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected, userName, AdminLogin } = this.state;
    const tabsMap = {
      // tab1: { name: "Engaged", enabled: true, visible: true },
      tab2: { name: "Campaigned", enabled: true, visible: true },
      // tab3: { name: "Salesforce", enabled: false, visible: true },
      // tab4: { name: "Not Eligible", enabled: false, visible: false },
    };

    return (
      <div className="dashboard-admin">
        <div className="d-flex" style={{ borderBottomStyle: "groove" }}>
          <Header />
          <li
            id="Dropprofile"
            className="d-flex align-items-center text-dark  logout_menu"
          >
            <span
              className="dropdown-toggle text-dark text-capitalize"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <b>{userName}</b>
            </span>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={logout}>
                <b>Logout</b>
              </a>
            </div>
            {/* <DropUserProfile username={this.state.userName} /> */}
          </li>
        </div>

        <div>
          <button
            className="float-right mt-2 mb-2 mr-3"
            style={{
              fontSize: "16px",
              fontFamily: "Montserrat",
              backgroundColor: "#ffd13f",
              border: "none",
              borderRadius: "4px",
              boxShadow: "0.5px 2px 0.4px grey",
            }}
            onClick={() => {
              window.location.href = "/StageOne";
            }}
          >
            <Link
              to="/StageOne"
              style={{ color: "black", textDecoration: "none" }}
            >
              {" "}
              Start New Prospect
            </Link>
          </button>
          {AdminLogin ? (
            <button
              onClick={() => {
                window.location.href = "/validate";
              }}
              className="float-right mt-2 mb-2 mr-3"
              style={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                backgroundColor: "#ffd13f",
                border: "none",
                borderRadius: "4px",
                boxShadow: "0.5px 2px 0.4px grey",
              }}
            >
              <Link
                to="/validate"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                Upload Dashboard
              </Link>
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="Admin__Dashboard">
          <div className="tabs p-4">
            <Nav variant="pills" defaultActiveKey={selected}>
              {Object.keys(tabsMap).map((tab) =>
                tabsMap[tab].visible ? (
                  <Nav.Item className={tabsMap[tab].enabled ? "" : "pl-1"}>
                    <Nav.Link
                      className={tabsMap[tab].enabled ? "" : "btn  text-grey"}
                      eventKey={tab}
                      onClick={() => this.setSelected(tab)}
                      disabled={!tabsMap[tab].enabled}
                    >
                      {tabsMap[tab].name}
                    </Nav.Link>
                  </Nav.Item>
                ) : null
              )}
            </Nav>
            {Object.keys(tabsMap).map((tab) => (
              <div key={tab}>
                {<DashboardAdmin tabName={tab} selected={selected} />}
              </div>
            ))}
          </div>
        </div>
        <Loader />
      </div>
    );
  }
}

export default Tabs;
