import React, { Component } from "react";
import numeral from "numeral";
import QuoteRow from "./QuoteRow";
import tempLogo from "../../images/new-logo.png";
import tempLogo1 from "../../images/new-logo-1.png";
import tempLogo2 from "../../images/new-logo-2.png";
import tempLogo3 from "../../images/new-logo-3.png";

const genericSolvePeoBasic = (peoName) => {
  return (
    <>
      <b>Program attributes - {peoName}</b>
      <ul>
        <li>A-Rated Carrier</li>
        <li>Human Resource Services</li>
        <li>Traditional or Web-Based Payroll Services</li>
        <li>Management of Unemployment Claims</li>
      </ul>
    </>
  );
};

const genericSolvePeoPlus = (peoName) => {
  return (
    <>
      <b>Program attributes - {peoName}</b>
      <ul>
        <li>A-Rated Carrier</li>
        <li>Sponsored Benefit Plan Administration & HR Services</li>
        <li>Traditional or Web-Based Payroll Services</li>
        <li>Management of Unemployment Claims</li>
      </ul>
    </>
  );
};

const genericWCN = () => {
  return (
    <>
      <b>Program attributes - PEO 2</b>
      <ul>
        <li>A-Rated Carrier</li>
        <li>24 Hour underwriting approval</li>
        <li>Easy & Affordable coverage</li>
        <li>Highly reactive and attentive sales and service support</li>
      </ul>
    </>
  );
};

const genericPrideRiskInfo = () => {
  return (
    <>
      <b>Program attributes - PEO 3</b>
      <ul>
        <li>A-Rated Carrier</li>
        <li>30+ years of coverage and industry experience</li>
        <li>~ $3 Billion in revenue serving 6,000+ clients</li>
        <li>Laser focused approach proven measures benefiting client </li>
      </ul>
    </>
  );
};

class QuoteTable extends Component {
  constructor(props) {
    super(props);
  }

  flipCheckbox = (peoType, carrier, val) => {
    let { quoteRows } = this.props;
    for (let peo in quoteRows) {
      for (let carr in quoteRows[peo]) {
        quoteRows[peo][carr].selected = false;
      }
    }

    quoteRows[peoType][carrier].selected = val;
    this.props.updateQuoteRows(quoteRows);
  };

  createFourthQuoteData(childrenLoc, carrBasedData, teapMap, peo, quoteStatus) {
    let quoteFactors = {
      teap: 0,
      total: 0,
      futa: 0,
      fica: 0,
      suta: 0,
      payroll: 0,
      adminFee: 0,
      employeeCount: 0,
      adminFeeTotal: 0,
    };

    let teap = 0;

    for (let location in carrBasedData["carrier_p"]) {
      if (
        carrBasedData["carrier_p"][location] !== "Dropped" &&
        carrBasedData["carrier_p"][location].carrier_location_data
      ) {
        quoteFactors.teap += Number(
          carrBasedData["carrier_p"][location].carrier_location_data
            .total_estimated_annual_premium
        );
        teap += Number(
          carrBasedData["carrier_p"][location].carrier_location_data
            .total_estimated_annual_premium
        );
        quoteFactors.total += Number(
          carrBasedData["carrier_p"][location].carrier_location_data.total
        );
        quoteFactors.futa = Number(
          carrBasedData["carrier_p"][location].carrier_location_data.futa
        );
        quoteFactors.fica = Number(
          carrBasedData["carrier_p"][location].carrier_location_data.fica
        );
        quoteFactors.suta =
          carrBasedData["carrier_p"][location].carrier_location_data.suta;
      }
    }

    teapMap[peo] = teap;

    for (let stateIndex in childrenLoc) {
      for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        quoteFactors.payroll += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
        ).value();
        quoteFactors.employeeCount += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft.value
        )
          .add(childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt.value)
          .value();
      }
    }

    let solveQuote =
      quoteFactors && quoteFactors.total && quoteFactors.total !== "0"
        ? true
        : false;

    if (solveQuote) {
      return {
        first: (
          <div className="d-flex flex-column imageinrow">
            <div className="mb-2 teap">
              <b>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                }).format(numeral(quoteFactors.total).value())}
              </b>
            </div>
            <div className="mb-2 icompimagestatus">
              <img src={tempLogo1} alt="peo logo" className="peo-logo" />
            </div>
            {quoteStatus === "uw" ? (
              <div className="quote-status" style={{ color: "#FCAD00" }}>
                Awaiting UW Approval
              </div>
            ) : quoteStatus === "reject" ? (
              <div className="quote-status" style={{ color: "red" }}>
                Rejected
              </div>
            ) : (
              <div className="quote-status" style={{ color: "#077407" }}>
                Approved
              </div>
            )}
          </div>
        ),
        second: (
          <div className="d-flex flex-column">
            <table className="payroll-services-table">
              <tbody>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Workers' Comp:</b>
                  </td>
                  <td className="align-bottom" width="50%">
                    {(
                      Math.round(
                        numeral(quoteFactors.teap)
                          .divide(quoteFactors.payroll)
                          .multiply(10000)
                          .value()
                      ) / 100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FICA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.fica)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.futa)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>SUTA:</b>
                  </td>
                  <td width="50%">
                    {quoteFactors.suta === "client_suta"
                      ? "Client SUTA"
                      : numeral(quoteFactors.suta)
                        .value()
                        .toFixed(2) + "%"}
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Admin Fee:</b>
                  </td>
                  <td className="align-bottom" width="50%">
                    {" "}
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }).format(quoteFactors.adminFee || 0)}{" "}
                    PEPM
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        third: <>{genericPrideRiskInfo()}</>,
      };
    } else {
      return undefined;
    }
  }

  createSolveDummyQuoteData(
    childrenLoc,
    carrBasedData,
    teapMap,
    peo,
    quoteStatus
  ) {
    let quoteFactors = {
      teap: 0,
      total: 0,
      futa: 0,
      fica: 0,
      suta: 0,
      payroll: 0,
      adminFee: 0,
      employeeCount: 0,
      adminFeeTotal: 0,
    };

    let teap = 0;

    for (let location in carrBasedData["carrier_n"]) {
      if (
        carrBasedData["carrier_n"][location] !== "Dropped" &&
        carrBasedData["carrier_n"][location].carrier_location_data
      ) {
        quoteFactors.adminFee =
          carrBasedData["carrier_n"][location].carrier_location_data.admin_fee;
        quoteFactors.teap += Number(
          carrBasedData["carrier_n"][location].carrier_location_data
            .total_estimated_annual_premium
        );
        teap += Number(
          carrBasedData["carrier_n"][location].carrier_location_data
            .total_estimated_annual_premium
        );
        quoteFactors.total += Number(
          carrBasedData["carrier_n"][location].carrier_location_data.total
        );
        quoteFactors.futa = Number(
          carrBasedData["carrier_n"][location].carrier_location_data.futa
        );
        quoteFactors.fica = Number(
          carrBasedData["carrier_n"][location].carrier_location_data.fica
        );
        quoteFactors.suta = Number(
          carrBasedData["carrier_n"][location].carrier_location_data.suta
        );
      }
    }

    teapMap[peo] = teap;

    for (let stateIndex in childrenLoc) {
      for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        quoteFactors.payroll += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
        ).value();
        quoteFactors.employeeCount += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft.value
        )
          .add(childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt.value)
          .value();
      }
    }

    quoteFactors.adminFeeTotal = numeral(quoteFactors.employeeCount)
      .multiply(quoteFactors.adminFee)
      .multiply(12)
      .value();

    let solveQuote =
      quoteFactors && quoteFactors.total && quoteFactors.total !== "0"
        ? true
        : false;

    if (solveQuote) {
      return {
        first: (
          <div className="d-flex flex-column">
            <div className="mb-2 teap">
              <b>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                }).format(
                  numeral(quoteFactors.total)
                    .add(quoteFactors.adminFeeTotal)
                    .value()
                )}
              </b>
            </div>
            <div className="mb-2 icompimagestatus">
              <img src={tempLogo} alt="peo logo" className="peo-logo" />
            </div>
            {quoteStatus === "uw" ? (
              <div className="quote-status" style={{ color: "#FCAD00" }}>
                Awaiting UW Approval
              </div>
            ) : quoteStatus === "reject" ? (
              <div className="quote-status" style={{ color: "red" }}>
                Rejected
              </div>
            ) : (
              <div className="quote-status" style={{ color: "#077407" }}>
                Approved
              </div>
            )}
          </div>
        ),
        second: (
          <div className="d-flex flex-column">
            <table className="payroll-services-table">
              <tbody>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Workers' Comp:</b>
                  </td>
                  <td className="align-bottom" width="50%">
                    {(
                      Math.round(
                        numeral(quoteFactors.teap)
                          .divide(quoteFactors.payroll)
                          .multiply(10000)
                          .value()
                      ) / 100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FICA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.fica)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.futa)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>SUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.suta)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Admin Fee:</b>
                  </td>
                  <td width="50%" className="align-bottom">
                    {" "}
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }).format(quoteFactors.adminFee || 0)}{" "}
                    PEPM
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        third: (
          <>
            {quoteFactors.adminFee === 60
              ? genericSolvePeoPlus("PEO 4")
              : genericSolvePeoBasic("PEO 4")}
          </>
        ),
      };
    } else {
      return undefined;
    }
  }

  createSolveQuoteData(childrenLoc, carrBasedData, teapMap, peo, quoteStatus) {
    let quoteFactors = {
      teap: 0,
      total: 0,
      futa: 0,
      fica: 0,
      suta: 0,
      payroll: 0,
      adminFee: 0,
      employeeCount: 0,
      adminFeeTotal: 0,
    };

    let teap = 0;

    for (let location in carrBasedData["carrier_n"]) {
      if (
        carrBasedData["carrier_n"][location] !== "Dropped" &&
        carrBasedData["carrier_n"][location].carrier_location_data
      ) {
        quoteFactors.adminFee =
          carrBasedData["carrier_n"][location].carrier_location_data.admin_fee;
        quoteFactors.teap += Number(
          carrBasedData["carrier_n"][location].carrier_location_data
            .total_estimated_annual_premium
        );
        teap += Number(
          carrBasedData["carrier_n"][location].carrier_location_data
            .total_estimated_annual_premium
        );
        quoteFactors.total += Number(
          carrBasedData["carrier_n"][location].carrier_location_data.total
        );
        quoteFactors.futa = Number(
          carrBasedData["carrier_n"][location].carrier_location_data.futa
        );
        quoteFactors.fica = Number(
          carrBasedData["carrier_n"][location].carrier_location_data.fica
        );
        quoteFactors.suta = Number(
          carrBasedData["carrier_n"][location].carrier_location_data.suta
        );
      }
    }

    teapMap[peo] = teap;

    for (let stateIndex in childrenLoc) {
      for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        quoteFactors.payroll += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
        ).value();
        quoteFactors.employeeCount += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft.value
        )
          .add(childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt.value)
          .value();
      }
    }

    quoteFactors.adminFeeTotal = numeral(quoteFactors.employeeCount)
      .multiply(quoteFactors.adminFee)
      .multiply(12)
      .value();

    let solveQuote =
      quoteFactors && quoteFactors.total && quoteFactors.total !== "0"
        ? true
        : false;

    if (solveQuote) {
      return {
        first: (
          <div className="d-flex flex-column imageinrow">
            <div className="mb-2 teap">
              <b>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                }).format(
                  numeral(quoteFactors.total)
                    .add(quoteFactors.adminFeeTotal)
                    .value()
                )}
              </b>
            </div>
            <div className="mb-2 icompimagestatus">
              {quoteFactors.adminFee === 60 ? <img src={tempLogo2} alt="peo logo" className="peo-logo" /> : <img src={tempLogo3} alt="peo logo" className="peo-logo" />}
            </div>
            {quoteStatus === "uw" ? (
              <div className="quote-status" style={{ color: "#FCAD00" }}>
                Awaiting UW Approval
              </div>
            ) : quoteStatus === "reject" ? (
              <div className="quote-status" style={{ color: "red" }}>
                Rejected
              </div>
            ) : (
              <div className="quote-status" style={{ color: "#077407" }}>
                Approved
              </div>
            )}
          </div>
        ),
        second: (
          <div className="d-flex flex-column">
            <table className="payroll-services-table">
              <tbody>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Workers' Comp:</b>
                  </td>
                  <td className="align-bottom" width="50%">
                    {(
                      Math.round(
                        numeral(quoteFactors.teap)
                          .divide(quoteFactors.payroll)
                          .multiply(10000)
                          .value()
                      ) / 100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FICA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.fica)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.futa)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>SUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.suta)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Admin Fee:</b>
                  </td>
                  <td className="align-bottom" width="50%">
                    {" "}
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }).format(quoteFactors.adminFee || 0)}{" "}
                    PEPM
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        third: (
          <>
            {quoteFactors.adminFee === 60
              ? genericSolvePeoPlus("PEO 2")
              : genericSolvePeoBasic("PEO 1 ")}
          </>
        ),
      };
    } else {
      return undefined;
    }
  }

  createWorkCompNowQuoteData(
    childrenLoc,
    carrBasedData,
    teapMap,
    peo,
    quoteStatus
  ) {
    return {
      first: (
        <div className="d-flex flex-column">
          <div className="mb-2 teap">
            <b>$ -</b>
          </div>
          <div className="mb-2 icompimagestatus">
            <img src={tempLogo} alt="peo logo" className="peo-logo" />
          </div>
          {quoteStatus === "uw" ? (
            <div className="quote-status" style={{ color: "#FCAD00" }}>
              Awaiting UW Approval
            </div>
          ) : quoteStatus === "reject" ? (
            <div className="quote-status" style={{ color: "red" }}>
              Rejected
            </div>
          ) : (
            <div className="quote-status" style={{ color: "#077407" }}>
              Approved
            </div>
          )}
        </div>
      ),
      second: (
        <div className="d-flex flex-column">
          <table className="payroll-services-table">
            <tbody>
              <tr>
                <td width="50%" className="text-right pr-3">
                  <b>Workers' Comp:</b>
                </td>
                <td className="align-bottom" width="50%">
                  -
                </td>
              </tr>
              <tr>
                <td width="50%" className="text-right pr-3">
                  <b>FICA:</b>
                </td>
                <td width="50%">-</td>
              </tr>
              <tr>
                <td width="50%" className="text-right pr-3">
                  <b>FUTA:</b>
                </td>
                <td width="50%">-</td>
              </tr>
              <tr>
                <td width="50%" className="text-right pr-3">
                  <b>SUTA:</b>
                </td>
                <td width="50%">-</td>
              </tr>
              <tr>
                <td width="50%" className="text-right pr-3">
                  <b>Admin Fee:</b>
                </td>
                <td width="50%" className="align-bottom">
                  -
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
      third: <>{genericWCN()}</>,
    };
  }

  createPrideQuoteData(childrenLoc, carrBasedData, teapMap, peo, quoteStatus) {
    let teap = 0;
    let quoteFactors = {
      admin: 0,
      oldAdminFee: 0,
      adminFee: 0,
      payroll: 0,
      total: 0,
      futa: 0,
      fica: 0,
      suta: 0,
    };

    for (let location in carrBasedData["carrier_q"]) {
      teap += Number(
        carrBasedData["carrier_q"][location].carrier_location_data
          .total_estimated_annual_premium || 0
      );
      if (
        carrBasedData["carrier_q"][location].carrier_location_data.total &&
        !quoteFactors.total
      ) {
        quoteFactors.total = Number(
          carrBasedData["carrier_q"][location].carrier_location_data.total
        );
      }
      if (!quoteFactors.admin) {
        quoteFactors.admin =
          carrBasedData["carrier_q"][location].carrier_location_data.admin;
      }
      if (!quoteFactors.oldAdminFee) {
        quoteFactors.oldAdminFee =
          carrBasedData["carrier_q"][location].carrier_location_data.admin_fee;
      }

      if (!quoteFactors.futa) {
        quoteFactors.futa = Number(
          carrBasedData["carrier_q"][location].carrier_location_data.futa
        );
      }

      if (!quoteFactors.fica) {
        quoteFactors.fica = Number(
          carrBasedData["carrier_q"][location].carrier_location_data.fica
        );
      }

      if (!quoteFactors.suta) {
        quoteFactors.suta = Number(
          carrBasedData["carrier_q"][location].carrier_location_data.suta
        );
      }
    }

    for (let stateIndex in childrenLoc) {
      for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        quoteFactors.payroll += numeral(
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
        ).value();
      }
    }

    quoteFactors.adminFee = quoteFactors.payroll
      ? Math.round(
        numeral(quoteFactors.payroll)
          .multiply(1.75)
          .divide(100)
          .value()
      )
      : 0;

    teapMap[peo] = teap;
    if (teap !== 0) {
      return {
        first: (
          <div className="d-flex flex-column imageinrow">
            <div className="mb-2 teap">
              <b>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                }).format(
                  numeral(quoteFactors.total)
                    .add(quoteFactors.oldAdminFee)
                    .value()
                )}
              </b>
            </div>
            <div className="mb-2 icompimagestatus">
              <img src={tempLogo} alt="peo logo" className="peo-logo" />
            </div>
            {quoteStatus === "uw" ? (
              <div className="quote-status" style={{ color: "#FCAD00" }}>
                Awaiting UW Approval
              </div>
            ) : quoteStatus === "reject" ? (
              <div className="quote-status" style={{ color: "red" }}>
                Rejected
              </div>
            ) : (
              <div className="quote-status" style={{ color: "#077407" }}>
                Approved
              </div>
            )}
          </div>
        ),
        second: (
          <div className="d-flex flex-column">
            <table className="payroll-services-table">
              <tbody>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Workers' Comp:</b>
                  </td>
                  <td className="align-bottom" width="50%">
                    {(
                      Math.round(
                        numeral(teap)
                          .divide(quoteFactors.payroll)
                          .multiply(10000)
                          .value()
                      ) / 100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FICA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.fica)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>FUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.futa)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>SUTA:</b>
                  </td>
                  <td width="50%">
                    {numeral(quoteFactors.suta)
                      .value()
                      .toFixed(2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td width="50%" className="text-right pr-3">
                    <b>Admin Fee:</b>
                  </td>
                  <td width="50%" className="align-bottom">
                    {quoteFactors.admin + "%"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        third: <>{genericWCN()}</>,
      };
    } else {
      return undefined;
    }
  }

  componentDidMount() {
    let { quoteRows } = this.props;
    let { data } = this.props;
    let teapMap = {};
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let childrenLoc = currProspect.childrenLoc;
    var formStage = sessionStorage.getItem("formStage");

    if (formStage === "three") {
      quoteRows["solve_peo_basic"]["carrier_n"] = {
        selected: false,
        status: "uw",
      };
      quoteRows["solve_peo_plus"]["carrier_n"] = {
        selected: false,
        status: "uw",
      };
      quoteRows["pride"]["carrier_q"] = {
        selected: false,
        status: "uw",
      };
      quoteRows["fourth_peo_basic"]["carrier_p"] = {
        selected: false,
        status: "uw",
      };
    } else if (formStage === "four") {
      quoteRows = sessionStorage.getItem("quoteRows")
        ? JSON.parse(sessionStorage.getItem("quoteRows"))
        : quoteRows;
    }

    let functionMap = {
      solve_peo_basic: this.createSolveQuoteData,
      solve_peo_plus: this.createSolveQuoteData,
      solve_dummy: this.createSolveDummyQuoteData,
      work_comp_now: this.createWorkCompNowQuoteData,
      pride: this.createPrideQuoteData,
      fourth_peo_basic: this.createFourthQuoteData,
    };

    for (let peo in data) {
      for (let carrier in data[peo]) {
        quoteRows[peo][carrier].status = data[peo][carrier][
          Object.keys(data[peo][carrier])[0]
        ]
          ? data[peo][carrier][Object.keys(data[peo][carrier])[0]]
            .quote_status || "uw"
          : "uw";

        quoteRows[peo][carrier].data = functionMap[peo](
          childrenLoc,
          data[peo],
          teapMap,
          peo,
          quoteRows[peo][carrier].status
        );
      }
    }

    sessionStorage.setItem("teapMap", JSON.stringify(teapMap));
    sessionStorage.setItem("quoteRows", JSON.stringify(quoteRows));
    this.props.updateQuoteRows(quoteRows);

    // for (let peo in quoteRows) {
    //   for (let carr in quoteRows[peo]) {
    //     if (quoteRows[peo][carr].data) {
    //       quoteRows[peo][carr].selected = true;
    //       break;
    //     }
    //   }
    // }
  }

  render() {
    let { quoteRows } = this.props;

    let displayQuoteRows = [];

    for (let peo in quoteRows) {
      for (let carrier in quoteRows[peo]) {
        if (quoteRows[peo][carrier].data)
          displayQuoteRows.push({
            data: quoteRows[peo][carrier].data,
            peo,
            carrier,
          });
      }
    }

    return (
      <div>
        <h3 className="mb-4">
          <b>Boom! Here are your options!</b>
        </h3>
        <div className="text-right notification-text">
          *All % values are percentages of the total payroll
        </div>
        <div className="quote-table  ">
          <table className="primary-table-quote w-100">
            <tbody>
              {displayQuoteRows.map((row, index) => {
                return (
                  row.data &&
                  row.data.first &&
                  row.data.second &&
                  row.data.third && (
                    <QuoteRow
                      key={index}
                      index={index}
                      first={row.data.first}
                      second={row.data.second}
                      third={row.data.third}
                      peo={row.peo}
                      carrier={row.carrier}
                      flipCheckbox={this.flipCheckbox}
                      quoteRows={quoteRows}
                    />
                  )
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default QuoteTable;
