import React, { Component } from "react";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
} from "../../../utils/common";
import { awsUrl, awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import QuoteTable from "../../common/QuoteTable";
import DropZones from "../../common/DropZones";
import Tabs from "../../common/Tabs";
import NewProgress from "../../../newcomponent/common/NewProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      visible: false,
      uuid_carrier: [],
      fileURL: undefined,
      fileURL2: undefined,
      loadingAccord: false,
      isNoLossChckd: false,
      loadingNoLoss: false,
      quoteRows: {
        solve_peo_basic: {},
        solve_peo_plus: {},
        pride: {},
        fourth_peo_basic: {},
      },
    };
    this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.QuoteView = this.QuoteView.bind(this);
  }

  updateQuoteRows = (quoteRows) => {
    this.setState({ quoteRows }, () => {
      sessionStorage.setItem("quoteRows", JSON.stringify(quoteRows));
      this.generateAccordAndStore();
    });
  };

  componentWillMount() {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
    // if (!this.state.fileURL) this.generateAccordAndStore();
  }

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    var formStage = sessionStorage.getItem("formStage");
    if (formStage === "three" || formStage === "four") {
      $("#loader").css("display", "block");
      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );

      if (currProspectDetails !== null) {
        let user_email = currProspectDetails.companyProfile.emailId.value;
        axios
          .get(awsUrl + "/api/getUserDataStatus/" + user_email + "/quote/fein")
          .then((response) => {
            let firstPeoData =
              response.data.carrierBasedData[
                Object.keys(response.data.carrierBasedData)[0]
              ];
            let firstCarrierData = firstPeoData[Object.keys(firstPeoData)[0]];
            let quoteStatus =
              firstCarrierData[Object.keys(firstCarrierData)[0]].quote_status;

            let fileListToStore =
              firstCarrierData[Object.keys(firstCarrierData)[0]]
                .fileListToStore;
            let startDate = firstCarrierData[Object.keys(firstCarrierData)[0]]
              .dFrom
              ? moment(firstCarrierData[Object.keys(firstCarrierData)[0]].dFrom)
              : undefined;
            let nolossGenerated =
              firstCarrierData[Object.keys(firstCarrierData)[0]]
                .nolossGenerated || false;

            if (nolossGenerated) {
              this.generateNoLoss(startDate);
            }

            this.setState({
              quoteStatus,
              startDate,
              isNoLossChckd: nolossGenerated,
              uuid_carrier: response.data.uuid,
              data: response.data.carrierBasedData,
            });
            if (fileListToStore) {
              let fileList = {
                first: {},
                second: {},
              };
              for (let row in fileListToStore) {
                if (!fileList[fileListToStore[row].keyToFile])
                  fileList[fileListToStore[row].keyToFile] = {};
                fileList[fileListToStore[row].keyToFile][
                  fileListToStore[row].key.split("/").reverse()[0]
                ] = "#empty";
              }
              this.setState({ fileList });
            }
            $("#loader").css("display", "none");
          })
          .catch((err) => {
            console.log("error: ", err);
            showErrAlert(err);
            $("#loader").css("display", "none");
          });
      } else {
        sessionStorage.setItem("formStage", "one");
        deleteAllCookies();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }
  open() {
    this.setState({
      visible: true,
    });
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error");
        });
    });
  }

  getSelectedQuoteRow = () => {
    let { quoteRows } = this.state;
    let selected = {};
    for (let peo in quoteRows) {
      for (let carr in quoteRows[peo]) {
        if (quoteRows[peo][carr].selected) {
          selected = {
            peo,
            carr,
          };
          break;
        }
      }
    }
    return selected;
  };

  getStateList = (currProspect) => {
    let stateList = [];

    for (let indx in currProspect.childrenLoc) {
      if (currProspect.childrenLoc[indx].state) {
        stateList.push(currProspect.childrenLoc[indx].state.value);
      }
    }

    return stateList;
  };

  generateAccordAndStore = () => {
    //Note:
    //dynamic values of peo and carrier are taken in consideration.
    //whereas state is not taken into account for now.

    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let address = sessionStorage.getItem("address")
      ? JSON.parse(sessionStorage.getItem("address"))
      : undefined;
    let quoteData = sessionStorage.getItem("quoteData")
      ? JSON.parse(sessionStorage.getItem("quoteData"))
      : undefined;

    let selected = this.getSelectedQuoteRow();

    //important note at first line of function ^
    if (currProspect && address && quoteData && selected.peo && selected.carr) {
      let etQuoteDetails = {
        email: currProspect.companyProfile.emailId.value,
        timestamp: quoteData.date,
        carrier: selected.carr,
        mail_address: "",
        address: address,
        state: ["TX"],
        source: selected.peo,
      };

      // if (selected.peo === "pride" || selected.peo === "solve_dummy") {
      etQuoteDetails.state = this.getStateList(currProspect);
      // }

      this.setState({ loadingAccord: true });
      axios
        .post(
          awsUrl + "/api/generateAccordForm",
          JSON.stringify(etQuoteDetails)
        )
        .then((res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);

          var fileURL = URL.createObjectURL(file);

          this.setState({ fileURL, loadingAccord: false });
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error.");
          this.setState({ loadingAccord: false });
        });
    }
  };

  generateNoLoss = async (date) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;

    if (currProspect) {
      this.setState({ loadingNoLoss: true });
      await axios
        .post(
          awsUrl2 + "/api/getNoLossData",
          JSON.stringify({
            company: currProspect.companyProfile.companyName.value,
            name: currProspect.companyProfile.primaryContact.value,
            dFrom: date,
            fein: currProspect.companyProfile.fein.value,
          })
        )
        .then(async (res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);

          var fileURL2 = URL.createObjectURL(file);
          let promiseListToUpdateTable = [];
          for (let uuid_carr_indx in sortKeyList) {
            let dataToSend = {
              user_email_id: currProspect.companyProfile.emailId.value,
              uuid_carrier: sortKeyList[uuid_carr_indx],
              nolossGenerated: true,
              dFrom: date,
            };
            promiseListToUpdateTable.push(
              axios
                .post(
                  awsUrl2 + "/api/updateAffinityTechUserStatusTable",
                  dataToSend
                )
                .then((res) => {})
                .catch((error) => {
                  console.log(
                    "error in API-updateAffinityTechUserStatusTable",
                    error
                  );
                })
            );
          }
          await Promise.all(promiseListToUpdateTable).catch((err) =>
            console.log("error in promise of handleCheckClicked", err)
          );
          this.setState({ fileURL2, loadingNoLoss: false });
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("No loss is not available due to error.");
          this.setState({ loadingNoLoss: false });
        });
    }
  };

  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }

  startFresh() {
    sessionStorage.clear();
    window.location.reload();
  }

  handleBind = () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));

    let body = {
      companyName: currProspect.companyProfile.companyName.value,
      date: quoteData.date,
      uuid: quoteData.uuid,
      email: currProspect.companyProfile.emailId.value,
    };

    sessionStorage.setItem("formStage", "four");
    window.location.reload();

    // axios
    //   .post(awsUrl2 + "/api/sendBindMail", JSON.stringify(body))
    //   .then((response) => {
    //     console.log("bind mail sent successfully");
    //   })
    //   .catch((error) => {
    //     console.log("error while sending bind email.", error);
    //   })
    //   .finally(() => {
    //     try {
    //       let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
    //       let email = JSON.parse(sessionStorage.getItem("currProspect"))
    //         .companyProfile.emailId.value;

    //       let updateBody = {
    //         sortKeyList,
    //         email,
    //       };

    //       axios
    //         .post(
    //           awsUrl2 + "/api/updateSolveUsersData",
    //           JSON.stringify(updateBody)
    //         )
    //         .then((res) => {
    //           console.log("updated table data successfully");
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         })
    //         .finally(() => {
    //           sessionStorage.setItem("formStage", "four");
    //           window.location.reload();
    //         });
    //     } catch (error) {
    //       console.log("error", error);
    //       sessionStorage.setItem("formStage", "four");
    //       window.location.reload();
    //     }
    //   });
  };

  handleCheckClicked = async () => {
    let { isNoLossChckd } = this.state;
    if (isNoLossChckd) {
      let currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : undefined;
      let sortKeyList = sessionStorage.getItem("sortKeyList")
        ? JSON.parse(sessionStorage.getItem("sortKeyList"))
        : undefined;

      let promiseListToUpdateTable = [];

      for (let uuid_carr_indx in sortKeyList) {
        let dataToSend = {
          user_email_id: currProspect.companyProfile.emailId.value,
          uuid_carrier: sortKeyList[uuid_carr_indx],
          nolossGenerated: false,
          dFrom: "",
        };
        promiseListToUpdateTable.push(
          axios
            .post(
              awsUrl2 + "/api/updateAffinityTechUserStatusTable",
              dataToSend
            )
            .then((res) => {})
            .catch((error) => {
              console.log(
                "error in API-updateAffinityTechUserStatusTable",
                error
              );
            })
        );
      }
      await Promise.all(promiseListToUpdateTable).catch((err) =>
        console.log("error in promise of handleCheckClicked", err)
      );
    }
    this.setState({ isNoLossChckd: !isNoLossChckd, startDate: undefined });
  };

  handleDateSelected = (date) => {
    this.setState({ startDate: date });
    this.generateNoLoss(date);
  };

  render() {
    let { updatePopUpData } = this.props;
    let calculated_data = "";
    let {
      fileURL,
      fileList,
      quoteStatus,
      isNoLossChckd,
      fileURL2,
      loadingNoLoss,
      startDate,
      peoType,
      quoteRows,
    } = this.state;

    let tabList = [];

    let selected = this.getSelectedQuoteRow();

    if (isNoLossChckd && fileURL2 && startDate) {
      tabList.push({
        tabName: "No Loss",
        children: (
          <LoadingOverlay active={this.state.loadingNoLoss} spinner>
            <iframe
              id={`iframeNoLoss`}
              src={fileURL2}
              style={{ border: "none", height: "645px" }}
              width="100%"
            ></iframe>
          </LoadingOverlay>
        ),
      });
    }

    tabList.push({
      tabName: "Acord",
      children: (
        <LoadingOverlay active={this.state.loadingAccord} spinner>
          <iframe
            id={`iframeAccord`}
            src={fileURL}
            style={{ border: "none", height: "645px" }}
            width="100%"
          ></iframe>
        </LoadingOverlay>
      ),
    });

    if (this.state.data !== undefined && this.state.data !== null) {
      for (let carrier in this.state.data) {
        let requireData = this.getAllDataWRTCarrier(
          carrier,
          this.state.data[carrier]
        );
        calculated_data = requireData.calculated_data;
      }
    }

    if (fileList === undefined || fileList === null) {
      fileList = {
        first: {},
        second: {},
      };
    }
    let randNum = Math.round(Math.random() * 10);
    // console.log("this.state.data: ", this.state.data);
    // console.log("this.state.quoteRows: ", this.state.quoteRows);

    return (
      <div id="cmprQts" className="container-fluid per98">
        <NewProgress />
        <div id="compareQuote">
          {this.state.data && (
            <QuoteTable
              data={this.state.data}
              updateQuoteRows={this.updateQuoteRows}
              quoteRows={this.state.quoteRows}
            />
          )}
          {/* <div className="row">
            <div className="col-md-6 col-sm-12">
              <QuoteTable data={this.state.data} />
            </div>
            <div
              className="col-md-6 col-sm-12 d-flex justify-content-center"
              style={{ width: "auto" }}
            >
              {peoType && (
                <img
                  src={require(`../../../images/${peoType}_quote_image.jpeg`)}
                  alt="Quote"
                  style={{ width: "100%" }}
                  className="px-auto"
                ></img>
              )}
            </div>
          </div> */}
          <div className="mt-5 pb-5">
            <DropZones updatePopUpData={updatePopUpData} fileList={fileList} />
          </div>
          <div className="d-flex">
            <input
              className="mr-1"
              type="checkbox"
              id="chck-1"
              name={"chck"}
              checked={isNoLossChckd}
              disabled={loadingNoLoss || quoteStatus === "proceed_uw"}
              onClick={this.handleCheckClicked}
            />
            <label for="chck-1">Do you want to generate No loss letter?</label>
          </div>
          <div if="datepicker-startDate">
            {isNoLossChckd && (
              <>
                <label
                  for="startDate"
                  title="Business start date"
                  className="mr-2"
                >
                  Business Start Date
                </label>
                <span id="startDate">
                  <DatePicker
                    selected={startDate}
                    onChange={this.handleDateSelected}
                    maxDate={moment()}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    disabled={quoteStatus === "proceed_uw"}
                  />
                </span>
              </>
            )}
          </div>
          <div className="mt-2">
            <div className="row no-gutters d-flex justify-content-end w-100">
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    if (document.getElementById("iframeAccord"))
                      document
                        .getElementById("iframeAccord")
                        .contentWindow.location.reload();
                    if (document.getElementById("iframeNoLoss"))
                      document
                        .getElementById("iframeNoLoss")
                        .contentWindow.location.reload();
                  }}
                >
                  {"refresh"}
                </button>
              </div>
              <Tabs tabList={tabList} className="mt-4" />
            </div>
          </div>
          <div className="mt-5 text-center">
            <a href="https://beta.insurecomp.com" target="_blank">
              Not interested in PEO services as of now, only looking for
              workers’ compensation? CLICK HERE
            </a>
          </div>
          <div className="row mt-3 font-italic justify-content-center">
            <div className="d-flex flex-column">
              <button
                disabled={
                  (quoteRows &&
                    selected &&
                    selected.carr &&
                    selected.peo &&
                    quoteRows[selected.peo][selected.carr].status !==
                      "proceed_uw") ||
                  (selected && !selected.carr)
                }
                // style={{ backgroundColor: "#18314e" }}
                onClick={this.handleBind}
                type="button"
                className="align-self-center btn btn-color btn-lg btn-color-shadow proceed-bind"
              >
                View Proposal
              </button>
            </div>
          </div>
          {/* <div className="mt-5">
            <p>
              *This is a Pricing Indication only, and subject to detailed
              underwriting review. Our Account Executive will get back to you
              within 24 hours with next steps. Thank you for your business.
            </p>
          </div> */}
          {/*{this.state.data !== undefined && this.state.data !== null && (
            <div>
              {!this.state.visible && (
                <button type="button" onClick={this.open} className="btnGen">
                  Open
                </button>
              )}
              {this.state.visible && (
                <button type="button" onClick={this.close} className="btnGen">
                  Close
                </button>
              )}
              {this.state.visible && (
                <div>
                  <pre id="json">
                    {JSON.stringify(calculated_data, undefined, 10)}
                  </pre>
                </div>
              )}
            </div>
          )} */}
          <div> </div>
        </div>
      </div>
    );
  }
}
export default FormCmprQuote;
