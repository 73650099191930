import React, { Component } from "react";
let landingImage = require("../../../images/man_suit_thumb.webp");

class ImageBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToQuoteButton = () => {
    let { scrollSelector } = this.props;
    try {
      document.querySelector(scrollSelector).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } catch (error) {}
  };

  render() {
    return (
      <div
        className="image-block d-flex flex-column-reverse w-100"
        onClick={this.scrollToQuoteButton}
      >
        <div className="d-flex justify-content-center">
          <img src={landingImage} alt="landing" />
        </div>
        <div className="w-100 image-text-container">
          <span className="image-text">
            Your business is protected, we do not honor Broker of Record letters
          </span>
        </div>
      </div>
    );
  }
}

export default ImageBlock;
