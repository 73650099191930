let mode;

if (process.env.REACT_APP_MODE) {
  // mode = "local2";
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}

// mode = "local2"
console.log("env", process.env);

var localURL = "http://localhost:3000";

export var demoToolUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://c4nep7bpkd.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://7cv525yoqk.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://1h3juvjogh.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://6xv7upk8ja.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://cy6xjaz0vg.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://8ah6am0922.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";

if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "1.0.0";
