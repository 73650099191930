import React, { Component } from "react";
import GetYourQuote from "../subcompo/sections-home/GetYourQuote";
import Header from "../common/Header";
import ban_main from "../../images/ban_main.jpg";
import AboutSection from "../subcompo/sections-home/AboutSection";
import Footer from "../subcompo/sections-home/Footer";
import new_logo from "../../images/new-logo.png";
import logo_ipeo from "../../images/logo-iPEO.png";

class Home3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: false,
    };
    this.servicesRef = React.createRef();
    this.whoWeServeRef = React.createRef();
    this.aboutRef = React.createRef();
    this.videoRef = React.createRef();
  }

  render() {
    let { loadingData } = this.state;
    return (
      <div className="home3 align-items-center">
        <div className="d-flex header-container">
          <Header />
        </div>
        <div className="w-100">
          <div className="col-12 text-center">
            <p className="py-5">
              <img
                className="w-100"
                style={{ margin: "0 auto" }}
                src={ban_main}
              ></img>
            </p>
          </div>
          <div className="col">
            <h1 className="text-center mb-4">
              Co-employment solutions
              <br />
              Just a click away
            </h1>
            <GetYourQuote loadingData={loadingData} />
          </div>
        </div>
        <div className="w-100">
          <div className="col-12">
            <div className="our-partners">
              <div class="partners-title">
                <h3>Our Partners</h3>
              </div>
              <div className="partners-image">
                <a href="http://www.insurecomp.com" target="_blank">
                  <img
                    className="insurecomp-logo"
                    src={new_logo}
                    alt="insurecomp-logo"
                  />
                </a>
              </div>
              <div className="partners-image">
                <a href="http://www.ipeosolutions.com" target="_blank">
                  <img className="ipeo-logo" src={logo_ipeo} alt="ipeo-logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="about-section-container">
          <AboutSection
            servicesRef={this.servicesRef}
            whoWeServeRef={this.whoWeServeRef}
            aboutRef={this.aboutRef}
            videoRef={this.videoRef}
          />
        </div>
        <Footer
          servicesRef={this.servicesRef}
          whoWeServeRef={this.whoWeServeRef}
          aboutRef={this.aboutRef}
          videoRef={this.videoRef}
        />
      </div>
    );
  }
}

export default Home3;
