import { file } from 'jszip';
import React from 'react';
import Table from 'react-bootstrap/Table';

function TabTable({ result }) {
    console.log("Hello", result)
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Sr No.</th>
                    <th>Uploaded File Name</th>
                    <th>Status</th>
                    <th>Error File</th>
                </tr>
            </thead>
            <tbody>
                {result?.map((row, index) => {
                    let error_file
                    if (row.file_status === "Error")
                        error_file = <a href={row.download_error_file}>Click to download error file.</a>
                    else
                        error_file = "NA"
                    return (<tr>
                        <td>{index + 1}</td>
                        <td>{row.file_name}</td>
                        <td>{row.file_status}</td>
                        <td>{error_file}</td>
                    </tr>)
                })}
            </tbody>
        </Table>
    );
}

export default TabTable;