import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import LoadingOverlay from "react-loading-overlay";

const moment = require("moment");
const uuidv4 = require("uuid/v4");

export class UploadDashboardFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      tabName: this.props.tabName,
      domainName: this.props.domainName,
      firstUpload:
        this.props.dashboardFiles && this.props.dashboardFiles.length === 0
          ? true
          : false,
      messageID: this.props.messageID,
      instance: this.props.instance,
      fileUploaded: true,
    };
  }

  componentDidMount() {
    let { fileList } = JSON.parse(JSON.stringify(this.state));
    let agpsFiles = JSON.parse(sessionStorage.getItem("agpsFiles"));
    if (agpsFiles) {
      const key = Object.keys(agpsFiles);
      if (key.length >= 0) {
        Object.values(JSON.parse(sessionStorage.getItem("agpsFiles"))).forEach(
          (key) => {
            fileList[key.keyToFile] = "#empty";
          }
        );

        this.setState({ fileList });
      }
    }
  }

  updateFileList = (newFile) => {
    let { fileList } = this.state;

    let files = fileList;

    if (files[newFile.name] && files[newFile.name] === "#empty") {
      this.setState({
        errMsg: "File already exist",
        uploadMsg: undefined,
        fileUploaded: true,
      });
      return;
    }

    files[newFile.name] = newFile;

    fileList = files;

    this.setState({
      fileList,
      errMsg: undefined,
      uploadMsg: undefined,
    });
  };

  removeFile = (fileName) => {
    let { fileList } = this.state;
    let files;
    files = fileList;

    delete files[fileName];

    fileList = files;

    this.setState({ fileList });
  };

  uploadFile = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/uploadDashboardFilesData", postDataS3)
        .then((response) => {
          let signedURL = response.data;
          let options = {
            header: { "Content-Type": postDataS3.fileType },
          };
          axios
            .put(signedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve({ uploadResponse: "success" });
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject({ uploadResponse: "error" });
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject({ uploadResponse: "error" });
        });
    });
  };
  putExtensisExistingTable = (dataToSend, flag) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/putExtensisExistingTable", dataToSend)
        .then((res) => {
          if (flag === "first") {
            this.setState({
              uploadMsg: "files uploaded",
              errMsg: undefined,
            });
          } else if (flag === "second") {
            this.setState({
              histUploadMsg: "files uploaded",
              histErrMsg: undefined,
            });
          } else if (flag === "third") {
            this.setState({
              emodUploadMsg: "files uploaded",
              emodErrMsg: undefined,
            });
          }
          resolve();
        })
        .catch((error) => {
          console.log("error in API-putExtensisExistingTable", error);
          reject();
        });
    });
  };

  handleUpload = async () => {
    try {
      let {
        fileList,
        domainName,
        tabName,
        firstUpload,
        messageID,
        instance,
      } = this.state;

      let files;
      let uploadedFiles = [];
      // let isLoggedIn = await Auth.currentAuthenticatedUser();
      // let userEmail = isLoggedIn.attributes.email;
      let date = moment().format("x");

      files = fileList;
      this.setState({ uploadingInProgress: true });

      for (let currFileName in files) {
        if (currFileName && files[currFileName] !== "#empty") {
          let fileType = currFileName.split(".").reverse()[0];

          const postDataS3 = {
            fileType,
            key: `${domainName}/${instance}/${tabName}/${currFileName}`,
            tab: tabName,
          };

          let { uploadResponse } = await this.uploadFile(
            files[currFileName],
            postDataS3
          );

          await axios
            .post(
              awsUrl2 + `/api/updateSolveTrackingDataGeneric/fileDetails`,
              JSON.stringify({
                domain: domainName,
                keyToFile: `${domainName}/${instance}/${tabName}/${currFileName}`,
                tab: tabName,
              })
            )
            .then((res) => {
              uploadedFiles.push(postDataS3.key);
              this.props.dashboardFiles.push(postDataS3.key);
              this.props.updateDomainMessageidFiles(
                domainName,
                "dashboardFiles",
                this.props.dashboardFiles
              );
            });
          files[currFileName] = "#empty";
          this.setState({ files });
        }
      }
      //sendMail with attachment and sending the mail  with attachment
      if (uploadedFiles.length > 0) {
        const company_name = this.props.companyName;
        // let uuid = uuidv4();
        let uuid = moment().format("YYYY-MM-DDH:MM:SSa"); // creating unique file_name
        uuid = uuid + "|" + company_name;

        // await axios
        //   .post(
        //     awsUrl2 + `/api/UploadZip`,
        //     JSON.stringify({
        //       id: `${domainName}/${instance}/${tabName}/zip/${uuid}.zip`,
        //       fileList: uploadedFiles,
        //     })
        //   )
        //   .then(async (res) => {
        //     // let sendMailZipBody = {
        //     //   id: `${domainName}/${instance}/${tabName}/zip/${uuid}.zip`,
        //     //   // emailTo: [
        //     //   //   "akshay@mobiux.in",
        //     //   // ],
        //     //   // emailTo: ["info@affinitypeo.com"],
        //     //   emailTo: ["msageer@insurepro20.com"],

        //     //   companyName: this.props.companyName,
        //     // };
        //     if (!firstUpload) {
        //       sendMailZipBody.re = "true";
        //     }
        //     if (messageID) {
        //       sendMailZipBody.messageID = messageID;
        //     }
        //     await axios
        //       .post(
        //         awsUrl2 + `/api/sendMailWithZip`,
        //         JSON.stringify(sendMailZipBody)
        //       )
        //       .then(async (res) => {
        //         if (!messageID && res.data.includes("amazon")) {
        //           await axios
        //             .post(
        //               awsUrl2 + `/api/updateSolveTrackingDataGeneric/generic`,
        //               JSON.stringify({
        //                 domain: domainName,
        //                 messageID: res.data,
        //               })
        //             )
        //             .then((res1) => {
        //               messageID = res.data;
        //               this.props.updateDomainMessageidFiles(
        //                 domainName,
        //                 "messageID",
        //                 messageID
        //               );
        //             });
        //         }
        //         console.log("Zip created and mail sent");
        //         firstUpload = false;
        //       });
        //   });
      }

      this.setState({
        firstUpload,
        uploadingInProgress: false,
        fileList: files,
        messageID,
        fileUploaded: false,
      });
    } catch (error) {
      console.log("error: ", error);
      this.setState({
        uploadingInProgress: false,
        uploadMsg: undefined,
        errMsg: "error in uploading",
      });
    }
  };

  // calling the api to take the post data to s3 bucket of preuwstateone
  agpsUploadFileHandler = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/agpsuploadDashboardFilesData", postDataS3)
        .then((response) => {
          let signedURL = response.data;
          let options = {
            header: { "Content-Type": postDataS3.fileType },
          };
          console.log(signedURL, fileData, options, "sageer");
          axios
            .put(signedURL, fileData, options)
            .then((result) => {
              resolve({ uploadResponse: "success" });
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject({ uploadResponse: "error" });
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject({ uploadResponse: "error" });
        });
    });
  };

  // if campaing tab means we  will update the details in dynamodb of campaing tab
  updateFourthUserCampaignTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 + "/api/agpsuploadDashboardFilesData/updateFileDetails",
          dataToSend
        )
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-preUwFlow-file_update", error);
          reject();
        });
    });
  };
  // if engaged tab means we  will update the details in dynamodb of engage tab
  updateFourthUserTrackingTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 +
            "/api/agpsuploadDashboardFilesData/updateTrackingFileDetails",
          dataToSend
        )
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-preUwFlow-file_update", error);
          reject();
        });
    });
  };

  fileuploadHandler = async () => {
    let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag");
    let domain = JSON.parse(sessionStorage.getItem("domain"));
    try {
      let { fileList } = this.state;
      this.setState({ uploadingInProgress: true });

      if (!domain) return;

      const folder1 = `agps/${domain}`;
      let dataToSendPromise = [];

      let promiseList = [],
        fileListToStore = [];

      for (let fileName in fileList) {
        let fileType = fileName.split(".").reverse()[0];
        const folder2 = "agpsUpload";

        fileListToStore.push({
          key: `${folder1}/${folder2}/${fileName}`,
          fileType,
          keyToFile: fileName,
        });

        const postDataS3 = {
          key: `${folder1}/${folder2}/${fileName}`,
          fileType,
          keyToFile: fileName,
        };
        if (fileList[fileName] !== "#empty")
          promiseList.push(
            this.agpsUploadFileHandler(fileList[fileName], postDataS3)
          );
      }

      sessionStorage.setItem("agpsFiles", JSON.stringify(fileListToStore));
      Promise.all(promiseList)
        .then((res) => {
          if (!preUwEngagedFlag) {
            dataToSendPromise.push(
              this.updateFourthUserCampaignTable({
                domain: domain,
                agpsFileToUpload: fileListToStore,
              })
            );
          } else {
            dataToSendPromise.push(
              this.updateFourthUserTrackingTable({
                domain: domain,
                agpsFileToUpload: fileListToStore,
              })
            );
          }

          Promise.all(dataToSendPromise)
            .then((res) => {
              for (let fileName in fileList) {
                fileList[fileName] = "#empty";
              }
              this.setState({
                uploadingInProgress: false,
                docUploaded: true,
                uploadMsg: "files uploaded",
                errMsg: undefined,
                fileList,
              });
            })
            .catch((error) => console.log("error in API", error));
        })
        .catch((error) => {
          console.log("error: ", error);
          this.setState({
            errMsg: "Error",
            uploadMsg: undefined,
            uploadingInProgress: false,
          });
        });
    } catch (error) {
      console.log("error: ", error);
      this.setState({
        errMsg: "Error",
        uploadMsg: undefined,
        uploadingInProgress: false,
      });
    }
  };

  render() {
    let {
      fileList,
      uploadingInProgress,
      uploadMsg,
      errMsg,
      fileUploaded,
    } = this.state;

    // if any apgs uplaoded files are there we will update.

    return (
      <>
        <div className="upload-heading my-3 mx-auto text-center">
          <h3 className="m-0">Upload Files</h3>
        </div>
        <div className="dropZonesContainer d-flex justify-content-center">
          <div className="d-flex" style={{ width: "500px" }}>
            <LoadingOverlay
              active={uploadingInProgress}
              spinner
              text="Uploading your files..."
            >
              <CustomDropZone
                fileList={fileList}
                updateFileList={this.updateFileList}
                removeFile={this.removeFile}
                uploadMsg={uploadMsg}
                fileUploaded={fileUploaded}
              />
              <DropButton
                uploadMsg={uploadMsg}
                errMsg={errMsg}
                handleUpload={
                  this.props.preuwFile
                    ? this.fileuploadHandler
                    : this.handleUpload
                }
                uploadingInProgress={uploadingInProgress}
              />
            </LoadingOverlay>
          </div>
        </div>
      </>
    );
  }
}

export const DropButton = (props) => {
  return (
    <div className="d-flex flex-column">
      <p
        className={
          "text-center text-capitalize " +
          (props.uploadMsg ? "text-success" : "text-danger")
        }
      >
        {props.uploadMsg || props.errMsg}
      </p>
      <button
        className="btn btn-color btn-sm btn-color-shadow upload-docs"
        style={{ width: "min-content", alignSelf: "center" }}
        onClick={() => props.handleUpload(props.flag)}
        disabled={props.uploadingInProgress}
      >
        Upload
      </button>
    </div>
  );
};
