import React, { Component } from "react";
import { signIn } from "../../../utils/log_in";
import {
  clearErr,
  validEmail,
  validPassword,
  changeText,
} from "../../../utils/common";

class FormLogin extends Component {
  render() {
    return (
      <div id="lgin" className="tab-pane fade-in active">
        <div id="formLogin">
          <p className="formTitle text-center">LOGIN</p>
          <hr />
          <form onSubmit={(e) => signIn(e, this.props.getLoggedInUser)}>
            <div>
              <span className="errMsg" />
              <input
                autoComplete="off"
                id="userId"
                type="email"
                placeholder="Enter Email Address"
                onBlur={validEmail}
                onChange={clearErr}
              />
            </div>
            <div>
              <span className="errMsg" />
              <input
                autoComplete="off"
                id="userPassword"
                type="password"
                placeholder="Enter Password"
                onBlur={validPassword}
                onChange={clearErr}
              />
            </div>
            <input
              autoComplete="off"
              id="doRemember"
              type="checkbox"
              defaultChecked
            />{" "}
            <span className="inptSideText">Remember Me</span>
            <span className="errMsg" />
            <button id="loginSubmit" className="btnFormFinal">
              SUBMIT
            </button>
          </form>
          <div className="formBottomLinks d-flex">
            <a id="link" data-toggle="tab" href="#chngpwd" onClick={changeText}>
              Change Password
            </a>{" "}
            <a data-toggle="tab" href="#fgtpwd" aria-expanded="true">
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default FormLogin;
