import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntoVideo from "../common/IntroVideo";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import GetYourQuote from "../subcompo/sections-home/GetYourQuote";
import { div } from "react-dom-factories";
let affinityTechLogo = require("../../images/AffinityTech-InsureComp.png");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }
  componentDidMount() {
    let userRole = ["user"];
    var curre = Auth.currentSession()
      .then((res) => {
        // console.log("Current Authenticated Response User: ", res);
        userRole = res.accessToken.payload["cognito:groups"];
        let userName = res.idToken.payload.email.split("@")[0];
        this.setState({
          userRole: userRole,
          userName: userName,
          isLoggedIn: true,
        });
        // console.log("Role: ", this.state.userRole, this.state.userName);
        if (userName !== "" && userRole.length > 0) {
          // This is a user who has logged in.

          sessionStorage.setItem("isLoggedIn", true);
        }
      })
      .catch((err) => {
        if (err === "No current user") {
          console.log("Current Authenticated User: ", err);
        }
      });
    console.log("Current Authenticated User: ", curre);
  }
  handleLogout = () => {
    logout();
    this.setState({ isLoggedIn: false });
  };

  startFreshstartFresh() {
    sessionStorage.clear();
    window.location.href = "/quote";
    // window.location.reload();
  }
  stagepageLandHandler() {
    sessionStorage.clear();
    window.location.href = "/StageOne";
  }

  render() {
    let queryString = window.location.search;
    let pathName = window.location.pathname;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let { isLoggedIn, userRole, userName } = this.state;
    // console.log(isLoggedIn,userRole,userName)
    let responsiveheading;
    return (
      <div
        className={
          "header container-fluid d-flex flex-row justify-content-between " +
          " " +
          (pathName === "/" ? " " : "responsiveheading")
        }
      >
        <div className="logos-container   pb-0">
          <a href="/">
            <div className="wcn-logo-container">
              <img
                src={affinityTechLogo}
                alt="affinity-group logo"
                className="img-fluid"
              />
            </div>
          </a>
        </div>
        <div className="d-flex justify-content-end centerresponsive align-items-center p-2">
          {pathName.toLowerCase() === "/" && (
            <Link to="/schedule-demo">
              <button className="btn w-100 demo mr-3">GET DEMO</button>
            </Link>
          )}
          <div className="login-container">
            {/* {isLoggedIn &&
              userRole &&
              userRole.includes(process.env.REACT_APP_ADMIN_GROUP) && (
                <Link to="/dashboard">Dashboard</Link>
              )}
            {isLoggedIn &&
              userRole &&
              userRole.includes(process.env.REACT_APP_CARRIER_GROUP) && (
                <Link to="/PanelUW">UW Portal</Link>
              )}
            {isLoggedIn &&
              userRole &&
              userRole.includes(process.env.REACT_APP_AGENT_GROUP) && (
                <Link to="/salesdashboard">Dashboard</Link>
              )} */}
            {!isLoggedIn &&
            !window.location.pathname.toLowerCase().includes("login") ? (
              <a
              onClick={() => {
                window.location.href = "/Login";
              }}
              className="login-logout-link"
            >
              Login
            </a>
            ) : (!window.location.pathname.toLowerCase().includes("login") && !window.location.pathname.toLowerCase().includes("admindashboard") && !window.location.pathname.toLowerCase().includes("stageone")) ? (
              <div>
              <span
                className="dropdown-toggle text-capitalize fw-bolder login-logout-link"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {userName}
              </span>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item text-dark"
                  onClick={() => {
                    window.location.href = (userRole &&
                      userRole.includes(process.env.REACT_APP_AGPSADMIN_GROUP))
                      ? "/AdminDashboard"
                      : (userRole &&
                      userRole.includes(process.env.REACT_APP_AGPSAGENT_GROUP))
                      ? "/AdminDashboard"
                      :  "/";
                  }}
                >
                  Dashboard
                </a>
                <a
                  className="dropdown-item text-dark"
                  onClick={() => {
                    this.handleLogout();
                  }}
                >
                  Logout
                </a>
              </div>
            </div>
            ) : ""}
          </div>
          {/* {pathName.toLowerCase().includes("quote") ? (
            <div className="d-flex align-items-center"> */}
          {/* <div className="mr-5">Get Quote</div> */}
          {/* <div
                style={{ cursor: "pointer" }}
                className="mr-5"
                onClick={this.startFresh}
              >
                Start New Quote
              </div>
              <div className="mr-5">
                <a href="/program-info">Program Info</a>
              </div>
              <div
                className="mr-5"
                style={{ cursor: "pointer" }}
                onClick={this.stagepageLandHandler}
              >
                Stage one UW
              </div>

              <div>Hi User</div>
            </div>
          ) : !(
              pathName.toLowerCase().includes("paneluw") ||
              pathName.toLowerCase().includes("validate") ||
              pathName.toLowerCase().includes("newdomain")
            ) ? (
            <div className="d-flex align-items-center ">
              <GetYourQuote />
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    );
  }
}

export default Header;
