import { event } from "jquery";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
const ContactDetails = ({
  specificEmailKey,
  contactDetails,
  updateContactDetails,
  deleteContactDetailsHandler,
  isdomain,
  emailValidatecheck,
}) => {
  let contactdetailslength = Object.keys(contactDetails).length;
  const [phoneError, setPhoneError] = useState(false);
  const validatePhoneNumber = (phoneNumber) => {
    if (
      phoneNumber &&
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumber)
    ) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };
  return (
    <div className="row no-gutters">
      <div id="EmailContactName" className=" col-lg-3 col-md-6">
        <Form.Group className="ml-3">
          <label htmlFor="preuw_EmailContactName" className={"mandatory"}>
            <b className="font-family-montserrat-semi-bold">Contact Name</b>
          </label>
          <input
            disabled={contactDetails?.companyProfile?.disable_check}
            id="preuw_EmailContactName"
            type="text"
            value={
              contactDetails?.companyProfile?.contact_name &&
              contactDetails?.companyProfile?.contact_name?.value
                ? contactDetails?.companyProfile?.contact_name?.value
                : ""
            }
            style={
              contactDetails?.companyProfile?.contact_name &&
              contactDetails?.companyProfile?.contact_name?.error
                ? { borderColor: "red" }
                : {}
            }
            onChange={(event) => {
              updateContactDetails(event, specificEmailKey, "contact_name");
            }}
            onKeyDown={(event) => {
              if (event.code === "Space") event.preventDefault();
            }}
            placeholder="Contact Name"
            autoComplete="off"
          />
          <p className="errMsg" />
        </Form.Group>
      </div>
      <div id="emailEmailId" className=" col-lg-3 col-md-12">
        <Form.Group>
          <label
            htmlFor={"emailEmailId"}
            className={"mandatory"}
            title="Email Id"
          >
            <b className="font-family-montserrat-semi-bold">Email Id</b>
          </label>
          <input
            disabled={contactDetails?.companyProfile?.disable_check}
            name="emailEmailId"
            id={"preuw_emailEmailId"}
            type="email"
            value={
              contactDetails?.companyProfile?.emailId &&
              contactDetails?.companyProfile?.emailId.value
                ? contactDetails?.companyProfile?.emailId.value.toLowerCase()
                : ""
            }
            style={
              contactDetails?.companyProfile?.emailId &&
              contactDetails?.companyProfile?.emailId.error
                ? { borderColor: "red" }
                : {}
            }
            onChange={(event) => {
              updateContactDetails(event, specificEmailKey, "emailId");
            }}
            placeholder="Email "
            autoComplete="off"
            onBlur={(event) => {
              emailValidatecheck(
                event.target.value.toLowerCase(),
                specificEmailKey,
                "emailId"
              );
            }}
          />
          {contactDetails?.companyProfile?.contactError?.value && (
            <p style={{ color: "red" }}>Email already exist in DB.</p>
          )}
          {contactDetails?.companyProfile?.validemailError?.value && (
            <p style={{ color: "red" }}>Invalid email.</p>
          )}
          {contactDetails?.companyProfile?.duplicateError?.value && (
            <p style={{ color: "red" }}>Duplicate email.</p>
          )}
          <p className="errMsg " />
        </Form.Group>
      </div>
      <div id="jobDescription" className=" col-lg-3 col-md-12">
        <Form.Group>
          <label
            htmlFor={"jobDescription"}
            className={`mandatory`}
            title="Job Description"
          >
            <b className="font-family-montserrat-semi-bold">Job Description</b>
          </label>
          <input
            disabled={contactDetails?.companyProfile?.disable_check}
            id={"preuw_jobDescription"}
            name="jobDescription"
            type="text"
            value={
              contactDetails?.companyProfile?.job_title &&
              contactDetails?.companyProfile?.job_title?.value
                ? contactDetails?.companyProfile?.job_title.value
                : ""
            }
            style={
              contactDetails?.companyProfile?.job_title &&
              contactDetails?.companyProfile?.job_title.error
                ? { borderColor: "red" }
                : {}
            }
            onChange={(event) => {
              updateContactDetails(event, specificEmailKey, "job_title");
            }}
            placeholder="Job Description"
            autoComplete="off"
          />
          <p className="errMsg" />
        </Form.Group>
      </div>
      <div id="Pre_PhoneNumber" className=" col-lg-2 col-md-12">
        <Form.Group>
          <label className={` mandatory`} title="Phone Number">
            <b className="font-family-montserrat-semi-bold">Phone Number</b>
          </label>
          <NumberFormat
            disabled={contactDetails?.companyProfile?.disable_check}
            id={"preuw_PhoneNumber"}
            name="Phone Number"
            type="text"
            format="(###) ###-####"
            placeholder="(000) 000-0000"
            value={
              contactDetails?.companyProfile?.phoneNumber &&
              contactDetails?.companyProfile?.phoneNumber?.value
                ? contactDetails?.companyProfile?.phoneNumber?.value
                : ""
            }
            style={
              contactDetails?.companyProfile?.phoneNumber &&
              contactDetails?.companyProfile?.phoneNumber?.error
                ? { borderColor: "red" }
                : {}
            }
            onChange={(event) => {
              updateContactDetails(event, specificEmailKey, "phoneNumber");
            }}
            onBlur={(event) => {
              validatePhoneNumber(event.target.value);
            }}
            autoComplete="off"
          />
          {phoneError && <p style={{ color: "red" }}>Invalid phone number. </p>}
          <p className="errMsg" />
        </Form.Group>
      </div>
      {}
      <div id="Pre_PhoneNumber" className=" col-lg-1 col-md-12">
        <button
          type="button"
          // disabled={isdomain}
          className="rmvLoc btnInvisible transpButton mt-3 btn "
          onClick={() => {
            deleteContactDetailsHandler(specificEmailKey);
          }}
        >
          <img src={require("../../../images/delete4.png")} alt="" />
        </button>
      </div>
    </div>
  );
};

export default ContactDetails;
