import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

class AgentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected } = this.state;
    const tabsMap = {
      tab1: "Agent Dashboard",
    };

    return (
      <div className="dashboard-wcn">
        <div className="d-flex" style={{ borderBottomStyle: "groove" }}>
          <Header />
          <div
            onClick={logout}
            className="d-flex align-items-center logout-div"
          >
            Logout
          </div>
        </div>
        <div className="tabs p-4">
          <div className="d-flex justify-content-center mb-3 text-center">
            <h2>
              <b>
                {"Prospect Dashboard"}
                <pre>
                  <a href="/Quote" target="_blank">
                    Generate New Quote
                  </a>
                </pre>
              </b>
            </h2>
          </div>
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px" }}
          >
            {Object.keys(tabsMap).map((tab) => (
              <Nav.Item>
                <Nav.Link eventKey={tab} onClick={() => this.setSelected(tab)}>
                  {tabsMap[tab]}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab}>
              {<SalesDashboard tabName={tab} selected={selected} />}
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

class SalesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = () => {
    this.updateDisplay();
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  setTableData = () => {
    let { rowDataList, isLoading } = JSON.parse(JSON.stringify(this.state));
    let { tabName } = this.props;

    let columns = {
      tab1: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          defaultSort: "desc",
          render: (rowData) => {
            let visitDate = moment(Number(rowData.visitDate))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <div>
                  <Link
                    to={"/Quote" + "?" + "agent=" + rowData.userEmail}
                    target="_blank"
                  >
                    {this.camelCase(rowData.companyName)}
                  </Link>
                </div>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Contact Name",
          field: "contactName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.contactName)}</Clamp>
          ),
        },
        {
          title: "Phone Number",
          field: "phoneNumber",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "110px",
            width: "110px",
            maxWidth: "110px",
          },
        },
        {
          title: "Indication Cost",
          field: "indicationCost",
          render: (rowData) => numeral(rowData.indicationCost).format("$0,0"),
          cellStyle: {
            padding: "6px 0",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          render: (rowData) =>
            moment(Number(rowData.effectiveDate)).format("M/D/YYYY"),
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "Email",
          field: "userEmail",
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 15px 6px 0px",
            minWidth: "160px",
            width: "160px",
            maxWidth: "160px",
          },
          headerStyle: {
            padding: "6px 15px 6px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.userEmail.toLowerCase()}</Clamp>
          ),
        },
        {
          title: "Status",
          field: "step",
          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };

            if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Docs Pending") {
              style.backgroundColor = "#eb6631a7";
            } else if (rowData.step === "Submitted") {
              style.backgroundColor = "#ffd737ac";
            } else {
              style.backgroundColor = "#FFF";
            }

            return style;
          },
        },
      ],
    };

    return (
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        data={rowDataList}
        columns={columns[tabName]}
        options={{
          paginationPosition: "both",
          padding: "dense",
          showTitle: false,
          thirdSortClick: false,
          pageSize: 25,
          pageSizeOptions: [25, 50, 75, 100],
          headerStyle: {
            fontSize: "12px",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: "6px 15px 6px 10px",
          },
          rowStyle: {
            fontSize: "12px",
          },
          searchFieldStyle: {
            fontSize: "12px",
          },
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
              }
              labelDisplayedRows={(row) => (
                <div style={{ fontSize: 12 }}>
                  {props.labelDisplayedRows(row)}
                </div>
              )}
              SelectProps={{
                style: {
                  fontSize: 12,
                },
              }}
            />
          ),
        }}
      />
    );
  };

  updateDisplay = async () => {
    let sessionData = await Auth.currentAuthenticatedUser();
    if (sessionData) {
      axios
        .get(
          awsUrl2 + "/api/getAgentDashboardData/" + sessionData.attributes.email
        )
        .then((res) => {
          let userDataList = [];
          let rowData;
          let rowDataList = [];
          let step;

          userDataList = res.data;

          for (let userDataRow in userDataList) {
            let userData = userDataList[userDataRow];

            if (userData.quote_status === "reject") {
              step = "Rejected";
            } else if (userData.quote_status === "proceed_uw") {
              step = "Approved";
            } else if (
              userData.doc_status !== "not_uploaded" ||
              userData.nolossGenerated
            ) {
              step = "Submitted";
            } else if (
              userData.doc_status === "not_uploaded" ||
              !userData.doc_status
            ) {
              step = "Docs Pending";
            } else {
              step = "-";
            }

            rowData = {
              visitDate: userData.date,
              userEmail: userData.user_email_id,
              uuid: userData.uuid,
              phoneNumber: userData.phone_number,
              effectiveDate: moment(userData.effective_date).format("x"),
              contactName: userData.contact_person,
              companyName: userData.company_name,
              domain: userData.domain,
              quote_status: userData.quote_status,
              doc_status: userData.doc_status,
              step,
            };

            let total = 0,
              childrenLoc = userData.prospect_addresses;
            for (let key in childrenLoc) {
              let classCodes = childrenLoc[key].classCodes;
              for (let indx in classCodes) {
                let classCodesObj = classCodes[indx];
                total += Number(
                  classCodesObj.payroll.replace(/[^0-9.-]+/g, "")
                );
              }
            }
            rowData.indicationCost = total;
            rowDataList.push(rowData);
          }

          this.setState({ rowDataList });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    let { selected, tabName } = this.props;
    return (
      <div className="Wcn-Dashboard-Sales">
        <div>{this.setTableData()}</div>
      </div>
    );
  }
}

export default AgentDashboard;
