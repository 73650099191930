import React, { Component } from "react";
import { Link } from "react-router-dom";

class GetYourQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { loadingData } = this.props;
    return (
      <div className="get-quote d-flex align-items-center justify-content-center">
        <Link to="/Quote">
          <button className="btn btngetquote btn-color" disabled={loadingData}>
            GET YOUR QUOTE NOW
          </button>
        </Link>
      </div>
    );
  }
}

export default GetYourQuote;
