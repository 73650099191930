import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

class CustomDropZone extends React.Component {
  render() {
    let {
      fileList,
      removeFile,
      updateFileList,
      name,
      headingMap,
      uploadMsg,
    } = this.props;
    return (
      <div className="customDropZone">
        <h6>{headingMap[name]}</h6>
        <DropZone
          listLen={Object.keys(fileList[name]).length}
          updateFileList={updateFileList}
          name={name}
        />
        <ul className="list-group list-group-flush p-4">
          {Object.keys(fileList[name]).map((val) => (
            <li
              className="list-group-item d-flex justify-content-between"
              key={val}
            >
              <span>{val}</span>
              {fileList[name][val] !== "#empty" &&
                uploadMsg !== "files uploaded" && (
                  <span
                    className="fw-bold"
                    onClick={() => removeFile(name, val)}
                  >
                    {"X"}
                  </span>
                )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList, listLen, name } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(name, file);
    });
  }, listLen);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className="dropZoneText">Drag &amp; drop or browse to select files</p>
    </div>
  );
};

export default CustomDropZone;
