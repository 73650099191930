import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";

class CustomDropZone extends React.Component {
  render() {
    let {
      fileList,
      updateFileList,
      removeFile,
      uploadMsg,
      fileUploaded,
    } = this.props;

    let listToShow = [];

    for (let fileName in fileList) {
      listToShow.push(
        <li
          className="list-group-item d-flex justify-content-between"
          key={fileName}
        >
          <span>{fileName}</span>
          {fileList[fileName] !== "#empty" && uploadMsg !== "files uploaded" && (
            <span className="fw-bold" onClick={() => removeFile(fileName)}>
              {"X"}
            </span>
          )}
        </li>
      );
    }

    return (
      <div className="customDropZone" style={{ width: "500px" }}>
        {/* <div className="d-flex justify-content-between align-items-center">
                    <h6 className="m-0">{heading}</h6>
                    <Link
                        to={"/" + downloadFileName}
                        target="_blank"
                        className="d-flex align-items-center"
                        download
                    >
                        <span>Download reference file</span>
                        <div style={{ fontWeight: "900", fontSize: "24px" }}>⬇</div>
                    </Link>
                </div> */}
        <DropZone updateFileList={updateFileList} />
        {
          <ul className="list-group list-group-flush">
            {fileUploaded ? (
              listToShow
            ) : (
              <h6 className="d-flex justify-content-center">
                Files Uploaded Successfully
              </h6>
            )}
          </ul>
        }
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(file);
    });
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className="dropZoneText">Drag &amp; drop or browse to select file</p>
    </div>
  );
};

export default CustomDropZone;
