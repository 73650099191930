import React, { Component } from "react";
import DescriptionCode from "../../subcompo/DescriptionCode";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

const axios = require("axios");
class Newcode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.classCodesInfo) !==
      JSON.stringify(this.state.classCodesInfo)
    ) {
      this.setState({ classCodesInfo: this.props.classCodesInfo });
    }
  }

  componentDidMount() {
    const {
      classCodesInfo,
      addressBlockNo,
      addressChildNo,
      handleMinMax,
    } = this.props;
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    for (let index in classCodesInfo) {
      if (currProspect && classCodesInfo[index].classCodeDescription) {
        let childrenLoc = currProspect.childrenLoc;
        if (
          Object.keys(classCodesInfo[index].classCodeDescription).length === 0
        ) {
          classCodesInfo[index].classCodeDescription =
            childrenLoc[addressBlockNo].classCodesInfo[
              index
            ].classCodeDescription;
        }
      }
    }
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ classCodesInfo: classCodesInfo });
  }

  render() {
    const {
      addressChildNo,
      addressBlockNo,
      state,
      removeClassCode,
      updateClassCodesInfo,
      handleMinMax,
      updateLocationError,
    } = this.props;

    const { classCodesInfo } = this.state;

    let codeCount = 1;
    try {
      codeCount = Object.keys(classCodesInfo).length;
    } catch (e) {}

    return (
      <div className="row calcPremium grey">
        <DescriptionCode
          addressBlockNo={addressBlockNo}
          addressChildNo={addressChildNo}
          state={state.toLowerCase()}
          handleMinMax={handleMinMax}
          classCodesInfo={classCodesInfo}
          updateLocationError={updateLocationError}
          updateClassCodesInfo={updateClassCodesInfo}
        />
        <span
          id={"invalidCode" + addressBlockNo + addressChildNo}
          style={{ color: "red" }}
        ></span>
        <div className="col-lg-5">
          <div className="row">
            <div className="col-lg-4 text-left grey">
              <Form.Group>
                <label
                  for={"classCodes" + addressBlockNo + addressChildNo + "1"}
                  title="Number of full time employees"
                >
                  # Full-Time*
                </label>
                <NumberFormat
                  title="Number of full time employees"
                  id={"classCodes" + addressBlockNo + addressChildNo + "1"}
                  isNumericString={true}
                  onKeyUp={(event) => {
                    updateClassCodesInfo(
                      addressBlockNo,
                      addressChildNo,
                      "ft",
                      event.target.value
                    );
                  }}
                  style={{
                    borderColor:
                      classCodesInfo &&
                      classCodesInfo[addressChildNo] &&
                      classCodesInfo[addressChildNo].ft &&
                      classCodesInfo[addressChildNo].ft.error
                        ? "red"
                        : "",
                  }}
                  value={
                    classCodesInfo && classCodesInfo[addressChildNo].ft
                      ? classCodesInfo[addressChildNo].ft.value
                      : ""
                  }
                  disabled={false}
                  onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  name="FT"
                  className="text-center"
                  format="#####"
                  placeholder="# FT"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 text-left grey">
              <Form.Group>
                <label
                  for={"classCodes" + addressBlockNo + addressChildNo + "2"}
                  title="Number of part time employees"
                >
                  # Part-Time*
                </label>
                <NumberFormat
                  title="Number of part time employees"
                  id={"classCodes" + addressBlockNo + addressChildNo + "2"}
                  onKeyUp={(event) => {
                    updateClassCodesInfo(
                      addressBlockNo,
                      addressChildNo,
                      "pt",
                      event.target.value
                    );
                  }}
                  style={{
                    borderColor:
                      classCodesInfo &&
                      classCodesInfo[addressChildNo] &&
                      classCodesInfo[addressChildNo].pt &&
                      classCodesInfo[addressChildNo].pt.error
                        ? "red"
                        : "",
                  }}
                  value={
                    classCodesInfo && classCodesInfo[addressChildNo].pt
                      ? classCodesInfo[addressChildNo].pt.value
                      : ""
                  }
                  disabled={false}
                  onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  name="PT"
                  className="text-center"
                  format="#####"
                  placeholder="# PT"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
            <div className="col-lg-4 text-left grey pr-0">
              <Form.Group>
                <label
                  for={"classCodes" + addressBlockNo + addressChildNo + "3"}
                  title="Annual Payroll"
                >
                  Annual Payroll *
                </label>
                <NumberFormat
                  title={
                    classCodesInfo && classCodesInfo[addressChildNo].payroll
                      ? classCodesInfo[addressChildNo].payroll.value
                      : "Annual Payroll"
                  }
                  id={"classCodes" + addressBlockNo + addressChildNo + "3"}
                  onKeyUp={(event) => {
                    updateClassCodesInfo(
                      addressBlockNo,
                      addressChildNo,
                      "payroll",
                      event.target.value
                    );
                  }}
                  style={{
                    borderColor:
                      classCodesInfo &&
                      classCodesInfo[addressChildNo] &&
                      classCodesInfo[addressChildNo].payroll &&
                      classCodesInfo[addressChildNo].payroll.error
                        ? "red"
                        : "",
                  }}
                  value={
                    classCodesInfo && classCodesInfo[addressChildNo].payroll
                      ? classCodesInfo[addressChildNo].payroll.value
                      : ""
                  }
                  disabled={false}
                  name="payroll"
                  onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
                  placeholder="Annual Payroll ($)"
                  thousandSeparator={true}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  autoComplete="off"
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="col-lg-1 text-center code-delete">
          <br />
          {codeCount > 1 && (
            <button
              id={"delete-class-code" + addressBlockNo + addressChildNo + "4"}
              type="button"
              className="rmvLoc btnInvisible transpButton"
              data-address={addressBlockNo}
              data-classno={addressChildNo}
            >
              <img
                onClick={(event) => {
                  removeClassCode(addressBlockNo, addressChildNo);
                }}
                src={require("../../../images/delete4.png")}
                data-address={addressBlockNo}
                data-classno={addressChildNo}
                alt=""
              />
            </button>
          )}
        </div>
        {/* {
          <div className="col-sm-4 relDiv rangeSlider">
            <div className="row justify-content-center">
              <div id="minVal" className="premVal">
                <span className="minValueCC">{min}</span>
              </div>
              <div className="barBG"></div>
              <div id="maxVal" className="premVal">
                <span className="manValueCC">{max}</span>
              </div>
            </div>
          </div>
        } */}
      </div>
    );
  }
}
export default Newcode;
