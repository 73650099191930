import React, { Component } from "react";
import { clearErr } from "../../utils/common";
var stateCity = require("../../utils/state_city.json");
let stateCityExtra = require("../../utils/state_city_extra.json");

class StateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateCollection: stateCity,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      resetClassCodeDescriptionData,
      addressBlockNo,
      extraStates,
    } = this.props;
    let stateCollection;
    if (this.state.locationInfo !== this.props.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
    if (this.props.state !== this.state.state) {
      this.setState({
        state: this.props.state,
      });
      //resetClassCodeDescriptionData(addressBlockNo);
    }
    if (JSON.stringify(extraStates) !== JSON.stringify(prevProps.extraStates)) {
      if (extraStates && extraStates.value === "Yes") {
        stateCollection = [...stateCity, ...stateCityExtra];
      } else {
        stateCollection = stateCity;
      }
      this.setState({ stateCollection });
    }
  }

  render() {
    const { updateLocationInfo, resetClassCodeDescriptionData } = this.props;
    const { locationInfo, stateCollection } = this.state;
    const number = locationInfo ? locationInfo.number : null;
    const stateList = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCollection) {
      if (locationInfo && item.Code === locationInfo.state.value) {
        stateList.push(
          <option key={item.Code} value={item.Code} selected>
            {item.Code}
          </option>
        );
      } else {
        stateList.push(
          <option key={item.Code} value={item.Code}>
            {item.Code}
          </option>
        );
      }
    }
    return (
      <select
        name="stateCodes"
        onChange={(event) => {
          updateLocationInfo(event.target.value, number, "state");
          resetClassCodeDescriptionData(number);
        }}
        style={
          locationInfo && locationInfo.state.error ? { borderColor: "red" } : {}
        }
      >
        {stateList}
      </select>
    );
  }
}
export default StateList;
