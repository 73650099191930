import React, { Component } from "react";
import IntroVideo from "../../common/IntroVideo";
import temp_image from "../../../images/black-card-2.png";
import moneyImage from "../../../images/money-white.png";
import houseImage from "../../../images/house-white.png";
import bagImage from "../../../images/bag-white.png";
import groupImage from "../../../images/group-white.png";
import handshakeImage from "../../../images/handshake_white.png";
import hurtImage from "../../../images/hurt-white.png";
import taxImage from "../../../images/tax-white.png";
import listImage from "../../../images/list-white.png";
import new_logo from "../../../images/new-logo.png";
import logo_ipeo from "../../../images/logo-iPEO.png";

class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  render() {
    const { servicesRef, whoWeServeRef, aboutRef, videoRef } = this.props;
    const { screenWidth } = this.state;

    let videoHeight = 450;
    if (screenWidth < 450) {
      videoHeight = 200;
    } else if (screenWidth < 550) {
      videoHeight = 250;
    } else if (screenWidth < 650) {
      videoHeight = 300;
    } else if (screenWidth < 800) {
      videoHeight = 350;
    } else if (screenWidth < 1000) {
      videoHeight = 400;
    }
    return (
      <div className="about-section">
        <div className="about-summary-container" ref={aboutRef}>
          <h2>About Affinity Group</h2>
          <p className="about-affinity-text text-justify">
            Affinity Group PEO Solutions is a JV tech platform developed by
            InsureComp &amp; exclusively sold by iPEO Solutions to disrupt and
            transform the procurement of PEO services, such as Workers' Comp via
            preset algorithms and AI. Now you can get your co employment quote
            instantly.
          </p>
        </div>
        <div className="video d-flex justify-content-center" ref={videoRef}>
          <IntroVideo width={"85%"} height={videoHeight} />
        </div>
        <div className="services" ref={servicesRef}>
          <h2>Services</h2>
          <div className="services-container">
            <div className="service-item text-center">
              <div className="service-item-image">
                <img src={hurtImage} alt="temp image" />
              </div>
              <div className="service-item-text">
                <div>Workers' Compensation and Risk Management</div>
                <div className="service-item-description">
                  PEOs handle workers' compensation needs for employees, ensure
                  full compliance with workers' comp policies, find a good plan
                  to cover employees and ensure coverage is up to date.
                </div>
              </div>
            </div>
            <div className="service-item text-center">
              <div className="service-item-image">
                <img src={moneyImage} alt="temp image" />
              </div>
              <div className="service-item-text">
                <div>Payroll Services</div>
                <div className="service-item-description">
                  SMEs can always manage payroll independently. However,
                  gathering employee information, issuing paychecks, and
                  recognizing required taxes and withholdings is very
                  time-consuming. Therefore, outsourcing all this to a PEO can
                  be beneficial. They ensure that they process payroll and can
                  pay local, state, and federal employment taxes.
                </div>
              </div>
            </div>
            <div className="service-item text-center">
              <div className="service-item-image">
                <img src={listImage} alt="temp image" />
              </div>
              <div className="service-item-text">
                <div>Compliance & HR (incl. EPLI)</div>
                <div className="service-item-description">
                  PEOs have compliance experts to help you protect your business
                  from penalties and OSHA violations. They can also help you
                  with co-employment and HR compliance, including payroll tax
                  law and reporting requirements, unemployment insurance,
                  workers' compensation, and hiring.
                </div>
              </div>
            </div>
            <div className="service-item text-center">
              <div className="service-item-image">
                <img src={taxImage} alt="temp image" />
              </div>
              <div className="service-item-text">
                <div>Tax Administration</div>
                <div className="service-item-description">
                  The PEO agreement indicates what tax withholding, reporting,
                  and payment the PEO are responsible for on your behalf. Since
                  PEOs are a worldwide phenomenon, they know local and federal
                  tax laws. The PEOs will also pay your employees and your
                  company's taxes with your fees using their EIN.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="industries-we-serve services" ref={whoWeServeRef}>
          <div className="industries-we-serve-header text-center">
            <h2>Who We Serve</h2>
          </div>
          <div className="insustries-container services-container">
            <div className="industry-item service-item">
              <div className="industry-item-image service-item-image">
                <img className="" src={handshakeImage} alt="retail-logo" />
              </div>
              <div className="service-item-text">
                <div className="service-item-header">Affinity Groups</div>
                <div className="service-item-description">
                  We specialize in serving specific industry groups, ensuring
                  your company has the best and most diverse employees,
                  decreasing turnover, and improving employee morale. Studies
                  have shown that having a highly diverse staff can help you
                  earn more revenue than your peers.
                </div>
              </div>
            </div>
            <div className="industry-item service-item">
              <div className="industry-item-image service-item-image">
                <img className="" src={houseImage} alt="restaurant-logo" />
              </div>
              <div className="service-item-text">
                <div className="service-item-header">Franchises</div>
                <div className="service-item-description">
                  When an SME decides to franchise, they require payroll,
                  compliance, HR, Workers' Compensation, and Risk Management
                  services. So as an experienced PEO service broker, we can
                  provide you with better group pricing and other associated
                  benefits.
                </div>
              </div>
            </div>
            <div className="industry-item service-item">
              <div className="industry-item-image service-item-image">
                <img className="" src={groupImage} alt="construction-logo" />
              </div>
              <div className="service-item-text">
                <div className="service-item-header">Industry Associations</div>
                <div className="service-item-description">
                  Industry associations, though large, require the same services
                  as other companies. As a PEO brokerage service, we can help
                  industry associations manage their benefits, HR, Payroll,
                  regulatory compliance assistance, and tax administration.
                </div>
              </div>
            </div>
            <div className="industry-item service-item">
              <div className="industry-item-image service-item-image">
                <img className="" src={bagImage} alt="manufacturing-logo" />
              </div>
              <div className="service-item-text">
                <div className="service-item-header">PE Portfolio Firms</div>
                <div className="service-item-description">
                  PEOs are a great idea for all venture-backed startups and PE
                  firms. A PEO enables the company to have a smoother run,
                  better benefits, and investment protection through the
                  Co-Employment relationship. Getting an effective PEO can help
                  you ensure everything's up to code and you have the best of
                  everything.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSection;
