import { awsUrl, awsUrl2 } from "../config";
import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import {
  deleteAllCookies,
  showErrAlert,
  getHeader,
  blobPdfFromBase64String,
} from "./common";
import { Auth } from "aws-amplify";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";
import { ConsoleLogger } from "@aws-amplify/core";
var instances = require("../instances.json");
const uuidv4 = require("uuid/v4");
const numeral = require("numeral");
var sizeof = require("object-sizeof");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

let salesPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_AGENT_GROUP)) {
          resolve({
            salespersonName: sessionData.attributes.email,
            salespersonCheck: true,
          });
        } else {
          resolve({
            salespersonName: "",
            salespersonCheck: false,
          });
        }
      } else {
        resolve({
          salespersonName: "",
          salespersonCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salespersonName: "",
        salespersonCheck: false,
      });
    }
  });

  return promise;
};

const goodAverageWageValidation = (childrenLoc) => {
  let ftPt = 0,
    total = 0;
  for (let key in childrenLoc) {
    let classCodesInfo = childrenLoc[key].classCodesInfo;
    for (let indx in classCodesInfo) {
      let classCodesInfoObj = classCodesInfo[indx];
      ftPt +=
        Number(classCodesInfoObj.ft.value) + Number(classCodesInfoObj.pt.value);
      total += Number(
        classCodesInfoObj.payroll.value.replace(/[^0-9.-]+/g, "")
      );
    }
  }
  return ftPt <= total;
};

const lossRatioCalculation = (historicalClaims, historicalClaimsCheck) => {
  let currYear = new Date().getFullYear();
  let sum_inc = 0,
    sum_prm = 0;
  for (let yr = currYear; yr > currYear - 3; yr--) {
    if (historicalClaims[yr] && !historicalClaimsCheck[yr]) {
      sum_inc += Number(historicalClaims[yr].totInc.replace(/[^0-9.-]+/g, ""));
      sum_prm += Number(historicalClaims[yr].annPre.replace(/[^0-9.-]+/g, ""));
    }
  }
  return (
    numeral(sum_inc)
      .divide(sum_prm)
      .value() < 0.4
  );
};

const checkAllStatesCalculated = (resData, dataBody) => {
  let response = false;
  let requestStateClassCodeMap = {};
  let responseStateClassCodeMap = {};
  try {
    for (let stateIndex in dataBody.offices) {
      let state = dataBody.offices[stateIndex].state;
      if (!requestStateClassCodeMap[state]) {
        requestStateClassCodeMap[state] = [];
      }
      for (let classCodeIndex in dataBody.offices[stateIndex].employees) {
        let code = dataBody.offices[stateIndex].employees[classCodeIndex].code;
        requestStateClassCodeMap[state].push(code);
      }
    }

    for (let location in resData) {
      for (let carrier in resData[location]) {
        let state = resData[location][carrier].state;
        if (!responseStateClassCodeMap[state]) {
          responseStateClassCodeMap[state] = [];
        }
        for (let classCode in resData[location][carrier]
          .manual_premium_values) {
          responseStateClassCodeMap[state].push(classCode);
        }
      }
    }

    for (let state in requestStateClassCodeMap) {
      if (!(state in responseStateClassCodeMap) || response) {
        response = true;
        break;
      } else {
        for (let classCode of requestStateClassCodeMap[state]) {
          if (!responseStateClassCodeMap[state].includes(classCode)) {
            response = true;
            break;
          }
        }
      }
    }
  } catch (error) {
    console.log("error", error);
    response = true;
  }
  return response;
};

function pride_quote_gen(
  childrenLoc,
  companyProfile,
  emodStatesData,
  historicalClaims,
  historicalClaimsCheck,
  peoType,
  source,
  uuid,
  visitTimestamp,
  currentDate,
  questions,
  uwQues,
  commonObjectForAddressAndSortKeyList
) {
  return new Promise((resolve, reject) => {
    let currProspect;
    let carrierUserData = [];

    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain,
      tempEmail = companyProfile.emailId.value;
    let tempBusinessName = tempEmail.split("@")[1].split(".");
    tempBusinessName.pop();
    let business_name = tempBusinessName.join(".");
    if (generalDomainNames.includes(business_name)) {
      domain = tempEmail.split("@")[0];
    } else {
      domain = business_name;
    }

    if (true) {
      currProspect = {
        childrenLoc: childrenLoc,
        companyProfile: companyProfile,
        emodStatesData: emodStatesData,
      };

      let dataBody = {
        common: {
          el_limits: "100-100-500",
          deductible: 2000,
          date_from: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.effectiveDate.value)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          emod: [],
          questions: [],
          sr_carriers: {},
        },
        offices: [],
      };

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };
          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      let carrierEligBody = {};
      for (let addressBlockNo in childrenLoc) {
        let officesObj = {
          name: `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value}_${childrenLoc[addressBlockNo].zipCode.value}`,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
          carriers: ["carrier_q"],
        };

        if (!(`${officesObj.name}` in carrierEligBody)) {
          carrierEligBody[`${officesObj.name}`] = [];
        }

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();

          carrierEligBody[`${officesObj.name}`].push({
            code,
            description: desc,
            state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          });

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      let teap = 0;

      let urlToGetQuote = instances.pride.awsUrl;
      axios
        .post(urlToGetQuote + "/api/getCarrierElig", JSON.stringify(carrierEligBody))
        .then(async (res) => {
          let eligResponse = res.data;
          let notEligible = false;

          for (let location in eligResponse) {
            for (let carrier in eligResponse[location]) {
              if (eligResponse[location][carrier].value === "no") {
                notEligible = true;
                break;
              }
            }
            if (notEligible) break;
          }

          if (!notEligible) {
            axios
              .post(urlToGetQuote + "/api/getQuote", JSON.stringify(dataBody))
              .then((res) => {
                let dropped = true;
                let data = res.data;

                for (let location in data) {
                  if (
                    data[location]["carrier_q"] &&
                    data[location]["carrier_q"] !== "Dropped"
                  ) {
                    dropped = false;
                    // if (!(location in data)) data[location] = {};
                    // data[location]["carrier"] = data[location]["carrier_q"];
                  }
                }

                if (!dropped) {
                  let prospectAddresses = {};

                  // create the prospect address object
                  for (let stateIndex in childrenLoc) {
                    prospectAddresses[stateIndex] = {
                      stateCode: childrenLoc[stateIndex].state.value,
                      addressId: childrenLoc[stateIndex].number,
                      classCodes: [],
                    };
                    for (let classCodeIndex in childrenLoc[stateIndex]
                      .classCodesInfo) {
                      let classCodeDescArray = childrenLoc[stateIndex].classCodesInfo[
                        classCodeIndex
                      ].classCodeDescription.value.split(":");

                      let code = classCodeDescArray.shift();
                      let desc = classCodeDescArray.join(":").trim();

                      prospectAddresses[stateIndex].classCodes.push({
                        code,
                        payroll: numeral(
                          childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
                            .payroll.value
                        )
                          .value()
                          .toString(),
                        pt: childrenLoc[stateIndex].classCodesInfo[
                          classCodeIndex
                        ].pt.value.trim(),
                        ft: childrenLoc[stateIndex].classCodesInfo[
                          classCodeIndex
                        ].ft.value.trim(),
                        description: desc,
                      });
                    }
                  }

                  for (let place in data) {
                    for (let carrier in data[place]) {
                      if (data[place][carrier] !== "Dropped") {
                        commonObjectForAddressAndSortKeyList.address.push(place);
                        let tempData = {
                          uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}`,
                          date: currentDate,
                          visitDate: visitTimestamp,
                          healthQues: companyProfile.healthQues.value,
                          effective_date: moment(
                            companyProfile.effectiveDate.value
                          ).format("YYYY-MM-DD"),
                          expiration_date: moment(companyProfile.effectiveDate.value)
                            .add(1, "y")
                            .format("YYYY-MM-DD"),
                          prospect_addresses: prospectAddresses,
                          contact_person: companyProfile.primaryContact.value,
                          user_email_id: companyProfile.emailId.value,
                          phone_number: companyProfile.phoneNumber.value,
                          company_name: companyProfile.companyName.value,
                          liability_limitId: "100/100/500",
                          uuid: uuid,
                          source,
                          domain,
                          uw_ques: uwQues,
                          doc_status: "not_uploaded",
                          fein: companyProfile.fein.value,
                          emodStatesData,
                          historicalClaims,
                          historicalClaimsCheck,
                          carrier: `${carrier}+${place}`,
                          carrier_email_id: data[place][carrier].carrier_email,
                          carrier_location_data: data[place][carrier],
                        };
                        commonObjectForAddressAndSortKeyList.sortKeyList.push(
                          tempData.uuid_carrier
                        );
                        carrierUserData.push(tempData);
                      }
                    }
                  }

                  // let uploadDataa = [];
                  // let userTrackingData = {};
                  // userTrackingData.email = sessionStorage.getItem("landingEmail");
                  // userTrackingData.timestamp =
                  //   sessionStorage.getItem("visitTimestamp");
                  // userTrackingData.domain = sessionStorage.getItem("domain");
                  // userTrackingData.companyProfile = JSON.parse(
                  //   sessionStorage.getItem("currProspect")
                  // ).companyProfile;
                  // userTrackingData.emodStatesData = JSON.parse(
                  //   sessionStorage.getItem("currProspect")
                  // ).emodStatesData;
                  // userTrackingData.indicationCost = teap;
                  // userTrackingData.payrollData = JSON.parse(
                  //   sessionStorage.getItem("currProspect")
                  // ).childrenLoc;
                  // userTrackingData.uuid = sessionStorage.getItem("uuid");
                  // uploadDataa.push(userTrackingData);
                  // console.log("*************************");
                  // console.log("********uploadDataa******");
                  // console.log(uploadDataa);
                  // axios
                  //   .post(awsUrl2 + "/api/updateUsersTrackingData", uploadDataa)
                  //   .then((res) => {
                  //     console.log("posted data", res);
                  //   })
                  //   .catch((error) => {
                  //     console.log("error in posting data to user tracking", error);
                  //   })
                  //   .finally((res) => {
                  //     $("#loader").css("display", "none");
                  //   });

                  let prevIndex = 0;
                  let nextIndex = 100;
                  let uploadData;
                  for (
                    let i = 0;
                    i < Math.floor(carrierUserData.length / 100) + 1;
                    i++
                  ) {
                    uploadData = carrierUserData.slice(prevIndex, nextIndex);
                    axios
                      .post(awsUrl + "/api/postUsersData/", uploadData)
                      .then((res) => {
                        resolve();
                      })
                      .catch((error) => {
                        console.log("error in posting data", error);
                        reject();
                      });
                  }
                  console.log("after uploading data correctly");
                } else {
                  reject();
                }
              })
              .catch((error) => {
                console.log("error", error);
                reject();
              });
          } else {
            reject();
          }
        })
    }
  });
}

function solve_peo(
  childrenLoc,
  companyProfile,
  emodStatesData,
  historicalClaims,
  historicalClaimsCheck,
  peoType,
  source,
  uuid,
  visitTimestamp,
  currentDate,
  questions,
  uwQues,
  commonObjectForAddressAndSortKeyList
) {
  return new Promise(async (resolve, reject) => {
    try {
      let carrierUserData = [];
      let carrierUserDataSolve = [];
      console.log("step3");

      let dataBody = {
        common: {
          el_limits: "100-100-500",
          deductible: 2000,
          date_from: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.effectiveDate.value)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          emod: [],
          questions,
          sr_carriers: {},
          peo_type: peoType,
        },
        offices: [],
      };
      let generalDomainNames = [
        "gmail",
        "yahoo",
        "aol",
        "mac",
        "cox",
        "frontier",
        "msn",
        "gammana",
        "hotmail",
        "pacbell",
        "att",
        "sbcglobal",
        "windstream",
        "verizon",
        "comcast",
        "ampcocontracting",
      ];
      let domain,
        tempEmail = companyProfile.emailId.value;
      let tempBusinessName = tempEmail.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = tempEmail.split("@")[0];
      } else {
        domain = business_name;
      }

      let emodForMail;
      console.log("step5");

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };

          if (emodForMail == null && emodStatesData[state].rate1.value)
            emodForMail = emodStatesData[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          // dataBody.common.emod.push(emodObj);
        }
      }

      console.log("step6");

      let carrierEligBody = {};
      let payrollMap = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value
          }_${(childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
          }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
          carriers: ["carrier_n"],
        };
        if (!(`${name}_${addressBlockNo}` in carrierEligBody)) {
          carrierEligBody[`${name}_${addressBlockNo}`] = [];
        }
        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[`${name}_${addressBlockNo}`].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      console.log("step7");

      // let eligibilityEmailData = {
      //   company_name: companyProfile.companyName.value,
      //   contact_person: companyProfile.primaryContact.value,
      //   effective_date: companyProfile.effectiveDate.value,
      //   phone_number: companyProfile.phoneNumber.value,
      //   user_email_id: companyProfile.emailId.value,
      //   emod: emodForMail === 0 || emodForMail == null ? 1 : emodForMail,
      // };

      let urlToGetQuote = instances.Solve.awsUrl;

      console.log("step8");

      console.log("urlToGetQuote: ", urlToGetQuote);
      axios
        .post(
          urlToGetQuote + "/api/getCarrierElig",
          JSON.stringify(carrierEligBody)
        )
        .then(async (res) => {
          let rejectFlag = false;
          let data = res.data,
            nonEligibleMap;
          for (let location in data) {
            for (let carrier in data[location]) {
              if (
                data[location][carrier] &&
                data[location][carrier].value === "no"
              ) {
                if (!rejectFlag) {
                  rejectFlag = true;
                }
                let excludedList = data[location][carrier].excludedList;
                for (let item of excludedList) {
                  item.payroll = payrollMap[item.state][item.class_code];
                }
                // if (!nonEligibleMap) {
                //   nonEligibleMap = {
                //     excludedList: [],
                //     currProspect: currProspectDetails,
                //     timestamp: Math.floor(Date.now()).toString(),
                //     status: "NEW",
                //     email: companyProfile.emailId.value,
                //     domain,
                //   };
                // }

                // nonEligibleMap.excludedList.push(...excludedList);

                // if (salespersonCheck) {
                //   nonEligibleMap.origin = "sales_person";
                // } else if (checkMailCampaignUser()) {
                //   nonEligibleMap.origin = "email_campaign";
                // } else {
                //   nonEligibleMap.origin = "direct_user";
                // }
              }
            }
          }

          if (!rejectFlag) {
            axios
              .post(urlToGetQuote + "/api/getQuote", JSON.stringify(dataBody))
              .then(async (res) => {
                let dropped = false;
                let data = res.data;
                console.log("step9");

                if (!dropped) {
                  let prospectAddresses = {};

                  // create the prospect address object
                  for (let stateIndex in childrenLoc) {
                    prospectAddresses[stateIndex] = {
                      stateCode: childrenLoc[stateIndex].state.value,
                      addressId: childrenLoc[stateIndex].number,
                      classCodes: [],
                    };
                    for (let classCodeIndex in childrenLoc[stateIndex]
                      .classCodesInfo) {
                      let classCodeDescArray = childrenLoc[
                        stateIndex
                      ].classCodesInfo[
                        classCodeIndex
                      ].classCodeDescription.value.split(":");

                      let code = classCodeDescArray.shift();
                      let desc = classCodeDescArray.join(":").trim();

                      prospectAddresses[stateIndex].classCodes.push({
                        code,
                        payroll: numeral(
                          childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
                            .payroll.value
                        )
                          .value()
                          .toString(),
                        pt: childrenLoc[stateIndex].classCodesInfo[
                          classCodeIndex
                        ].pt.value.trim(),
                        ft: childrenLoc[stateIndex].classCodesInfo[
                          classCodeIndex
                        ].ft.value.trim(),
                        description: desc,
                      });
                    }
                  }

                  let origin;

                  // if (salespersonCheck) {
                  //   origin = "sales_person";
                  //   salesperson_email = salespersonName;
                  // } else if (checkMailCampaignUser()) {
                  //   origin = "email_campaign";
                  // } else {
                  //   origin = "direct_user";
                  // }
                  origin = "direct_user";
                  console.log("step9");

                  for (let place in data) {
                    commonObjectForAddressAndSortKeyList.address.push(place);
                    for (let carrier in data[place]) {
                      if (data[place][carrier] !== "Dropped") {
                        let tempData = {
                          uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}+${source}`,
                          date: currentDate,
                          healthQues: companyProfile.healthQues.value,
                          visitDate: visitTimestamp,
                          effective_date: moment(
                            companyProfile.effectiveDate.value
                          ).format("YYYY-MM-DD"),
                          expiration_date: moment(
                            companyProfile.effectiveDate.value
                          )
                            .add(1, "y")
                            .format("YYYY-MM-DD"),
                          prospect_addresses: prospectAddresses,
                          contact_person: companyProfile.primaryContact.value,
                          user_email_id: companyProfile.emailId.value,
                          phone_number: companyProfile.phoneNumber.value,
                          company_name: companyProfile.companyName.value,
                          fein: companyProfile.fein.value,
                          liability_limitId: "100/100/500",
                          uuid: uuid,
                          carrier: `${carrier}+${place}`,
                          carrier_email_id: data[place][carrier].carrier_email,
                          uw_ques: uwQues,
                          carrier_location_data: data[place][carrier],
                          domain,
                          doc_status: "not_uploaded",
                          emodStatesData,
                          historicalClaims,
                          historicalClaimsCheck,
                          origin,
                          peoType,
                          source,
                        };
                        commonObjectForAddressAndSortKeyList.sortKeyList.push(
                          tempData.uuid_carrier
                        );
                        carrierUserData.push(tempData);
                      }
                    }
                  }

                  if (sessionStorage.getItem("landingEmail")) {
                    let uploadDataa = [];
                    let userTrackingData = {};
                    userTrackingData.email = sessionStorage.getItem(
                      "landingEmail"
                    );
                    userTrackingData.timestamp = visitTimestamp;
                    userTrackingData.domain = sessionStorage.getItem("domain");
                    userTrackingData.companyProfile = JSON.parse(
                      sessionStorage.getItem("currProspect")
                    ).companyProfile;
                    userTrackingData.emodStatesData = JSON.parse(
                      sessionStorage.getItem("currProspect")
                    ).emodStatesData;
                    userTrackingData.payrollData = JSON.parse(
                      sessionStorage.getItem("currProspect")
                    ).childrenLoc;
                    userTrackingData.uuid = sessionStorage.getItem("uuid");
                    uploadDataa.push(userTrackingData);
                    // console.log("*************************");
                    // console.log("********uploadDataa******");
                    // console.log(uploadDataa);

                    //commented till confirmed
                    // await axios
                    //   .post(
                    //     awsUrl2 + "/api/updateUsersTrackingData",
                    //     uploadDataa
                    //   )
                    //   .then((res) => {
                    //     console.log("posted data", res);
                    //   })
                    //   .catch((error) => {
                    //     console.log(
                    //       "error in posting data to user tracking",
                    //       error
                    //     );
                    //   });
                  }

                  let prevIndex = 0;
                  let nextIndex = 100;
                  let uploadData;
                  console.log("step10");
                  for (
                    let i = 0;
                    i < Math.floor(carrierUserData.length / 100) + 1;
                    i++
                  ) {
                    uploadData = carrierUserData.slice(prevIndex, nextIndex);
                    axios
                      .post(awsUrl + "/api/postUsersData", uploadData)
                      .then((res) => {
                        // sessionStorage.setItem("formStage", "three");
                        // $("#loader").css("display", "none");
                        // window.location.reload();
                        resolve();
                      })
                      .catch((error) => {
                        console.log("error in posting data", error);
                        reject();
                      });
                  }
                } else {
                  console.log("dropped");
                  // debugger;
                  // sessionStorage.setItem("formStage", "two");
                  // $("#loader").css("display", "none");
                  // window.location.reload();
                  reject();
                }
              })
              .catch((error) => {
                console.log("error", error);
                // debugger;
                // $("#loader").css("display", "none");
                // window.location.reload();
                reject();
              });
          } else {
            resolve();
            //*commented till confirmed*
            // try {
            //   axios
            //     .post(
            //       awsUrl2 + "/api/sendEligibilityEmail",
            //       eligibilityEmailData
            //     )
            //     .then((res) => {
            //       axios
            //         .post(
            //           awsUrl2 + "/api/putNonEligibleData",
            //           JSON.stringify(nonEligibleMap)
            //         )
            //         .then((res) => console.log("uploaded"))
            //         .catch((err) => console.log(err))
            //         .finally(() => {
            //           $("#loader").css("display", "none");
            //           updatePopUpData({
            //             show: true,
            //             title: "Thank you for choosing us!!",
            //             children: (
            //               <>
            //                 <br></br>
            //                 <span style={{ fontWeight: "bold" }}>
            //                   Our Team will get back to you in the next 24
            //                   Hours.
            //                 </span>
            //               </>
            //             ),
            //             acceptBtn: "Okay",
            //             disableNoButton: true,
            //           });
            //         });
            //     })
            //     .catch((error) => console.log("error: ", error))
            //     .finally(() => {
            //       $("#loader").css("display", "none");
            //     });
            // } catch (error) {
            //   console.log("error in sendStatusEmail-API");
            //   $("#loader").css("display", "none");
            // }
          }
        })
        .catch((err) => {
          console.log("error in eligibility api", err);
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in solve peo basic quote generation");
      reject();
    }
  });
}

function fourth_peo(
  childrenLoc,
  companyProfile,
  emodStatesData,
  historicalClaims,
  historicalClaimsCheck,
  peoType,
  source,
  uuid,
  visitTimestamp,
  currentDate,
  questions,
  uwQues,
  commonObjectForAddressAndSortKeyList
) {
  return new Promise(async (resolve, reject) => {
    try {
      let carrierUserData = [];
      console.log("step3");

      let generalDomainNames = [
        "gmail",
        "yahoo",
        "aol",
        "mac",
        "cox",
        "frontier",
        "msn",
        "gammana",
        "hotmail",
        "pacbell",
        "att",
        "sbcglobal",
        "windstream",
        "verizon",
        "comcast",
        "ampcocontracting",
      ];
      let domain,
        tempEmail = companyProfile.emailId.value;
      let tempBusinessName = tempEmail.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = tempEmail.split("@")[0];
      } else {
        domain = business_name;
      }

      let dataBody = {
        common: {
          el_limits: "1000-1000-1000",
          date_from: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.effectiveDate.value)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          emod: [],
          questions: [],
          sr_carriers: {},
        },
        offices: [],
      };

      let emodForMail;
      console.log("step5");

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };

          if (emodForMail == null && emodStatesData[state].rate1.value)
            emodForMail = emodStatesData[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      console.log("step6");

      let payrollMap = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value
          }_${(childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
          }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          suta: "",
          employees: [],
          carriers: ["carrier_p"],
        };

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();
          officesObj.suta =
            childrenLoc[addressBlockNo].suta &&
              childrenLoc[addressBlockNo].suta.value
              ? childrenLoc[addressBlockNo].suta.value.split("%")[0]
              : "";

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
                childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                  .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                  addressChildNo
                ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      console.log("step7");

      let urlToGetQuote = instances.Fourth.awsUrl;

      console.log("step8");

      console.log("urlToGetQuote: ", urlToGetQuote);

      axios
        .post(urlToGetQuote + "/api/getQuote", JSON.stringify(dataBody))
        .then(async (res) => {
          let dropped = false;
          let data = res.data;
          for (let location in data) {
            if (data[location]["carrier_p"] !== "Dropped") {
              dropped = false;
            }
          }
          console.log("step9");

          if (!dropped) {
            let prospectAddresses = {};

            // create the prospect address object
            for (let stateIndex in childrenLoc) {
              prospectAddresses[stateIndex] = {
                stateCode: childrenLoc[stateIndex].state.value,
                addressId: childrenLoc[stateIndex].number,
                classCodes: [],
              };
              for (let classCodeIndex in childrenLoc[stateIndex]
                .classCodesInfo) {
                let classCodeDescArray = childrenLoc[stateIndex].classCodesInfo[
                  classCodeIndex
                ].classCodeDescription.value.split(":");

                let code = classCodeDescArray.shift();
                let desc = classCodeDescArray.join(":").trim();

                prospectAddresses[stateIndex].classCodes.push({
                  code,
                  payroll: numeral(
                    childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
                      .payroll.value
                  )
                    .value()
                    .toString(),
                  pt: childrenLoc[stateIndex].classCodesInfo[
                    classCodeIndex
                  ].pt.value.trim(),
                  ft: childrenLoc[stateIndex].classCodesInfo[
                    classCodeIndex
                  ].ft.value.trim(),
                  description: desc,
                });
              }
            }

            let origin;

            origin = "direct_user";
            console.log("step9");

            for (let place in data) {
              commonObjectForAddressAndSortKeyList.address.push(place);
              for (let carrier in data[place]) {
                if (data[place][carrier] !== "Dropped") {
                  let tempData = {
                    uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}+${source}`,
                    date: currentDate,
                    healthQues: companyProfile.healthQues.value,
                    visitDate: visitTimestamp,
                    effective_date: moment(
                      companyProfile.effectiveDate.value
                    ).format("YYYY-MM-DD"),
                    expiration_date: moment(
                      companyProfile.effectiveDate.value
                    )
                      .add(1, "y")
                      .format("YYYY-MM-DD"),
                    prospect_addresses: prospectAddresses,
                    contact_person: companyProfile.primaryContact.value,
                    user_email_id: companyProfile.emailId.value,
                    phone_number: companyProfile.phoneNumber.value,
                    company_name: companyProfile.companyName.value,
                    fein: companyProfile.fein.value,
                    liability_limitId: "100/100/500",
                    uuid: uuid,
                    carrier: `${carrier}+${place}`,
                    carrier_email_id: data[place][carrier].carrier_email,
                    uw_ques: uwQues,
                    carrier_location_data: data[place][carrier],
                    doc_status: "not_uploaded",
                    emodStatesData,
                    historicalClaims,
                    historicalClaimsCheck,
                    origin,
                    domain,
                    peoType,
                    source,
                  };
                  commonObjectForAddressAndSortKeyList.sortKeyList.push(
                    tempData.uuid_carrier
                  );
                  carrierUserData.push(tempData);
                }
              }
            }

            if (sessionStorage.getItem("landingEmail")) {
              let uploadDataa = [];
              let userTrackingData = {};
              userTrackingData.email = sessionStorage.getItem(
                "landingEmail"
              );
              userTrackingData.timestamp = visitTimestamp;
              userTrackingData.domain = sessionStorage.getItem("domain");
              userTrackingData.companyProfile = JSON.parse(
                sessionStorage.getItem("currProspect")
              ).companyProfile;
              userTrackingData.emodStatesData = JSON.parse(
                sessionStorage.getItem("currProspect")
              ).emodStatesData;
              userTrackingData.payrollData = JSON.parse(
                sessionStorage.getItem("currProspect")
              ).childrenLoc;
              userTrackingData.uuid = sessionStorage.getItem("uuid");
              uploadDataa.push(userTrackingData);
              // console.log("*************************");
              // console.log("********uploadDataa******");
              // console.log(uploadDataa);

              //commented till confirmed
              // await axios
              //   .post(
              //     awsUrl2 + "/api/updateUsersTrackingData",
              //     uploadDataa
              //   )
              //   .then((res) => {
              //     console.log("posted data", res);
              //   })
              //   .catch((error) => {
              //     console.log(
              //       "error in posting data to user tracking",
              //       error
              //     );
              //   });
            }

            let prevIndex = 0;
            let nextIndex = 100;
            let uploadData;
            console.log("step10");
            for (
              let i = 0;
              i < Math.floor(carrierUserData.length / 100) + 1;
              i++
            ) {
              uploadData = carrierUserData.slice(prevIndex, nextIndex);
              axios
                .post(awsUrl + "/api/postUsersData", uploadData)
                .then((res) => {
                  // sessionStorage.setItem("formStage", "three");
                  // $("#loader").css("display", "none");
                  // window.location.reload();
                  resolve();
                })
                .catch((error) => {
                  console.log("error in posting data", error);
                  reject();
                });
            }
          } else {
            console.log("dropped");
            // debugger;
            // sessionStorage.setItem("formStage", "two");
            // $("#loader").css("display", "none");
            // window.location.reload();
            reject();
          }
        })
        .catch((error) => {
          console.log("error", error);
          // debugger;
          // $("#loader").css("display", "none");
          // window.location.reload();
          reject();
        });

    } catch (error) {
      console.log("error in solve peo basic quote generation");
      reject();
    }
  });
}

function solve_dummy(
  childrenLoc,
  companyProfile,
  emodStatesData,
  historicalClaims,
  historicalClaimsCheck,
  peoType,
  source,
  uuid,
  visitTimestamp,
  currentDate,
  questions,
  uwQues,
  commonObjectForAddressAndSortKeyList
) {
  return new Promise(async (resolve, reject) => {
    try {
      let carrierUserData = [];
      let carrierUserDataSolve = [];
      console.log("step3");

      let dataBody = {
        common: {
          el_limits: "100-100-500",
          deductible: 2000,
          date_from: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.effectiveDate.value)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          emod: [],
          questions,
          sr_carriers: {},
          peo_type: peoType,
        },
        offices: [],
      };
      let generalDomainNames = [
        "gmail",
        "yahoo",
        "aol",
        "mac",
        "cox",
        "frontier",
        "msn",
        "gammana",
        "hotmail",
        "pacbell",
        "att",
        "sbcglobal",
        "windstream",
        "verizon",
        "comcast",
        "ampcocontracting",
      ];
      let domain,
        tempEmail = companyProfile.emailId.value;
      let tempBusinessName = tempEmail.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = tempEmail.split("@")[0];
      } else {
        domain = business_name;
      }

      let emodForMail;
      console.log("step5");

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };

          if (emodForMail == null && emodStatesData[state].rate1.value)
            emodForMail = emodStatesData[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      console.log("step6");

      let carrierEligBody = {};
      let payrollMap = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value
          }_${(childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
          }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
          carriers: ["carrier_n"],
        };
        if (!(`${name}_${addressBlockNo}` in carrierEligBody)) {
          carrierEligBody[`${name}_${addressBlockNo}`] = [];
        }
        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[`${name}_${addressBlockNo}`].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      console.log("step7");

      let eligibilityEmailData = {
        company_name: companyProfile.companyName.value,
        contact_person: companyProfile.primaryContact.value,
        effective_date: companyProfile.effectiveDate.value,
        phone_number: companyProfile.phoneNumber.value,
        user_email_id: companyProfile.emailId.value,
        emod: emodForMail === 0 || emodForMail == null ? 1 : emodForMail,
      };

      let urlToGetQuote = instances.Solve.awsUrl;

      console.log("step8");

      console.log("urlToGetQuote: ", urlToGetQuote);
      // axios
      //   .post(
      //     urlToGetQuote + "/api/getCarrierElig",
      //     JSON.stringify(carrierEligBody)
      //   )
      //   .then(async (res) => {
      let rejectFlag = false;
      //let data = res.data,
      //   nonEligibleMap;
      // for (let location in data) {
      //   for (let carrier in data[location]) {
      //     if (
      //       data[location][carrier] &&
      //       data[location][carrier].value === "no"
      //     ) {
      //       if (!rejectFlag) {
      //         rejectFlag = true;
      //       }
      //       let excludedList = data[location][carrier].excludedList;
      //       for (let item of excludedList) {
      //         item.payroll = payrollMap[item.state][item.class_code];
      //       }
      //       if (!nonEligibleMap) {
      //         nonEligibleMap = {
      //           excludedList: [],
      //           currProspect: currProspectDetails,
      //           timestamp: Math.floor(Date.now()).toString(),
      //           status: "NEW",
      //           email: companyProfile.emailId.value,
      //           domain,
      //         };
      //       }

      //       nonEligibleMap.excludedList.push(...excludedList);

      //       if (salespersonCheck) {
      //         nonEligibleMap.origin = "sales_person";
      //       } else if (checkMailCampaignUser()) {
      //         nonEligibleMap.origin = "email_campaign";
      //       } else {
      //         nonEligibleMap.origin = "direct_user";
      //       }
      //     }
      //   }
      // }

      if (!rejectFlag) {
        axios
          .post(urlToGetQuote + "/api/getQuote", JSON.stringify(dataBody))
          .then(async (res) => {
            let dropped = false;
            try {
              dropped = checkAllStatesCalculated(res.data, dataBody);
            } catch (error) {
              dropped = true;
              console.log(error);
            }
            let data = res.data;

            console.log("step9", data);

            if (!dropped) {
              let prospectAddresses = {};

              // create the prospect address object
              for (let stateIndex in childrenLoc) {
                prospectAddresses[stateIndex] = {
                  stateCode: childrenLoc[stateIndex].state.value,
                  addressId: childrenLoc[stateIndex].number,
                  classCodes: [],
                };
                for (let classCodeIndex in childrenLoc[stateIndex]
                  .classCodesInfo) {
                  let classCodeDescArray = childrenLoc[
                    stateIndex
                  ].classCodesInfo[
                    classCodeIndex
                  ].classCodeDescription.value.split(":");

                  let code = classCodeDescArray.shift();
                  let desc = classCodeDescArray.join(":").trim();

                  prospectAddresses[stateIndex].classCodes.push({
                    code,
                    payroll: numeral(
                      childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
                        .payroll.value
                    )
                      .value()
                      .toString(),
                    pt: childrenLoc[stateIndex].classCodesInfo[
                      classCodeIndex
                    ].pt.value.trim(),
                    ft: childrenLoc[stateIndex].classCodesInfo[
                      classCodeIndex
                    ].ft.value.trim(),
                    description: desc,
                  });
                }
              }

              let origin;

              // if (salespersonCheck) {
              //   origin = "sales_person";
              //   salesperson_email = salespersonName;
              // } else if (checkMailCampaignUser()) {
              //   origin = "email_campaign";
              // } else {
              //   origin = "direct_user";
              // }
              origin = "direct_user";
              console.log("step9");

              for (let place in data) {
                commonObjectForAddressAndSortKeyList.address.push(place);
                for (let carrier in data[place]) {
                  if (data[place][carrier] !== "Dropped") {
                    let tempData = {
                      uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}+${source}`,
                      date: currentDate,
                      healthQues: companyProfile.healthQues.value,
                      visitDate: visitTimestamp,
                      effective_date: moment(
                        companyProfile.effectiveDate.value
                      ).format("YYYY-MM-DD"),
                      expiration_date: moment(
                        companyProfile.effectiveDate.value
                      )
                        .add(1, "y")
                        .format("YYYY-MM-DD"),
                      prospect_addresses: prospectAddresses,
                      contact_person: companyProfile.primaryContact.value,
                      user_email_id: companyProfile.emailId.value,
                      phone_number: companyProfile.phoneNumber.value,
                      company_name: companyProfile.companyName.value,
                      fein: companyProfile.fein.value,
                      liability_limitId: "100/100/500",
                      uuid: uuid,
                      carrier: `${carrier}+${place}`,
                      carrier_email_id: data[place][carrier].carrier_email,
                      uw_ques: uwQues,
                      carrier_location_data: data[place][carrier],
                      domain,
                      doc_status: "not_uploaded",
                      emodStatesData,
                      historicalClaims,
                      historicalClaimsCheck,
                      origin,
                      peoType,
                      source,
                    };
                    commonObjectForAddressAndSortKeyList.sortKeyList.push(
                      tempData.uuid_carrier
                    );
                    carrierUserData.push(tempData);
                  }
                }
              }

              if (sessionStorage.getItem("landingEmail")) {
                let uploadDataa = [];
                let userTrackingData = {};
                userTrackingData.email = sessionStorage.getItem("landingEmail");
                userTrackingData.timestamp = visitTimestamp;
                userTrackingData.domain = sessionStorage.getItem("domain");
                userTrackingData.companyProfile = JSON.parse(
                  sessionStorage.getItem("currProspect")
                ).companyProfile;
                userTrackingData.emodStatesData = JSON.parse(
                  sessionStorage.getItem("currProspect")
                ).emodStatesData;
                userTrackingData.payrollData = JSON.parse(
                  sessionStorage.getItem("currProspect")
                ).childrenLoc;
                userTrackingData.uuid = sessionStorage.getItem("uuid");
                uploadDataa.push(userTrackingData);
                // console.log("*************************");
                // console.log("********uploadDataa******");
                // console.log(uploadDataa);

                //commented till confirmed
                // await axios
                //   .post(
                //     awsUrl2 + "/api/updateUsersTrackingData",
                //     uploadDataa
                //   )
                //   .then((res) => {
                //     console.log("posted data", res);
                //   })
                //   .catch((error) => {
                //     console.log(
                //       "error in posting data to user tracking",
                //       error
                //     );
                //   });
              }

              let prevIndex = 0;
              let nextIndex = 100;
              let uploadData;
              console.log("step10");
              for (
                let i = 0;
                i < Math.floor(carrierUserData.length / 100) + 1;
                i++
              ) {
                uploadData = carrierUserData.slice(prevIndex, nextIndex);
                axios
                  .post(awsUrl + "/api/postUsersData", uploadData)
                  .then((res) => {
                    // sessionStorage.setItem("formStage", "three");
                    // $("#loader").css("display", "none");
                    // window.location.reload();
                    resolve();
                  })
                  .catch((error) => {
                    console.log("error in posting data", error);
                    reject();
                  });
              }
            } else {
              console.log("dropped");
              // debugger;
              // sessionStorage.setItem("formStage", "two");
              // $("#loader").css("display", "none");
              // window.location.reload();
              reject();
            }
          })
          .catch((error) => {
            console.log("error", error);
            // debugger;
            // $("#loader").css("display", "none");
            // window.location.reload();
            reject();
          });
      } else {
        resolve();
        //*commented till confirmed*
        // try {
        //   axios
        //     .post(
        //       awsUrl2 + "/api/sendEligibilityEmail",
        //       eligibilityEmailData
        //     )
        //     .then((res) => {
        //       axios
        //         .post(
        //           awsUrl2 + "/api/putNonEligibleData",
        //           JSON.stringify(nonEligibleMap)
        //         )
        //         .then((res) => console.log("uploaded"))
        //         .catch((err) => console.log(err))
        //         .finally(() => {
        //           $("#loader").css("display", "none");
        //           updatePopUpData({
        //             show: true,
        //             title: "Thank you for choosing us!!",
        //             children: (
        //               <>
        //                 <br></br>
        //                 <span style={{ fontWeight: "bold" }}>
        //                   Our Team will get back to you in the next 24
        //                   Hours.
        //                 </span>
        //               </>
        //             ),
        //             acceptBtn: "Okay",
        //             disableNoButton: true,
        //           });
        //         });
        //     })
        //     .catch((error) => console.log("error: ", error))
        //     .finally(() => {
        //       $("#loader").css("display", "none");
        //     });
        // } catch (error) {
        //   console.log("error in sendStatusEmail-API");
        //   $("#loader").css("display", "none");
        // }
      }
      // })
      // .catch((err) => {
      //   console.log("error in eligibility api", err);
      //   $("#loader").css("display", "none");
      // });
    } catch (error) {
      console.log("error in solve peo basic quote generation");
      reject();
    }
  });
}

function wcn(
  currProspectDetails,
  uuid,
  visitTimestamp,
  currentDate,
  commonObjectForAddressAndSortKeyList
) {
  return new Promise(async (resolve, reject) => {
    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
    } = currProspectDetails;

    // let childFlag = goodAverageWageValidation(childrenLoc);
    // let lossFlag = lossRatioCalculation(
    //   historicalClaims,
    //   historicalClaimsCheck
    // );

    // console.log("childFlag: ", childFlag);
    // console.log("lossFlag: ", lossFlag);

    let uwQuesData = [];
    // for (let question of quesList) {
    //   let remarks =
    //     !question.remarks || question.remarks.trim() === ""
    //       ? "none"
    //       : question.remarks;
    //   let dataQues = {
    //     resp: question.response ? "Y" : "N",
    //     remarks: remarks,
    //   };
    //   uwQuesData.push(dataQues);
    // }

    // let questions = [];

    if (
      childrenLoc &&
      companyProfile &&
      emodStatesData &&
      historicalClaims &&
      historicalClaimsCheck
    ) {
      let historicalClaimsList = [];
      for (let year in historicalClaims) {
        if (!historicalClaimsCheck[year]) {
          let objRow = {
            year: year,
            carrier: historicalClaims[year].carrier,
            annPre: historicalClaims[year].annPre,
            claim: historicalClaims[year].claim,
            totInc: historicalClaims[year].totInc,
            totPaid: historicalClaims[year].totPaid,
            totRes: historicalClaims[year].totRes,
          };
          historicalClaimsList.unshift(objRow);
        }
      }

      let lossFrequency;
      if (historicalClaimsList.length > 0) {
        let aggClaim = 0;
        let aggPremium = 0;
        for (let historicalClaim of historicalClaimsList) {
          let claim = numeral(historicalClaim.claim).value();
          let premium = numeral(historicalClaim.annPre).value();

          aggClaim = numeral(aggClaim)
            .add(claim)
            .value();
          aggPremium = numeral(aggPremium)
            .add(premium)
            .value();
        }

        if (aggPremium !== 0) {
          lossFrequency = numeral(aggClaim)
            .divide(
              numeral(aggPremium)
                .divide(10000)
                .value()
            )
            .value();
        }
      }

      // let dataBody = {
      //   common: {
      //     el_limits: "100-100-500",
      //     deductible: 2000,
      //     date_from: moment(companyProfile.effectiveDate.value).format(
      //       "YYYY-MM-DD"
      //     ),
      //     date_to: moment(companyProfile.effectiveDate.value)
      //       .add(1, "y")
      //       .format("YYYY-MM-DD"),
      //     emod: [],
      //     sr_carriers: {},
      //     peo_type: peoType,
      //   },
      //   offices: [],
      // };

      // if (lossFrequency !== undefined) {
      //   dataBody.common.loss_frequency = lossFrequency;
      // }

      let generalDomainNames = [
        "gmail",
        "yahoo",
        "aol",
        "mac",
        "cox",
        "frontier",
        "msn",
        "gammana",
        "hotmail",
        "pacbell",
        "att",
        "sbcglobal",
        "windstream",
        "verizon",
        "comcast",
        "ampcocontracting",
      ];
      let domain,
        tempEmail = companyProfile.emailId.value;
      let tempBusinessName = tempEmail.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = tempEmail.split("@")[0];
      } else {
        domain = business_name;
      }

      let emodForMail;

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };

          if (emodForMail == null && emodStatesData[state].rate1.value)
            emodForMail = emodStatesData[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          //dataBody.common.emod.push(emodObj);
        }
      }

      let carrierEligBody = {};
      let payrollMap = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value
          }_${(childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
          }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
          carriers: ["carrier_n"],
        };
        if (!(`${name}_${addressBlockNo}` in carrierEligBody)) {
          carrierEligBody[`${name}_${addressBlockNo}`] = [];
        }
        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[`${name}_${addressBlockNo}`].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
      }

      let prospectAddresses = {};

      // create the prospect address object
      for (let stateIndex in childrenLoc) {
        prospectAddresses[stateIndex] = {
          stateCode: childrenLoc[stateIndex].state.value,
          addressId: childrenLoc[stateIndex].number,
          classCodes: [],
        };
        for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
          let classCodeDescArray = childrenLoc[stateIndex].classCodesInfo[
            classCodeIndex
          ].classCodeDescription.value.split(":");

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();

          prospectAddresses[stateIndex].classCodes.push({
            code,
            payroll: numeral(
              childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
                .value
            )
              .value()
              .toString(),
            pt: childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].pt.value.trim(),
            ft: childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].ft.value.trim(),
            description: desc,
          });
          // quoteFactors.payroll += numeral(
          //   childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
          // ).value();
        }
      }

      commonObjectForAddressAndSortKeyList.address.push("place");
      commonObjectForAddressAndSortKeyList.sortKeyList.push(
        `${currentDate}@${uuid}_${"carrier"}+${"place"}`
      );

      let tempData = {
        carrier_email_id: "carrier@gmail.com",
        historicalClaims: historicalClaims || {},
        historicalClaimsCheck: historicalClaimsCheck || {},
        uuid_carrier: `${currentDate}@${uuid}_${"carrier"}+${"place"}`,
        date: currentDate,
        healthQues: companyProfile.healthQues.value,
        effective_date: moment(companyProfile.effectiveDate.value).format(
          "YYYY-MM-DD"
        ),
        expiration_date: moment(companyProfile.effectiveDate.value)
          .add(1, "y")
          .format("YYYY-MM-DD"),
        carrier: "carrier",
        prospect_addresses: prospectAddresses,
        contact_person: companyProfile.primaryContact.value,
        user_email_id: companyProfile.emailId.value,
        phone_number: companyProfile.phoneNumber.value,
        company_name: companyProfile.companyName.value,
        fein: companyProfile.fein.value,
        liability_limitId: "100/100/500",
        uuid: uuid,
        domain,
        visitDate: visitTimestamp,
        doc_status: "not_uploaded",
        emodStatesData,
        source: "work_comp_now",
      };

      axios
        .post(awsUrl + "/api/postUsersData", tempData)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in posting data", error);
          reject();
        });
    }
  });
}

export let submitQues = async (quesList, updatePopUpData) => {
  $("#loader").css("display", "block");
  try {
    console.log("step1");

    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );
    let uwQuesData = [];
    for (let question of quesList) {
      let remarks =
        !question.remarks || question.remarks.trim() === ""
          ? "none"
          : question.remarks;
      let dataQues = {
        resp: question.response ? "Y" : "N",
        remarks: remarks,
      };
      uwQuesData.push(dataQues);
    }

    console.log("step2");

    let questions = [];

    currProspectDetails.uwQues = uwQuesData;
    sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));

    let currentDate = Math.floor(Date.now()).toString();
    let visitTimestamp = sessionStorage.getItem("visitTimestamp");
    let uuid = sessionStorage.getItem("uuid");
    if (!uuid) {
      uuid = uuidv4();
    }

    let quoteData = {
      uuid,
      date: currentDate,
    };
    sessionStorage.setItem("quoteData", JSON.stringify(quoteData));

    let instancesPromiseList = [];

    let commonObjectForAddressAndSortKeyList = {
      sortKeyList: [],
      address: [],
    };

    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
      uwQues,
    } = currProspectDetails;
    if (childrenLoc && companyProfile && emodStatesData) {
      // instancesPromiseList.push(
      //   solve_dummy(
      //     childrenLoc,
      //     companyProfile,
      //     emodStatesData,
      //     historicalClaims,
      //     historicalClaimsCheck,
      //     "peo_plus",
      //     "solve_dummy",
      //     uuid,
      //     visitTimestamp,
      //     currentDate,
      //     questions,
      //     uwQues,
      //     commonObjectForAddressAndSortKeyList
      //   )
      //     .then((res) => {})
      //     .catch((error) =>
      //       console.log("error in solve_dummy quote gen", error)
      //     )
      // );

      // instancesPromiseList.push(
      //   wcn(
      //     currProspectDetails,
      //     uuid,
      //     visitTimestamp,
      //     currentDate,
      //     commonObjectForAddressAndSortKeyList
      //   )
      //     .then((res) => {})
      //     .catch((error) => console.log("error in wcn quote gen", error))
      // );

      /* have to uncomment from here */
      instancesPromiseList.push(
        solve_peo(
          childrenLoc,
          companyProfile,
          emodStatesData,
          historicalClaims,
          historicalClaimsCheck,
          "peo_basic",
          "solve_peo_basic",
          uuid,
          visitTimestamp,
          currentDate,
          questions,
          uwQues,
          commonObjectForAddressAndSortKeyList
        )
          .then((res) => { })
          .catch((error) =>
            console.log("error in solve_peo_basic quote gen", error)
          )
      );

      instancesPromiseList.push(
        solve_peo(
          childrenLoc,
          companyProfile,
          emodStatesData,
          historicalClaims,
          historicalClaimsCheck,
          "peo_plus",
          "solve_peo_plus",
          uuid,
          visitTimestamp,
          currentDate,
          questions,
          uwQues,
          commonObjectForAddressAndSortKeyList
        )
          .then((res) => { })
          .catch((error) =>
            console.log("error in solve_peo_plus quote gen", error)
          )
      );

      instancesPromiseList.push(
        pride_quote_gen(
          childrenLoc,
          companyProfile,
          emodStatesData,
          historicalClaims,
          historicalClaimsCheck,
          "peo_plus",
          "pride",
          uuid,
          visitTimestamp,
          currentDate,
          questions,
          uwQues,
          commonObjectForAddressAndSortKeyList
        )
          .then((res) => { })
          .catch((error) => console.log("error in pride quote gen", error))
      );
      /* have to uncomment to here */

      instancesPromiseList.push(
        fourth_peo(
          childrenLoc,
          companyProfile,
          emodStatesData,
          historicalClaims,
          historicalClaimsCheck,
          "peo_basic",
          "fourth_peo_basic",
          uuid,
          visitTimestamp,
          currentDate,
          questions,
          uwQues,
          commonObjectForAddressAndSortKeyList
        )
          .then((res) => { })
          .catch((error) =>
            console.log("error in fourth_peo_basic quote gen", error)
          )
      );

      Promise.all(instancesPromiseList)
        .then(async (res) => {
          sessionStorage.setItem(
            "sortKeyList",
            JSON.stringify(commonObjectForAddressAndSortKeyList.sortKeyList)
          );
          sessionStorage.setItem(
            "address",
            JSON.stringify(commonObjectForAddressAndSortKeyList.address)
          );
          sessionStorage.setItem("formStage", "three");

          if (sessionStorage.getItem("landingEmail")) {
            let uploadDataa = [];
            let userTrackingData = {};
            userTrackingData.email = sessionStorage.getItem(
              "landingEmail"
            );
            userTrackingData.timestamp = sessionStorage.getItem(
              "visitTimestamp"
            ) || Math.floor(Date.now()).toString();
            userTrackingData.domain = sessionStorage.getItem(
              "domain"
            );
            userTrackingData.companyProfile =
              companyProfile;
            userTrackingData.emodStatesData =
              emodStatesData;
            userTrackingData.indicationCost = 0;
            userTrackingData.payrollData = childrenLoc;
            userTrackingData.historicalClaims = historicalClaims;
            userTrackingData.historicalClaimsCheck = historicalClaimsCheck;
            userTrackingData.uuid = sessionStorage.getItem("uuid");
            uploadDataa.push(userTrackingData);

            await axios
              .post(
                awsUrl2 + "/api/updateUsersTrackingData",
                uploadDataa
              )
              .then((res) => {
                console.log("posted data", res);
              })
              .catch((error) => {
                console.log(
                  "error in posting data to user tracking",
                  error
                );
              });
          }

          $("#loader").css("display", "none");
          window.location.reload();
        })
        .catch((error) => {
          console.log("error in promise all of quote generation: ", error);
          $("#loader").css("display", "none");
        });
    }
  } catch (error) {
    console.log("error", error);
    $("#loader").css("display", "none");
  }
};

export function updateQuesList(e, key, value) {
  let { quesList } = this.state;
  let index = e.target.id.split("-")[1];
  quesList[index][key] = value;
  this.setState({ quesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach((question) => {
    quesListAdd[index] = question;
    index++;
  });
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {};
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}
