import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

const PrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();

        if (rest.allowedGroups) {
          let userGroups =
            isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
          let allowedAccess = rest.allowedGroups.some((e) =>
            userGroups.includes(e)
          );
          if (!allowedAccess) {
            isLoggedIn = false;
          }
        }
      } catch (error) {
        console.log("error in private route group permissions", error);
      }
      if (!isLoggedIn) {
        setLoggedInStatus(false);
      }
    }
    checkLoggedInStatus();
  }, []);

  let [isLoggedIn, setLoggedInStatus] = useState(true);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
