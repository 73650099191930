//component for description code mapping
import React, { Component } from "react";
import { awsUrl2 } from "../../config";
import { getHeader } from "../../utils/common";
import AsyncSelect from "react-select/async";
import { Form } from "react-bootstrap";

const axios = require("axios");
class DescriptionCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOptionsMessage: "Enter 3 characters to start",
      key: Math.random(),
      initialDescApiCalled: false,
      placeholderText: "Enter 3 characters",
    };
  }

  componentDidUpdate = () => {
    const {
      addressBlockNo,
      addressChildNo,
      classCodesInfo,
      state,
    } = this.props;
    const { updateLocationError } = this.props;
    if (
      JSON.stringify(classCodesInfo) !==
      JSON.stringify(this.state.classCodesInfo)
    ) {
      let description;
      let returnList = [];
      if (
        classCodesInfo &&
        classCodesInfo[addressChildNo].classCodeDescription &&
        classCodesInfo[addressChildNo].classCodeDescription.value
      ) {
        description = classCodesInfo[addressChildNo].classCodeDescription.value;
        let desc = description.split(":")[1].trim();
        this.setState({ classCodesInfo });
        if (
          desc === "no description found" &&
          !this.state.initialDescApiCalled
        ) {
          this.setState({ initialDescApiCalled: true });
          // const header = await getHeader();
          let value = description.split(":")[0];
          axios
            .get(awsUrl2 + `/api/getCcodes/${state}/${value}`)
            .then((res) => {
              let dbObjsList = res.data.data;
              if (dbObjsList.length === 0) {
                this.setState({ noOptionsMessage: "No data found" });
              }
              dbObjsList.forEach((element) => {
                returnList.push({
                  value: `${element.class_code}: ${element.description}`,
                  label: `${element.class_code}: ${element.description.trim()}`,
                });
              });
              this.setState({ defaultOptionsList: returnList });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              updateLocationError(
                addressBlockNo,
                addressChildNo,
                "classCodeDescription",
                true
              );
            });
        }
      }
    }
    if (this.props.state !== this.state.state) {
      this.setState({
        key: Math.random(),
        state: this.props.state,
        noOptionsMessage: "Enter 3 characters to start",
      });
    }
  };

  handleSelectChange = (selectedOption) => {
    const {
      addressBlockNo,
      addressChildNo,
      handleMinMax,
      updateClassCodesInfo,
      updateLocationError,
    } = this.props;

    updateClassCodesInfo(
      addressBlockNo,
      addressChildNo,
      "classCodeDescription",
      selectedOption.value
    );
    updateClassCodesInfo(
      addressBlockNo,
      addressChildNo,
      "naics",
      selectedOption.naics
    );
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ prevDescription: selectedOption.value });
  };

  loadOptions = async (value, callback) => {
    let minLength = isNaN(value) ? 3 : 1;
    if (value.length >= minLength) {
      let returnList = [];
      const { state } = this.props;
      // const state = "fl";
      const header = await getHeader();
      await axios
        .get(awsUrl2 + `/api/getCcodes/${state}/${value}`, header)
        .then((res) => {
          let dbObjsList = res.data.data;
          if (dbObjsList.length === 0) {
            this.setState({ noOptionsMessage: "No data found" });
          }
          dbObjsList.forEach((element) => {
            returnList.push({
              value: `${element.class_code}: ${element.description}`,
              label: `${element.class_code}: ${element.description.trim()}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return callback(returnList);
    } else {
      this.setState({ noOptionsMessage: "Enter 3 characters to start" });
    }
  };

  customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px",
      border: "1px solid #ababab",
      background: "transparent",
      borderRadius: "4px",
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  customStylesErr = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px",
      border: "1px solid red",
      background: "transparent",
      borderRadius: "4px",
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  render() {
    let {
      noOptionsMessage,
      classCodesInfo,
      key,
      prevDescription,
      dropDownValue,
      defaultOptionsList,
      placeholderText,
    } = this.state;

    let {
      addressBlockNo,
      addressChildNo,
      handleMinMax,
      updateClassCodesInfo,
      updateLocationError,
    } = this.props;
    let descValue;
    let description;
    if (
      classCodesInfo &&
      classCodesInfo[addressChildNo].classCodeDescription &&
      classCodesInfo[addressChildNo].classCodeDescription.value
    ) {
      description = classCodesInfo[addressChildNo].classCodeDescription.value;

      let desc = description.split(":")[1].trim();
      let code = description.split(":")[0].trim();
      if (desc === "no description found") {
        descValue = [
          {
            label: code,
            value: `${code}: ${desc}`,
          },
        ];
        dropDownValue = defaultOptionsList;
      } else {
        descValue = [
          {
            label: description,
            value: description,
          },
        ];
        dropDownValue = descValue;
      }
    } else if (
      classCodesInfo &&
      classCodesInfo[addressChildNo].classCodeDescription &&
      !classCodesInfo[addressChildNo].classCodeDescription.value &&
      prevDescription
    ) {
      description = prevDescription;

      let desc = description.split(":")[1].trim();
      let code = description.split(":")[0].trim();
      if (desc === "no description found") {
        descValue = [
          {
            label: code,
            value: `${code}: ${desc}`,
          },
        ];
        dropDownValue = defaultOptionsList;
      } else {
        descValue = [
          {
            label: description,
            value: description,
          },
        ];
        dropDownValue = descValue;
      }
    }

    return (
      <div
        className="col-lg-6"
        id={`class-code-desc-${addressBlockNo}${addressChildNo}`}
      >
        <Form.Group
          style={
            classCodesInfo &&
            classCodesInfo[addressChildNo].classCodeDescription &&
            classCodesInfo[addressChildNo].classCodeDescription.error
              ? { marginBottom: 0 }
              : {}
          }
        >
          <label title="Worker's Compensation Code">Worker's Comp Code *</label>
          <div
            title={`${
              description ? description : "Enter 3 characters to start"
            }`}
          >
            <AsyncSelect
              key={key}
              value={descValue ? descValue : []}
              className="class-desc-select"
              classNamePrefix="class-desc-select"
              cacheOptions
              defaultOptions={dropDownValue ? dropDownValue : []}
              selectedOption
              onClick={this.loadOptions}
              onFocus={() => {
                this.setState({ placeholderText: "" });

                this.setState({ prevDescription: description }, () => {
                  updateClassCodesInfo(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    undefined
                  );
                });
                handleMinMax(addressBlockNo, addressChildNo);
              }}
              onBlur={() => {
                this.setState({ placeholderText: "Enter 3 characters" });
                if (!prevDescription) return;
                let desc = prevDescription.split(":")[1].trim();

                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "classCodeDescription",
                  prevDescription
                );
                handleMinMax(addressBlockNo, addressChildNo);
                if (desc === "no description found") {
                  updateLocationError(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    true
                  );
                }
              }}
              loadOptions={this.loadOptions}
              noOptionsMessage={() => noOptionsMessage}
              onChange={this.handleSelectChange}
              placeholder={placeholderText}
              styles={
                classCodesInfo &&
                classCodesInfo[addressChildNo].classCodeDescription &&
                classCodesInfo[addressChildNo].classCodeDescription.error
                  ? this.customStylesErr
                  : this.customStyles
              }
            />
          </div>
        </Form.Group>
        {classCodesInfo &&
          classCodesInfo[addressChildNo].classCodeDescription &&
          classCodesInfo[addressChildNo].classCodeDescription.error && (
            <div style={{ color: "red", fontSize: "11px" }}>
              Invalid class code. Please check.
            </div>
          )}
      </div>
    );
  }
}

export default DescriptionCode;
