import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

const AntiPrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn, pathName;
      let groupMap = {
        Admin: "/AdminDashboard",
        Agent: "/AgentDashboard",
        Carrier: "/PanelUW",
      };
      try {
        let sessionData = await Auth.currentAuthenticatedUser();
        let groups;
        if (sessionData) {
          groups =
            sessionData.signInUserSession.accessToken.payload["cognito:groups"];
          isLoggedIn = true;
          if (groups.length > 0) {
            let group = groups[0];
            for (let key in groupMap) {
              if (group.includes(key)) {
                pathName = groupMap[key];
                break;
              }
            }
          }
        }
      } catch (error) {}
      if (isLoggedIn) {
        setPathName(pathName);
        setLoggedInStatus(true);
      }
    }
    checkLoggedInStatus();
  }, []);

  let [isLoggedIn, setLoggedInStatus] = useState(false);
  let [pathname, setPathName] = useState(null);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn || !pathname ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname, state: { from: props.location } }} />
        )
      }
    />
  );
};

export default AntiPrivateRoute;
