import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { awsUrl2 } from "../../../config"
import TabTable from './TabTable';

const ToggleTab = ({ refreshData, setRefreshData }) => {
    const [companyProfile, setCompanyProfile] = useState()
    const [contactInfo, setContactInfo] = useState()
    const [payrollEmod, setPayrollEmod] = useState()
    const [status, setStatus] = useState()



    const getData = async () => {
        try {
            const tableData = await axios.post(awsUrl2 + "/api/excelFileValidator/getTop5Data", { "object_type_list": ["companyProfile", "contactInfo", "payrollEmod", "status"] })
            setCompanyProfile(tableData?.data?.companyProfile)
            setContactInfo(tableData?.data?.contactInfo)
            setPayrollEmod(tableData?.data?.payrollEmod)
            setStatus(tableData?.data?.status)
            setRefreshData(false)
        }
        catch (error) {
            console.log("Error in fetching data", error)
        }
    }
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (refreshData)
            getData()
    }, [refreshData])

    return (
        <Tabs
            defaultActiveKey="companyProfile"
            id="toggleTab"
            className="mb-3"
            justify
        >
            <Tab eventKey="companyProfile" title="Company Profile">
                {companyProfile?.length > 0 ? <TabTable result={companyProfile} /> : <p style={{ textAlign: "center" }}>No Data Found</p>}
            </Tab>
            <Tab eventKey="contact" title="Contact Information">
                {contactInfo?.length > 0 ? <TabTable result={contactInfo} /> : <p style={{ textAlign: "center" }}>No Data Found</p>}
            </Tab>
            <Tab eventKey="payrollEmod" title="Payroll Emod">
                {payrollEmod?.length > 0 ? <TabTable result={payrollEmod} /> : <p style={{ textAlign: "center" }}>No Data Found</p>}
            </Tab>
            <Tab eventKey="status" title="Status">
                {status?.length > 0 ? <TabTable result={status} /> : <p style={{ textAlign: "center" }}>No Data Found</p>}
            </Tab>
        </Tabs>
    );
}

export default ToggleTab;