import React, { Component } from "react";

class IntroVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { height, width } = this.props;
    return (
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        height={height}
        width={width}
        src="https://www.youtube.com/embed/BF9_EdUYa3c"
        frameborder="0"
        title="YouTube video player"
      ></iframe>
    );
  }
}

export default IntroVideo;
