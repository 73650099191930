import React, { useState, useEffect } from "react";
import Banner from "../subcompo/sections-demo/Banner";
import InputForm from "../subcompo/sections-demo/InputForm";
import LoginForm from "../subcompo/sections-login/LoginForm";
import GridBanner from "../subcompo/sections-demo/GridBanner";
import Header from "../common/Header";
import SmallLoader from "../common/SmallLoader";
import { Auth } from "aws-amplify";
import axios from "axios";
import { demoToolUrl } from "../../config";

function DemoTool() {
  let [tableData, updateTableData] = useState([]);
  let [userDetails, updateUserDetails] = useState();
  let [tableDataFetched, updateTableDataFetched] = useState(false);

  useEffect(() => {
    getLoggedInUser();
    getTableData();
  }, []);

  const getTableData = async () => {
    try {
      let tableData = await axios.get(demoToolUrl + "/api/getDemoTableData");
      updateTableData(tableData.data);
      updateTableDataFetched(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRedirect = async (url, attachId) => {
    try {
      let resp = await axios.post(demoToolUrl + "/api/createDemoRedirectUuid");
      let uuid = resp.data;
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      if (attachId) {
        a.href += `&id=${uuid}`;
      }
      a.click();
      a.remove();
    } catch (error) {
      console.log("error", error);
    }
  };

  const createTable = () => {
    let tableRows = [];
    let rowNumber = 0;
    try {
      let res = tableData;
      for (let rowData of res) {
        let row = [];
        let cellNumber = 0;
        rowNumber++;
        for (let cellData of rowData) {
          cellNumber++;
          let isCellColored = (rowNumber + cellNumber) % 2 === 0 ? true : false;
          let header = cellData?.header?.name;
          if (cellData?.header?.url) {
            header = (
              <span
                onClick={() =>
                  handleRedirect(cellData.header.url, cellData.header.attachId)
                }
                className="header-link"
              >
                {header}
              </span>
            );
          }
          row.push(
            <td
              key={`cell-${rowNumber}-${cellNumber}`}
              className={`text-center ${
                !cellData?.links || cellData?.links?.length === 0
                  ? "align-middle"
                  : ""
              }`}
              style={
                isCellColored ? { backgroundColor: "rgb(214, 230, 244)" } : {}
              }
            >
              <span className="cell-header">
                <b>{header}</b>
              </span>
              {cellData?.links?.length > 0 && (
                <ul>
                  {cellData.links.map((link) => (
                    <li key={`list-element-${link.name}`}>
                      <span
                        onClick={() => handleRedirect(link.url, link.attachId)}
                      >
                        {link.name}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </td>
          );
        }
        tableRows.push(<tr key={`row-${rowNumber}`}>{row}</tr>);
      }
    } catch (error) {
      console.log("error", error);
    }

    let table = <table className="table table-bordered">{tableRows}</table>;

    return table;
  };

  const getLoggedInUser = async () => {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        updateUserDetails(currentUser);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  let userGroups =
    userDetails?.signInUserSession?.idToken?.payload?.["cognito:groups"];

  return (
    <>
      <div className="demo-container d-flex flex-column align-items-center">
        <div className="d-flex header-container w-100">
          <Header />
        </div>
        <div className="banner-container w-100">
          <Banner />
        </div>
        <div className="form-section-container">
          <InputForm />
        </div>
        {userDetails && userGroups?.includes("DemoAdmin") ? (
          <>
            <div className="grid-banner-container">
              <GridBanner />
            </div>
            {tableDataFetched ? (
              <div className="table-container">{createTable()}</div>
            ) : (
              <SmallLoader />
            )}
          </>
        ) : ""
          // <div className="demo-login-container">
          //   <LoginForm getLoggedInUser={getLoggedInUser} />
          // </div>
        }
      </div>
    </>
  );
}

export default DemoTool;
