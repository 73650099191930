import React, { Component } from "react";
import AboutSection from "../subcompo/sections-home/AboutSection";
import Footer from "../subcompo/sections-home/Footer";
import Header from "../common/Header";

class ProgramInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="d-flex mb-5">
          <Header />
        </div>
        <div className="mb-5">
          <AboutSection />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ProgramInfo;
