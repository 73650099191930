import React, { useEffect, forwardRef, useState } from 'react'
import MaterialTable from 'material-table'
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import moment from "moment"
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
// import { logout } from "../../utils/common";

const icons = {
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

const NewDomain = () => {
    const [rowList, setRowList] = useState([])
    const [loader, setLoader] = useState()
    let columns = [
        {
            title: "Domain",
            field: "domain",
            cellStyle: {
                padding: "6px 15px 6px 10px",
                minWidth: "110px",
                width: "110px",
                maxWidth: "110px",
            },
            render: (rowData) => (
                <div>
                    {rowData.domain}
                </div>
            ),
        },
        {
            title: "Date",
            field: "date",
            cellStyle: {
                padding: "6px 15px 6px 10px",
                minWidth: "110px",
                width: "110px",
                maxWidth: "110px",
            },
            render: (rowData) => {
                let dateReadable = moment(Number(rowData.date), "x").format("MM-DD-YYYY")
                return <div>
                    {dateReadable}
                </div>
            }
        },
    ]


    useEffect(() => {
        setLoader(true)
        axios.post(awsUrl2 + "/api/domainChecker", { "days": "30" }).then(response => {
            setRowList(response.data)
            setLoader(false)
        })
            .catch(error => {
                console.log("Error in fetching domainChecker", error)
                setLoader(false)
            })
    }, [])
    return (
        <div>
            <Header />
            <MaterialTable
                isLoading={loader}
                data={rowList}
                icons={icons}
                columns={columns}
                options={{
                    paginationPosition: "both",
                    padding: "dense",
                    showTitle: false,
                    thirdSortClick: false,
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 75, 100],
                    headerStyle: {
                        fontSize: "12px",
                        backgroundColor: "#003764",
                        color: "#FFF",
                        padding: "6px 15px 6px 10px",
                    },
                    rowStyle: {
                        fontSize: "12px",
                    },
                    searchFieldStyle: {
                        fontSize: "12px",
                    },
                }}
                components={{
                    Pagination: (props) => (
                        <TablePagination
                            {...props}
                            labelRowsPerPage={
                                <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                            }
                            labelDisplayedRows={(row) => (
                                <div style={{ fontSize: 12 }}>
                                    {props.labelDisplayedRows(row)}
                                </div>
                            )}
                            SelectProps={{
                                style: {
                                    fontSize: 12,
                                },
                            }}
                        />
                    ),
                }}

            />



        </div>
    )

}
export default NewDomain