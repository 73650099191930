import React, { Component } from "react";
import howItWorksPdf from "../../../static/Affinity Group PEO Solutions Overview.pdf";
import GetYourQuote from "../../subcompo/sections-home/GetYourQuote";
import new_logo from "../../../images/new-logo.png";
import logo_ipeo from "../../../images/logo-iPEO.png";
import facebook_icon from "../../../images/facebook.svg";
import linkedin_icon from "../../../images/linkedin.svg";
import instagram_icon from "../../../images/instagram.svg";
import nepoelogo from "../../../images/Nepeo.jpeg";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleScrollToElement = (refName, offset = 0) => {
    window.scrollTo({
      behavior: "smooth",
      top: refName.current.offsetTop - offset,
    });
  };

  render() {
    const { servicesRef, whoWeServeRef, aboutRef, videoRef } = this.props;
    return (
      <div className="page-footer">
        <div className="upper-footer d-flex">
          <div className="section-1 px-4 d-flex flex-column">
            <div className="partners-header">Our Partners</div>
            <div className="partners-image-container d-flex">
              <div className="partners-image">
                <a href="http://www.insurecomp.com" target="_blank">
                  <img
                    className="insurecomp-logo"
                    src={new_logo}
                    alt="insurecomp-logo"
                  />
                </a>
              </div>
              <div className="partners-image">
                <a href="http://www.ipeosolutions.com" target="_blank">
                  <img className="ipeo-logo" src={logo_ipeo} alt="ipeo-logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="section-2 d-flex w-100 px-4">
            <div>
              <div
                className="footer-item align-self-center"
                onClick={() => this.handleScrollToElement(aboutRef, 220)}
              >
                About Affinity Group
              </div>
              <div
                className="footer-item align-self-center"
                onClick={() => this.handleScrollToElement(servicesRef, 150)}
              >
                Services
              </div>
              <div
                className="footer-item align-self-center"
                onClick={() => this.handleScrollToElement(whoWeServeRef, 200)}
              >
                Who We Serve
              </div>
            </div>
            <div>
              <div className="footer-item align-self-center">
                <a href={howItWorksPdf} target="_blank">
                  How it Works
                </a>
              </div>
              <div
                className="footer-item align-self-center"
                onClick={() => this.handleScrollToElement(videoRef, 140)}
              >
                Video
              </div>
            </div>
          </div>
          <div className="section-4 px-4">
            <div className="d-flex flex-column">
              <div className="partners-header">OUR AFFILIATIONS</div>
              <div className="partners-image">
                <img
                  className="ipeo-logo w-100"
                  src={nepoelogo}
                  alt="npeologo"
                />
              </div>
            </div>
          </div>
          <div className="section-3 d-flex flex-column px-4">
            <div className="footer-item align-self-center text-center">
              <GetYourQuote />
            </div>
            <div className="socials d-flex mt-3">
              <a
                href="https://www.facebook.com/affinitygrouppeo"
                target="_blank"
              >
                <img
                  className="social-icon"
                  src={facebook_icon}
                  alt="facebook icon"
                ></img>
              </a>
              <a
                href="https://www.instagram.com/affinitygrouppeo/"
                target="_blank"
              >
                <img
                  className="social-icon"
                  src={instagram_icon}
                  alt="instagram icon"
                ></img>
              </a>
              <a
                href="https://www.linkedin.com/showcase/75666677/admin/\"
                target="_blank"
              >
                <img
                  className="social-icon"
                  src={linkedin_icon}
                  alt="linkedin icon"
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-footer d-flex">
          <div className="text-center w-100"></div>
          <div className="text-center w-100">
            &copy;2022 All Rights Reserved
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
