import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { awsUrl2 } from "../../config";
import ContactDetails from "../subcompo/sections-quote/ContactDetails";
import AsyncSelect from "react-select/async";
import { getHeader } from "../../utils/common";
import DropZones from "../common/DropZones";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import Swal from "sweetalert2";
import {
  DropButton,
  UploadDashboardFile,
} from "../subcompo/dashboard-components/UploadDashboardFile";

import CustomDropZone from "../subcompo/dashboard-components/CustomDropZone";
import { DownloadDashboardFiles } from "../subcompo/dashboard-components/DownloadDashboardFiles";
import { logout } from "../../utils/common";
import debounce from "../../utils/optimize_api";
import { set } from "d3";
import { object } from "react-dom-factories";
import { data } from "jquery";
const uuidv4 = require("uuid/v4");
let stateCity = require("../../utils/state_city.json");

const Preuwstageonepage = () => {
  const [containerCloseOpen, setContainerCloseOpen] = useState({
    showCompanyProfile: true,
    showContactDetails: true,
    showAddtionalContactDetails: false,
    showLocationAndPayroll: true,
  });
  const [sameAsPrimary, setSameAsPrimary] = useState(false);
  const [
    payrollandLocationSameASPayroll,
    setPayrollandLocationSameASPayroll,
  ] = useState({});
  const [locSameAsPrimary, setlocSameAsPrimary] = useState(false);
  const [showSubmittedMessage, setShowSubmittedMessage] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [websiteButtonShow, setWebsiteButtonShow] = useState(true);
  const [companyProfile, setCompanyProfile] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const [childrenLoc, setChildrenLoc] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginUserName, setLoginUserName] = useState("");
  const [isdomain, setIsdomain] = useState(false);
  const [isSourcePresent, setIsSourcePresent] = useState(false);
  const [CheckDomainExistinDb, SetcheckDomainExistinDb] = useState(false);
  const [
    CheckDomainNameSpecialCharacter,
    setcheckDomainNameSpecialCharacter,
  ] = useState(false);

  useEffect(() => {
    userlogin();
    setinputData();
  }, []);

  // get userName and login details
  const userlogin = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_AGPSAGENT_GROUP)) {
          setIsLoggedIn(true);
          setLoginUserName(userName);
        }
        if (groups.includes(process.env.REACT_APP_AGPSADMIN_GROUP)) {
          setIsLoggedIn(true);
          setLoginUserName(userName);
        }
      }
    } catch (error) {}
  };

  // update the field on mount of component
  const setinputData = async () => {
    // gettng the compnay profile informaton form session storage
    try {
      let companyProfile;
      if (sessionStorage.getItem("companyProfile"))
        companyProfile = JSON.parse(sessionStorage.getItem("companyProfile"));
      // setting the Expected Start data .
      if (companyProfile) {
        setIsdomain(true);
        setIsSourcePresent(true);
      } else {
        setIsdomain(false);
        setIsSourcePresent(false);
      }

      if (companyProfile) {
        companyProfile.expectedStartDate.value = moment(
          companyProfile.expectedStartDate.value
        );
      }

      if (!companyProfile) {
        companyProfile = {
          companyName: { value: "" },
          descriptionOfOperations: { value: "" },
          domain: { value: "" },
          destination: { value: [] },
          address: { value: "" },
          city: { value: "" },
          zip: { value: "" },
          state: { value: "" },
          phoneNumber: { value: "" },
          yearsInBusiness: { value: "" },
          street1: { value: "" },
          zipCode1: { value: "" },
          cityName1: { value: "" },
          state1: { value: "" },
          street2: { value: "" },
          zipCode2: { value: "" },
          cityName2: { value: "" },
          state2: { value: "" },
          fein: { value: "" },
          entityType: { value: "" },
          website: { value: "" },
          source: { value: "" },
        };
      }
      setCompanyProfile({ ...companyProfile });
    } catch (error) {
      console.log(error);
    }
    // Checking state for same primary location
    let companyProfile;
    if (sessionStorage.getItem("companyProfile"))
      companyProfile = JSON.parse(sessionStorage.getItem("companyProfile"));
    if (
      companyProfile?.street1?.value &&
      companyProfile?.zipCode1?.value &&
      companyProfile?.cityName1?.value &&
      companyProfile?.state1?.value
    ) {
      if (
        companyProfile?.street1?.value === companyProfile?.street2?.value &&
        companyProfile?.zipCode1?.value === companyProfile?.zipCode2?.value &&
        companyProfile?.cityName1?.value === companyProfile?.cityName2?.value &&
        companyProfile?.state1?.value === companyProfile?.state2?.value
      ) {
        setSameAsPrimary(true);
      } else {
        setSameAsPrimary(false);
      }
    } else {
      setSameAsPrimary(false);
    }

    // gettng the contact informaton form session storage
    try {
      let email_specific;
      if (sessionStorage.getItem("contactDetails"))
        email_specific = JSON.parse(sessionStorage.getItem("contactDetails"));
      // want to send key as integer was getting email as key. so prepared the key to integer using reduce method
      if (email_specific) {
        const newContactDetails = Object.entries(email_specific).reduce(
          (acc, [key, value], index) => {
            acc[index] = value;
            index++;
            return acc;
          },
          {}
        );

        setContactDetails({ ...newContactDetails });
        const info_count = Object.keys(newContactDetails).length;

        for (let i = 0; i < info_count; i++) {
          setContactDetails((values) => ({
            ...values,
            [i]: {
              ...values[i],
              ["companyProfile"]: {
                ...values[i]["companyProfile"],
                ["disable_check"]: true,
              },
            },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }

    //making first row of contact info madatory
    try {
      let email_specific;
      let index;
      if (sessionStorage.getItem("contactDetails")) {
        email_specific = JSON.parse(sessionStorage.getItem("contactDetails"));
        const keys = Object.keys(email_specific);
        index = keys.length;
      } else {
        index = 0;
      }

      if (index === 0) {
        setContactDetails({
          ...contactDetails,
          [0]: {
            companyProfile: {
              company_name: { value: companyProfile?.companyName?.value || "" },
              contact_name: {},
              emailId: {},
              job_title: {},
              phoneNumber: {},
              source: {},
              state: {},
            },
          },
        });
      }
    } catch (error) {
      console.log(
        "Error fetch contact details from Session Storage and setting it."
      );
    }

    //getting the location and payroll from session storage
    let childrenLoc;
    try {
      if (sessionStorage.getItem("childrenLoc"))
        childrenLoc = JSON.parse(sessionStorage.getItem("childrenLoc"));
      if (childrenLoc) {
        for (const obj of Object.values(childrenLoc)) {
          for (const key of Object.values(obj.classCodesInfo)) {
            // Get the classCodeDescription value
            const classCodeDescValue = key.classCodeDescription.value;

            // Add a new key-value pair to the dictionary
            key.classCodeDescription.label = classCodeDescValue;
          }
        }

        setChildrenLoc({ ...childrenLoc });
      }
    } catch (error) {
      console.log("Error while getting the classcode");
    }

    // checking same primary location in payroll feil
    let companyprofileCheck;
    if (
      companyProfile?.street1?.value &&
      companyProfile?.zipCode1?.value &&
      companyProfile?.cityName1?.value &&
      companyProfile?.state1?.value
    ) {
      companyprofileCheck = true;
    } else {
      companyprofileCheck = false;
    }

    if (childrenLoc && companyProfile) {
      let primaryKey = ["cityName", "street", "state", "zipCode"];
      let sameAsPrimary = true;
      for (let key of primaryKey) {
        if (
          companyProfile?.[`${key}1`]?.value !==
          Object.entries(childrenLoc)[0][1]?.[`${key}`]?.value
        ) {
          sameAsPrimary = false;
          break;
        }
      }
      setlocSameAsPrimary(sameAsPrimary);
    } else {
      setlocSameAsPrimary(false);
    }

    // getting the files of agps uploaded if any  present.
    try {
      let agpsFiles = JSON.parse(sessionStorage.getItem("agpsFiles"));
    } catch (error) {
      console.log(error);
    }
  };

  // setting the options of destination in company profile page
  const updateDestinationHandler = (event, name) => {
    const value = event;
    setCompanyProfile((values) => ({ ...values, [name]: { value } }));
  };

  // update the state of companyprofile.
  const updateCompanyProfile = (event, name) => {
    let value = event?.target?.value;
    value = value?.trimStart();
    value = value?.replace(/\s+/g, " ");
    if (name === "domain1") {
      name = "domain";
      value = event?.toLowerCase();
    }
    setCompanyProfile((values) => ({ ...values, [name]: { value } }));
  };

  // update the state of  contact details.
  const updateContactDetails = (event, specificEmailKey, name) => {
    const value = event.target.value;
    setContactDetails((values) => ({
      ...values,
      [specificEmailKey]: {
        ...values[specificEmailKey],
        ["companyProfile"]: {
          ...values[specificEmailKey]["companyProfile"],
          [name]: { value },
        },
      },
    }));

    if (name === "phoneNumber") {
      setCompanyProfile((values) => ({ ...values, [name]: { value } }));
    }
  };

  // update payroll and locaiton street location
  const updateLocationAndPayroll = (event, parentLocation, name) => {
    const value = event.target.value;
    setChildrenLoc((prevState) => ({
      ...prevState,
      [parentLocation]: {
        ...prevState[parentLocation],
        [name]: { value },
      },
    }));
  };

  // update payroll and location parttime, fulltime and payroll detials in state
  const updateWorkerCompRow = (event, parentLocation, childLocation, name) => {
    let value = event.target.value;

    setChildrenLoc((prevState) => ({
      ...prevState,
      [parentLocation]: {
        ...prevState[parentLocation],
        ["classCodesInfo"]: {
          ...prevState[parentLocation]["classCodesInfo"],
          [childLocation]: {
            ...prevState[parentLocation]["classCodesInfo"][childLocation],
            [name]: { value },
          },
        },
      },
    }));
  };

  const updateWorkerCompDesc = (event, parentLocation, childLocation, name) => {
    let value = event.value;
    let label = event.label;

    setChildrenLoc((prevState) => ({
      ...prevState,
      [parentLocation]: {
        ...prevState[parentLocation],
        ["classCodesInfo"]: {
          ...prevState[parentLocation]["classCodesInfo"],
          [childLocation]: {
            ...prevState[parentLocation]["classCodesInfo"][childLocation],
            [name]: { label, value },
          },
        },
      },
    }));
  };

  //update the expected start date in companyprofile
  const handleDateChangeEff = (date) => {
    if (date) {
      companyProfile.expectedStartDate.value = date;
      companyProfile.effectiveDate.value = date;
      setCompanyProfile({
        ...companyProfile,
      });
    }
  };

  // updating the company profile zipcode primary location
  const setStateAndCity1 = (zipCode) => {
    if (!zipCode) return;
    axios
      .get(awsUrl2 + "/api/getStateAndCity/" + zipCode)
      .then((res) => {
        const data = res.data;
        if (data !== `"Data not found"`) {
          setCompanyProfile({
            ...companyProfile,
            state: { value: data.state },
            city: { value: data.city },
            state1: { value: data.state },
            cityName1: { value: data.city },
          });
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
      });
  };

  // updating the company profile zipcode mailing address
  const setStateAndCity2 = (zipCode) => {
    if (!zipCode) return;
    axios
      .get(awsUrl2 + "/api/getStateAndCity/" + zipCode)
      .then((res) => {
        const data = res.data;
        if (data !== `"Data not found"`) {
          setCompanyProfile({
            ...companyProfile,
            state2: { value: data.state },
            cityName2: { value: data.city },
          });
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
      });
  };

  // updating the location and payroll location.
  const setStateAndCity3 = (zipCode, locationNumber) => {
    if (!zipCode) return;
    axios
      .get(awsUrl2 + "/api/getStateAndCity/" + zipCode)
      .then((res) => {
        const data = res.data;
        if (data !== `"Data not found"`) {
          setChildrenLoc((prevState) => ({
            ...prevState,
            [locationNumber]: {
              ...prevState[locationNumber],
              state: {
                value: data.state,
              },
              cityName: { value: data.city },
            },
          }));
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
      });
  };

  // bring all the state when user type zipcode for primary location
  let allStates1 = [
    <option key={0} value="">
      State
    </option>,
  ];
  for (var item of stateCity) {
    if (
      companyProfile &&
      item ===
        (companyProfile.state1 && companyProfile.state1.value
          ? companyProfile.state1.value
          : "")
    ) {
      allStates1.push(
        <option key={item.Code} value={item.Code.toLowerCase()} selected>
          {item.Code}
        </option>
      );
    } else {
      allStates1.push(
        <option key={item.Code} value={item.Code.toLowerCase()}>
          {item.Code}
        </option>
      );
    }
  }
  // bring all the state when user type zipcode for mailing address
  let allStates2 = [
    <option key={0} value="">
      State
    </option>,
  ];
  for (var item of stateCity) {
    if (
      companyProfile &&
      item ===
        (companyProfile.state2 && companyProfile.state2.value
          ? companyProfile.state2.value
          : "")
    ) {
      allStates2.push(
        <option key={item.Code} value={item.Code.toLowerCase()} selected>
          {item.Code}
        </option>
      );
    } else {
      allStates2.push(
        <option key={item.Code} value={item.Code.toLowerCase()}>
          {item.Code}
        </option>
      );
    }
  }

  // auto filling the mailing address as primary address

  const handleSameAsPrimaryCheck = () => {
    if (sameAsPrimary) {
      setSameAsPrimary(false);
      setCompanyProfile({
        ...companyProfile,
        cityName2: { value: "" },
        state2: { value: "" },
        zipCode2: { value: "" },
        street2: { value: "" },
      });
    } else {
      setSameAsPrimary(true);
      setCompanyProfile({
        ...companyProfile,
        cityName2: {
          value:
            companyProfile?.["cityName1"]?.["value"] ||
            companyProfile?.["city"]?.["value"],
        },
        state2: {
          value:
            companyProfile?.["state1"]?.["value"] ||
            companyProfile?.["state"]?.["value"],
        },
        zipCode2: {
          value:
            companyProfile?.["zipCode1"]?.["value"] ||
            companyProfile?.["zip"]?.["value"],
        },
        street2: {
          value:
            companyProfile?.["street1"]?.["value"] ||
            companyProfile?.["address"]?.["value"],
        },
      });
    }
  };

  // same as primary location for location and payroll
  const locationAndPayrollSameAsPrimaryLoc = (locationNumber) => {
    if (payrollandLocationSameASPayroll[locationNumber]) {
      setPayrollandLocationSameASPayroll((values) => ({
        ...values,
        [locationNumber]: false,
      }));
      setChildrenLoc({
        ...childrenLoc,
        [locationNumber]: {
          ...childrenLoc[locationNumber],
          cityName: { value: "" },
          state: { value: "" },
          zipCode: { value: "" },
          street: { value: "" },
        },
      });
      setlocSameAsPrimary(false);
    } else {
      setPayrollandLocationSameASPayroll((values) => ({
        ...values,
        [locationNumber]: true,
      }));
      setlocSameAsPrimary(true);
      setChildrenLoc({
        ...childrenLoc,
        [locationNumber]: {
          ...childrenLoc[locationNumber],

          cityName: {
            value:
              companyProfile?.["cityName1"]?.["value"] ||
              companyProfile?.["city"]?.["value"],
          },
          state: {
            value:
              companyProfile?.["state1"]?.["value"] ||
              companyProfile?.["state"]?.["value"],
          },
          zipCode: {
            value:
              companyProfile?.["zipCode1"]?.["value"] ||
              companyProfile?.["zip"]?.["value"],
          },
          street: {
            value:
              companyProfile?.["street1"]?.["value"] ||
              companyProfile?.["address"]?.["value"],
          },
        },
      });
    }
  };

  // adding the extra contact detials on click of add contact details button

  const showAddtionalContactDetailsHandler = () => {
    const keys = Object.keys(contactDetails);
    let index;

    if (keys.length === 0) {
      index = 0; // If there are no keys in the classCodesInfo object, set the index to 0
    } else {
      index = Math.max(...keys.map((key) => parseInt(key))) + 1; // Calculate the next available index by finding the highest key value and adding 1 to it
    }

    setContactDetails({
      ...contactDetails,
      [index]: {
        companyProfile: {
          company_name: { value: companyProfile.companyName.value },
          contact_name: {},
          emailId: {},
          job_title: {},
          phoneNumber: {},
          source: {},
          state: {},
        },
      },
    });
  };

  const checkSessionStorage = (emailToDelete) => {
    const existingContactDetailsString = sessionStorage.getItem(
      "contactDetails"
    );
    if (existingContactDetailsString) {
      let existingContactDetails = JSON.parse(existingContactDetailsString);
      if (existingContactDetails.hasOwnProperty(emailToDelete)) return true;
    }
    return false;
  };

  const updateSessionStorage = (emailToDelete) => {
    const existingContactDetailsString = sessionStorage.getItem(
      "contactDetails"
    );
    if (existingContactDetailsString) {
      let existingContactDetails = JSON.parse(existingContactDetailsString);
      if (existingContactDetails.hasOwnProperty(emailToDelete)) {
        delete existingContactDetails[emailToDelete];
        sessionStorage.setItem(
          "contactDetails",
          JSON.stringify(existingContactDetails)
        );
      }

      console.log(`Key '${emailToDelete}' deleted from contactDetails.`);
    }
  };

  // Delete the Selected Contact Details
  const deleteContactDetailsHandler = async (specificEmailKey) => {
    let contactDetailsObject = Object.values(contactDetails);
    let emailToDelete =
      contactDetailsObject[specificEmailKey]?.["companyProfile"]?.emailId
        ?.value;
    if (!emailToDelete || emailToDelete.trim().length <= 0) {
      const updatedContactDetails = { ...contactDetails };
      delete updatedContactDetails[specificEmailKey];
      setContactDetails({ ...updatedContactDetails });
      return;
    }

    if (!checkSessionStorage(emailToDelete)) {
      const updatedContactDetails = { ...contactDetails };
      delete updatedContactDetails[specificEmailKey];
      setContactDetails({ ...updatedContactDetails });
      return;
    }
    let domain;
    if (sessionStorage.getItem("domain"))
      domain = JSON.parse(sessionStorage.getItem("domain"));
    Swal.fire({
      title: "Are you sure?",
      text: "Email data will be deleted permanently.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (emailToDelete) {
          console.log(emailToDelete, "Email to be deleted");
          await axios
            .post(awsUrl2 + "/api/deleteEmail", {
              emailToDelete,
              domain,
            })
            .then((res) => {
              if (res.status === 200) {
                console.log("Email deleted from db");
                updateSessionStorage(emailToDelete);
                const updatedContactDetails = { ...contactDetails };
                delete updatedContactDetails[specificEmailKey];
                setContactDetails({ ...updatedContactDetails });
                Swal.fire({
                  title: "Deleted!",
                  text: `${emailToDelete} is successfully deleted`,
                  icon: "success",
                });
              } else {
                throw new Error(res.body);
              }
            })
            .catch((error) => {
              console.log("error:", error);
              Swal.fire({
                title: "Error!",
                text: `Error in deleting ${emailToDelete}`,
                icon: "error",
              });
            });
        }
      } else {
        console.log("No Data to delete!!");
      }
    });
  };

  //  adding the new row to add the new location

  const addNewLocationHandler = () => {
    const keys = Object.keys(childrenLoc);
    let index;

    if (keys.length === 0) {
      index = 0; // If there are no keys in the classCodesInfo object, set the index to 0
    } else {
      index = Math.max(...keys.map((key) => parseInt(key))) + 1; // Calculate the next available index by finding the highest key value and adding 1 to it
    }

    console.log(index);

    setChildrenLoc({
      ...childrenLoc,
      [index]: {
        street: {},
        zipCode: {},
        cityName: {},
        state: {},
        number: index,
        classCodesInfo: {
          0: {
            classCodeDescription: {},
            ft: {},
            pt: {},
            preuwPhone: {},
            payroll: {},
          },
        },
      },
    });
  };

  //deleteAdditionalLocationHandler is used to delete the additional location

  const deleteAdditionalLocationHandler = (locationNumber) => {
    setChildrenLoc((prevState) => {
      const newState = { ...prevState };
      delete newState[locationNumber];

      // Reassign keys to start from 0
      const resetState = {};
      let i = 0;
      for (const key in newState) {
        resetState[i] = newState[key];
        i++;
      }

      return resetState;
    });
  };

  //

  //onblur email check present on Db and local State
  const emailValidatecheck = async (value, specificEmailKey, name) => {
    const object_values = Object.values(contactDetails);

    //Checking for Duplicate email in group
    const allemail = object_values.map(
      (data) => data["companyProfile"]?.["emailId"]?.value
    );
    allemail.splice(specificEmailKey, 1);
    let state_email = allemail.includes(value);

    //Checking for Email alerady exsist in group
    let dbEmail_check;
    await axios
      .post(awsUrl2 + "/api/checkEmailExistDb", value)
      .then((res) => {
        dbEmail_check = res.data;
      })
      .catch((error) => {
        console.log("error:", error);
      });

    let emailExistError = false;
    if (dbEmail_check) {
      emailExistError = true;
    } else {
      emailExistError = false;
    }

    //Checking for valid email
    let validEmailError;
    if (value.length > 0) {
      const email_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      validEmailError = !email_pattern.test(value);
    } else {
      validEmailError = false;
    }

    setContactDetails((values) => ({
      ...values,
      [specificEmailKey]: {
        ...values[specificEmailKey],
        ["companyProfile"]: {
          ...values[specificEmailKey]["companyProfile"],
          ["contactError"]: { value: emailExistError },
          ["validemailError"]: { value: validEmailError },
          ["duplicateError"]: { value: state_email },
        },
      },
    }));
  };

  // addign the additional classcode row on specific location
  const addNewClassCodeHandler = (locatioNumber) => {
    const keys = Object.keys(childrenLoc[locatioNumber]["classCodesInfo"]);
    let index;

    if (keys.length === 0) {
      index = 0; // If there are no keys in the classCodesInfo object, set the index to 0
    } else {
      index = Math.max(...keys.map((key) => parseInt(key))) + 1; // Calculate the next available index by finding the highest key value and adding 1 to it
    }

    console.log("index: ", index);
    let parentLocation = locatioNumber;

    setChildrenLoc((prevState) => ({
      ...prevState,
      [parentLocation]: {
        ...prevState[parentLocation],
        ["classCodesInfo"]: {
          ...prevState[locatioNumber]["classCodesInfo"],
          [index]: {
            classCodeDescription: {},
            ft: {},
            pt: {},
            preuwPhone: {},
            payroll: {},
          },
        },
      },
    }));
  };

  //delete the added new classcode using the add classcode handler.

  const deleteClasscodeHandler = (locationNumber, addressLocation) => {
    setChildrenLoc((prevState) => {
      const newState = { ...prevState };

      // Remove the specified classCodesInfo object
      delete newState[locationNumber]["classCodesInfo"][addressLocation];

      // Reassign keys to start from 0
      const resetState = { ...newState[locationNumber] };
      resetState.classCodesInfo = {};
      let i = 0;
      for (const key in newState[locationNumber]["classCodesInfo"]) {
        resetState.classCodesInfo[i] =
          newState[locationNumber]["classCodesInfo"][key];
        i++;
      }

      newState[locationNumber] = resetState;

      return newState;
    });
  };

  // loading the wokers's comp descriptions
  const loadOptions = async (value, callback, state) => {
    state = state?.toLowerCase();
    let minLength = isNaN(value) ? 3 : 1;
    if (value.length >= minLength) {
      let returnList = [];

      // Replace this with your actual API request logic
      const header = await getHeader();
      await axios
        .get(awsUrl2 + `/api/getCcodes/${state}/${value}`, header)
        .then((res) => {
          let dbObjsList = res.data.data;
          dbObjsList.forEach((element) => {
            returnList.push({
              value: `${element.class_code}: ${element.description}`,
              label: `${element.class_code}: ${element.description?.trim()}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });

      return callback(returnList);
    }
  };
  const debouncedLoadOptions = debounce((inputValue, callback, state) => {
    loadOptions(inputValue, callback, state);
  });

  // check for validation company profile
  const checkCompanyProfileError = () => {
    const fieldList = [
      "companyName",
      "descriptionOfOperations",
      "street1",
      "zipCode1",
      "cityName1",
      "state1",
      "street2",
      "zipCode2",
      "cityName2",
      "state2",
      "yearsInBusiness",
      "fein",
      "entityType",
      "website",
      "source",
      "destination",
    ];

    let error = false;
    let field;
    for (let fieldName of fieldList) {
      try {
        if (
          !companyProfile[fieldName] ||
          !companyProfile[fieldName]?.value ||
          companyProfile[fieldName]?.value === "Please Select" ||
          companyProfile[fieldName]?.error
        ) {
          companyProfile[fieldName].error = true;
          if (!field) field = fieldName;
          error = true;
        }
        if (fieldName === "destination") {
          if (companyProfile?.destination?.value.length === 0) {
            companyProfile[fieldName].error = true;
            if (!field) field = fieldName;
            error = true;
          }
        }
      } catch (error) {
        console.log("values are not getting ");
      }
    }
    if (companyProfile?.destination?.value.length === 0) {
      companyProfile["destination"].error = true;
      if (!field) field = "destination";
      error = true;
    }
    setCompanyProfile({ ...companyProfile });
    console.log("one:------>", [error, field]);
    console.log("two:------>", companyProfile);

    return [error, field];
  };
  // check for contact info Validation
  const checkContactInfoError = () => {
    const fieldList = ["contact_name", "emailId", "job_title", "phoneNumber"];
    let error = false;
    let field;
    for (let index = 0; index < Object.keys(contactDetails).length; index++) {
      for (let fieldName of fieldList) {
        try {
          if (
            !contactDetails[index]["companyProfile"][fieldName] ||
            !contactDetails[index]["companyProfile"][fieldName]?.value ||
            contactDetails[index]["companyProfile"][fieldName]?.error ||
            contactDetails[index]["companyProfile"]?.["validemailError"]
              ?.value ||
            contactDetails[index]["companyProfile"]?.["duplicateError"]
              ?.value ||
            contactDetails[index]["companyProfile"]?.["contactError"]?.value ||
            contactDetails[index]["companyProfile"][fieldName]?.value.trim()
              .length === 0
          ) {
            contactDetails[index]["companyProfile"][fieldName].error = true;
            if (!field) field = fieldName;
            error = true;
          }
        } catch (err) {
          console.log("values are not getting ");
        }
      }
    }

    setContactDetails({ ...contactDetails });
    return [error, field];
  };
  // check validation for location and payroll
  const checkLocationInfoError = () => {
    const fieldList = ["street", "zipCode", "cityName", "state"];
    let error = false;
    let field;
    for (let key in childrenLoc) {
      for (let fieldName of fieldList) {
        try {
          if (
            !childrenLoc[key][fieldName] ||
            !childrenLoc[key][fieldName]?.value ||
            childrenLoc[key][fieldName]?.error
          ) {
            childrenLoc[key][fieldName].error = true;
            if (!field) field = fieldName;
            error = true;
          }
        } catch (error) {
          console.log("values are not getting ");
        }
      }
    }
    setChildrenLoc({ ...childrenLoc });
    return [error, field];
  };

  // check is domain is already exist or not
  const checkDominExistInDatabaseHandler = async (value) => {
    if (value) {
      const isValid = /^[A-Za-z0-9]+$/.test(value);
      if (isValid) {
        setcheckDomainNameSpecialCharacter(false);
        await axios
          .post(awsUrl2 + "/api/CheckDomainExistInDatabase", value)
          .then((res) => {
            console.log(res);
            SetcheckDomainExistinDb(res.data);
          })
          .catch((error) => {
            console.log("error:", error);
          });
      } else {
        setcheckDomainNameSpecialCharacter(true);
      }
    }
  };
  // submit the form to database after validation
  const submitHandler = async (saveOrSubmit) => {
    //check for errors
    let [companyProfileError, companyfield] = checkCompanyProfileError();
    let [contactInforError, contactInfoFeild] = checkContactInfoError();
    let [locationInfoError, locationInfoField] = checkLocationInfoError();
    try {
      if (companyProfileError) {
        document.getElementById(`preuw_${companyfield}`).focus();
        setSubmitError(true);
        Swal.fire({
          icon: "error",
          title: "Error in Data",
          text: "Please fill all the mandatory fields!!",
        });
        return;
      } else if (locationInfoError) {
        document
          .getElementById(`preuw_${locationInfoField}`)
          .scrollIntoView({ behavior: "smooth" });
        setSubmitError(true);
        Swal.fire({
          icon: "error",
          title: "Error in Data",
          text: "Please fill all the mandatory feilds!!",
        });
        return;
      } else if (contactInforError) {
        //document.getElementById(`preuw_${contactInfoFeild}`).focus();
        setSubmitError(true);
        Swal.fire({
          icon: "error",
          title: "Error in Data",
          text: "Please fill all the mandatory feilds!!",
        });
        return;
      }
    } catch (error) {}
    let destinationErrorCheck = checkCompanyProfileError()[1];
    let destinationError = checkCompanyProfileError()[0];
    if (
      destinationError === "destination" &&
      destinationErrorCheck === "true"
    ) {
      setSubmitError(true);
    }
    setSubmitError(false);
    setShowSubmittedMessage(false);
    let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag")
      ? true
      : false;
    let domain =
      JSON.parse(sessionStorage.getItem("domain")) ||
      companyProfile?.companyName?.domain;

    if (domain) {
      domain = domain;
    } else {
      domain = await uuidv4();
      updateCompanyProfile(domain, "domain1");
      setIsdomain(true);
      setIsSourcePresent(false);
    }
    let agpsuserName = loginUserName;
    let agpsSubmittedDate = Math.floor(Date.now()).toString();

    const email_specific = Object.values(contactDetails).reduce((acc, curr) => {
      const email = curr.companyProfile.emailId.value;
      acc[email] = curr;
      return acc;
    }, {});

    let all_email = Object.keys(email_specific);
    let email_exist = [];
    let emailobjecttoSend = [];

    for (let i = 0; i < all_email.length; i++) {
      emailobjecttoSend.push({
        domain,
        email: all_email[i],
      });
    }

    if (emailobjecttoSend.length > 0) {
      await axios
        .post(awsUrl2 + "/api/insertEmail", emailobjecttoSend)
        .then((res) => {
          console.log("Data Inserted Sucessfully in domain mapping table", res);
        })
        .catch((error) => {
          console.log("error:", error);
        });
    } else {
      console.log("All Emails are present in DB");
    }
    const info_count = Object.keys(contactDetails).length;

    for (let i = 0; i < info_count; i++) {
      setContactDetails((values) => ({
        ...values,
        [i]: {
          ...values[i],
          ["companyProfile"]: {
            ...values[i]["companyProfile"],
            ["disable_check"]: true,
          },
        },
      }));
    }

    const sendEngagedCampaignedData = async (agpsWorkStatus, saveOrSubmit) => {
      try {
        let submittedate = saveOrSubmit == "submit" ? agpsSubmittedDate : ""; // only on submit date we capturing
        let updateUnderWriterDetails = {};

        // setting the destination table where and all this prospect need to show.
        if (companyProfile["destination"]) {
          let destinationTable = {
            Fourth: "isFourth",
            Solve: "isSolve",
            Fuba: "isFuba",
          };

          for (let key of companyProfile["destination"]["value"])
            updateUnderWriterDetails[destinationTable[key.value]] = "true";
        }
        // preparing the source column
        let source = companyProfile?.["source"]?.["value"];
        updateUnderWriterDetails["source"] = source ? source?.toString() : "";
        // perparing the whole object to send to agent table
        updateUnderWriterDetails.domain = domain;
        updateUnderWriterDetails.companyProfile = companyProfile;
        updateUnderWriterDetails.payrollData =
          Object.keys(childrenLoc).length > 0 ? childrenLoc : undefined;
        updateUnderWriterDetails.email_specific =
          Object.keys(email_specific).length > 0 ? email_specific : undefined;
        updateUnderWriterDetails.agpsWorkStatus = agpsWorkStatus;
        updateUnderWriterDetails.agpsuserName = agpsuserName;
        updateUnderWriterDetails.agpsSubmittedDate = submittedate;
        updateUnderWriterDetails.affinity_hash_key = "true";
        updateUnderWriterDetails.UploadedDate = agpsSubmittedDate; // need to confirm
        // if you want to use Engaged and compaigned  conditionally uncomment this, so that any of you come through engaged or compained, data go to specific table.
        // await axios
        //   .post(
        //     awsUrl2 +
        //       `/api/agpsTableUpdate/${
        //         preUwEngagedFlag ? "updateTrackingData" : "updateData"
        //       }`,
        //     updateUnderWriterDetails
        //   )
        // only Saving the Data in Campainged  Table
        if (domain && companyProfile?.companyName?.value) {
          await axios
            .post(
              awsUrl2 + `/api/agpsTableUpdate/${"updateData"}`,
              updateUnderWriterDetails
            )
            .then((res) => {
              sessionStorage.clear();
              sessionStorage.setItem("domain", JSON.stringify(domain));
              sessionStorage.setItem(
                "companyProfile",
                JSON.stringify(companyProfile)
              );
              if (email_specific)
                sessionStorage.setItem(
                  "contactDetails",
                  JSON.stringify(email_specific)
                );
              if (childrenLoc)
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );

              setShowSubmittedMessage(true);
              if (saveOrSubmit === "save") {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else if (saveOrSubmit === "submit") {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Your work has been submitted",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((error) => {
              console.log("error in posting data to user tracking", error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
            });
        }

        console.log({ updateUnderWriterDetails });
      } catch (error) {
        console.log("error while updating before Pre underwriter ", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    };

    if (saveOrSubmit === "save") {
      if (companyProfile?.companyName?.value) {
        let agpsWorkStatus = "WIP";
        sendEngagedCampaignedData(agpsWorkStatus, saveOrSubmit);
      }
    } else if (saveOrSubmit === "submit") {
      if (companyProfile?.companyName?.value) {
        let agpsWorkStatus = "Completed";
        sendEngagedCampaignedData(agpsWorkStatus, saveOrSubmit);
      }
    }
  };
  // start new prospect on clear all the session storage data.

  const startNewProspect = () => {
    sessionStorage.clear();
    window.location.href = "/stageOne";
  };

  return (
    <>
      <div className="container-fluid">
        <Header />
        <hr></hr>
      </div>
      <div>
        <ul className="d-flex list-unstyled justify-content-end ">
          <li
            className="Dashboard__menucontent ml-2 mr-2"
            onClick={startNewProspect}
          >
            <Link to={"/stageOne"} className="text-dark">
              <b>Start New Prospect</b>
            </Link>
          </li>
          <li className="Dashboard__menucontent ml-2 mr-2">
            <Link to={"/AdminDashboard"} className="text-dark">
              <b>Dashboard</b>
            </Link>
          </li>
          <li
            id="Dropprofile"
            className="d-flex align-items-center text-dark  logout_menu ml-2 mr-2"
          >
            <span
              className="dropdown-toggle text-dark text-capitalize"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <b>{loginUserName}</b>
            </span>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={logout}>
                <b>Logout</b>
              </a>
            </div>
            {/* <DropUserProfile username={this.state.userName} /> */}
          </li>
        </ul>
      </div>
      <div
        className="container-fluid"
        id="preuwmain_container"
        style={{ backgroundColor: "#e2f2ff" }}
      >
        <main className="mb-3" style={{ width: "70%", margin: "0 auto" }}>
          <section>
            <div className="pink-header">
              <div className="font-family-montserrat-semi-bold mt-3 ml-3 ">
                Company Profile
              </div>
              {containerCloseOpen["showCompanyProfile"] ? (
                <span
                  className="pink-sign noselect mt-3 ml-3"
                  onClick={() => {
                    if (containerCloseOpen["showCompanyProfile"])
                      setContainerCloseOpen({
                        ...containerCloseOpen,
                        showCompanyProfile: false,
                      });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!containerCloseOpen["showCompanyProfile"])
                      setContainerCloseOpen({
                        ...containerCloseOpen,
                        showCompanyProfile: true,
                      });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )}
            </div>
            {containerCloseOpen["showCompanyProfile"] && (
              <div>
                <div className="row no-gutters">
                  <div className="col-lg-4 col-md-12">
                    <Form.Group className="ml-3">
                      <label htmlFor="preuw_companyName" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Company Name (Applicant Name)
                        </b>
                      </label>

                      <input
                        value={
                          companyProfile.companyName &&
                          companyProfile.companyName.value
                            ? companyProfile.companyName.value
                            : ""
                        }
                        style={
                          companyProfile.companyName &&
                          companyProfile.companyName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "companyName")
                        }
                        id="preuw_companyName"
                        type="text"
                        placeholder="Company Name"
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>

                  <div className="col-lg-8 col-md-12 pre_description">
                    <Form.Group className="ml-3">
                      <label
                        htmlFor="preuw_descriptionOfOperations"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Description of Operations
                        </b>
                      </label>

                      <textarea
                        value={
                          companyProfile.descriptionOfOperations &&
                          companyProfile.descriptionOfOperations.value
                            ? companyProfile.descriptionOfOperations.value
                            : ""
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "descriptionOfOperations")
                        }
                        style={
                          companyProfile.descriptionOfOperations &&
                          companyProfile.descriptionOfOperations.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="preuw_descriptionOfOperations"
                        type="text"
                        placeholder="Description of Operations"
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-3 col-md-12">
                    <Form.Group className="ml-3">
                      <label htmlFor="preuw_website" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Website
                        </b>
                      </label>

                      <input
                        id="preuw_website"
                        type="text"
                        placeholder="Website"
                        autoComplete="off"
                        disabled={
                          websiteButtonShow && sessionStorage.getItem("domain")
                        }
                        value={
                          companyProfile.website && companyProfile.website.value
                            ? companyProfile.website.value
                            : ""
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "website")
                        }
                        style={
                          companyProfile.website && companyProfile.website.error
                            ? { borderColor: "red" }
                            : {}
                        }
                      />
                      <br />
                      <a
                        style={{ color: "blue" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          companyProfile?.website?.value &&
                          (companyProfile.website.value.includes("://")
                            ? companyProfile.website.value
                            : "//" + companyProfile.website.value)
                        }
                      >
                        {companyProfile?.website &&
                          companyProfile?.website?.value}
                      </a>
                      {sessionStorage.getItem("domain") && (
                        <button
                          className="ml-2"
                          onClick={() => {
                            setWebsiteButtonShow((prevState) => !prevState);
                          }}
                        >
                          {websiteButtonShow ? "Edit" : "save"}
                        </button>
                      )}
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label htmlFor="preuw_fein" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">FEIN</b>
                      </label>
                      <input
                        value={
                          companyProfile?.fein && companyProfile?.fein?.value
                        }
                        onChange={(event) => {
                          updateCompanyProfile(event, "fein");
                        }}
                        style={
                          companyProfile?.fein && companyProfile?.fein?.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="preuw_fein"
                        type="text"
                        placeholder="FEIN"
                        autoComplete="off"
                        onInput={(event) => {
                          event.target.value = event.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");
                        }}
                        onBlur={(event) => {
                          var value = event.target.value.toString().trim();
                          value = value.split("-").join("");
                          var len = value.length,
                            dummystr = "000000000";
                          if (companyProfile && companyProfile.fein) {
                            if (len > 0 && len < 9) {
                              value = dummystr.slice(0, 9 - len) + "" + value;
                              companyProfile.fein.value =
                                value[0] + value[1] + "-" + value.slice(2);
                              companyProfile.fein.errMsg = "";
                            } else if (len > 9) {
                              companyProfile.fein.errMsg =
                                "FEIN needs to be 9 digits";
                              companyProfile.fein.error = true;
                            } else if (len === 0) {
                              companyProfile.fein.value = undefined;
                              companyProfile.fein.errMsg = "";
                            } else {
                              companyProfile.fein.value =
                                value[0] + value[1] + "-" + value.slice(2);
                              companyProfile.fein.errMsg = "";
                            }
                          }
                          setCompanyProfile({ ...companyProfile });
                        }}
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="preuw_entity-type" className="col-lg-3 col-md-12">
                    <Form.Group>
                      <Form.Label htmlFor="entityType" className="mandatory">
                        <b className="font-family-montserrat-semi-bold ">
                          Entity Type
                        </b>
                      </Form.Label>
                      <select
                        id="preuw_entityType"
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        value={
                          companyProfile?.entityType &&
                          companyProfile?.entityType?.value
                            ? companyProfile?.entityType?.value
                            : ""
                        }
                        style={
                          companyProfile?.entityType &&
                          companyProfile?.entityType?.error
                            ? { borderColor: "red" }
                            : { fontSize: "16px" }
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "entityType")
                        }
                      >
                        <option key={1} value={undefined}>
                          {" "}
                          Please Select{" "}
                        </option>
                        <option key={2} value={"Corporation"}>
                          {" "}
                          Corporation{" "}
                        </option>
                        <option key={3} value={"Joint Venture"}>
                          {" "}
                          Joint Venture{" "}
                        </option>
                        <option key={4} value={"LLC"}>
                          {" "}
                          LLC{" "}
                        </option>
                        <option key={5} value={"SubCharter 'S' corp"}>
                          {" "}
                          SubCharter 'S' corp{" "}
                        </option>
                        <option key={6} value={"Not for Profit 501[c][3]"}>
                          {" "}
                          Not for Profit 501[c][3]{" "}
                        </option>
                        <option key={7} value={"Other"}>
                          {" "}
                          Other{" "}
                        </option>
                        <option key={8} value={"Partnership"}>
                          {" "}
                          Partnership{" "}
                        </option>
                        <option key={9} value={"Sole Proprietor"}>
                          {" "}
                          Sole Proprietor{" "}
                        </option>
                        <option key={10} value={"Trust"}>
                          {" "}
                          Trust{" "}
                        </option>
                      </select>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="years-in-business" className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="preuw_yearsInBusiness"
                        title="Years in Business"
                        className={` mandatory`}
                      >
                        <b className="font-family-montserrat-semi-bold text-nowrap ">
                          Years in Business
                        </b>
                      </label>
                      <span id="preuw_yearsInBusiness-span">
                        <NumberFormat
                          autoComplete="off"
                          id="preuw_yearsInBusiness"
                          format="####"
                          type="text"
                          value={
                            companyProfile.yearsInBusiness &&
                            companyProfile.yearsInBusiness.value
                              ? companyProfile.yearsInBusiness.value
                              : ""
                          }
                          onChange={(event) =>
                            updateCompanyProfile(event, "yearsInBusiness")
                          }
                          style={
                            companyProfile.yearsInBusiness &&
                            companyProfile.yearsInBusiness.error
                              ? { borderColor: "red", textAlign: "center" }
                              : { textAlign: "center" }
                          }
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="expected-start-date" className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="pre_expectedStartDate"
                        title="Expected Start Date"
                        className={` mandatory`}
                      >
                        <b className="font-family-montserrat-semi-bold ">
                          Expected Start Date
                        </b>
                      </label>

                      <span>
                        <DatePicker
                          id="preuw_expectedStartDate"
                          selected={
                            companyProfile.expectedStartDate
                              ? companyProfile?.expectedStartDate?.value
                              : moment()
                          }
                          onChange={handleDateChangeEff}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </span>

                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-3 col-md-12 ">
                    <Form.Group className="ml-3">
                      <label htmlFor="preuw_Source" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Source
                        </b>
                      </label>

                      <select
                        value={
                          companyProfile.source && companyProfile.source.value
                            ? companyProfile.source.value
                            : ""
                        }
                        style={
                          companyProfile.source && companyProfile.source.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "source")
                        }
                        disabled={isSourcePresent}
                        id="preuw_Source"
                        type="text"
                        placeholder="Source Name"
                        autoComplete="off"
                      >
                        <option key={1} value={undefined}>
                          Please Select
                        </option>
                        <option key={2} value={"hotschedules"}>
                          Hotschedule
                        </option>
                        <option key={3} value={"affinity"}>
                          Affinity
                        </option>
                      </select>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-md-12 ">
                    <Form.Group>
                      <label htmlFor="preuw_destination" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Destination Tables
                        </b>
                      </label>

                      <MultiSelect
                        value={
                          companyProfile?.destination &&
                          companyProfile?.destination.value
                            ? companyProfile?.destination?.value
                            : ""
                        }
                        style={
                          companyProfile.destination &&
                          companyProfile.destination.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          updateDestinationHandler(event, "destination")
                        }
                        // disabled={true}
                        id="preuw_destination"
                        placeholder="Destination"
                        autoComplete="off"
                        options={[
                          { label: "Fourth ", value: "Fourth" },
                          { label: "Solve", value: "Solve" },
                          { label: "Fuba", value: "Fuba" },
                        ]}
                        labelledBy="Select"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                    {companyProfile.destination &&
                      companyProfile.destination.error && (
                        <p style={{ color: "red" }}>
                          Please Select Destination Table
                        </p>
                      )}
                  </div>
                  <div className="col-lg-3 col-md-12  ">
                    <Form.Group className="ml-4">
                      <label htmlFor="preuw_domainName">
                        <b className="font-family-montserrat-semi-bold">
                          Domain Name
                        </b>
                      </label>

                      <input
                        value={
                          companyProfile.domain && companyProfile.domain.value
                            ? companyProfile.domain.value
                            : ""
                        }
                        disabled={isdomain}
                        style={
                          (companyProfile.domain && companyProfile.domain.error
                            ? { borderColor: "red" }
                            : {},
                          CheckDomainExistinDb ? { borderColor: "red" } : {},
                          CheckDomainNameSpecialCharacter
                            ? { borderColor: "red" }
                            : {})
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "domain")
                        }
                        onBlur={(event) => {
                          checkDominExistInDatabaseHandler(event.target.value);
                          sessionStorage.setItem(
                            "domain",
                            JSON.stringify(event.target.value)
                          );
                        }}
                        id="preuw_domain"
                        type="text"
                        placeholder="Domain Name"
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                      {CheckDomainExistinDb ? (
                        <p style={{ color: "red" }}>
                          Domain Name Already Exist
                        </p>
                      ) : (
                        ""
                      )}
                      {CheckDomainNameSpecialCharacter ? (
                        <p>Please remove the Special Charater</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className="row-col-2 mb-0 no-gutters">
                  <Form.Group
                    style={{ marginBottom: "19.95px" }}
                    className="ml-3"
                  >
                    <b className="font-family-montserrat-semi-bold">
                      Primary Location
                    </b>
                  </Form.Group>
                </div>
                <div className="row no-gutters">
                  <div id="preuw_street-1" className="col-lg-5 col-md-12">
                    <Form.Group className="ml-3">
                      <label htmlFor="street1" className={`mandatory`}>
                        <b className="font-family-montserrat-semi-bold">
                          Street Address
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.street1 && companyProfile.street1.value
                            ? companyProfile.street1.value
                            : ""
                        }
                        id="preuw_street1"
                        type="text"
                        style={
                          companyProfile.street1 && companyProfile.street1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "street1")
                        }
                        placeholder="Street Address"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="preuw_zip-1" className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor={"zipCode1"}
                        className={"mandatory"}
                        title="Zip Code"
                      >
                        <b className="font-family-montserrat-semi-bold">Zip</b>
                      </label>
                      <NumberFormat
                        name="zipCode1"
                        id={"preuw_zipCode1"}
                        format="#####"
                        type="text"
                        onChange={(event) =>
                          updateCompanyProfile(event, "zipCode1")
                        }
                        value={
                          companyProfile.zipCode1
                            ? companyProfile.zipCode1.value
                            : ""
                        }
                        onBlur={(event) => setStateAndCity1(event.target.value)}
                        style={
                          companyProfile.zipCode1 &&
                          companyProfile.zipCode1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="Zip"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div id="preuw_city-1" className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor={"cityName1"}
                        className={`mandatory`}
                        title="City"
                      >
                        <b className="font-family-montserrat-semi-bold">City</b>
                      </label>
                      <input
                        id={"preuw_cityName1"}
                        name="cityName1"
                        type="text"
                        onChange={(event) =>
                          updateCompanyProfile(event, "cityName1")
                        }
                        value={
                          companyProfile.cityName1
                            ? companyProfile.cityName1.value
                            : ""
                        }
                        style={
                          companyProfile.cityName1 &&
                          companyProfile.cityName1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="City Name"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div id="preuw_state-1" className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label className={` mandatory`} title="State">
                        <b className="font-family-montserrat-semi-bold">
                          State
                        </b>
                      </label>
                      <select
                        id={"preuw_state1"}
                        name="state1"
                        type="text"
                        onChange={(event) =>
                          updateCompanyProfile(event, "state1")
                        }
                        value={
                          companyProfile.state1 && companyProfile.state1.value
                            ? companyProfile.state1.value.toLowerCase()
                            : ""
                        }
                        style={
                          companyProfile.state1 && companyProfile.state1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        autoComplete="off"
                      >
                        {allStates1}
                      </select>
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                </div>
                <div className="row-col-2 no-gutters">
                  <Form.Group>
                    <div className="row no-gutters">
                      <b
                        className="font-family-montserrat-semi-bold ml-2 mt-2"
                        style={{ paddingInline: "15px" }}
                      >
                        Mailing Address
                      </b>
                      <div className="col-9 d-flex align-items-center">
                        <input
                          className="mr-1"
                          type="checkbox"
                          id="sameAsPrimary-1"
                          name={"sameAsPrimary"}
                          checked={sameAsPrimary}
                          onClick={handleSameAsPrimaryCheck}
                        />
                        <label htmlFor="sameAsPrimary-1" className="text-wrap">
                          <small>Same as Physical Address</small>
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="row no-gutters">
                  <div id="preuw_street-2" className=" col-lg-5 col-md-12">
                    <Form.Group className="ml-3">
                      <label htmlFor="street2" className={"mandatory"}>
                        <b className="font-family-montserrat-semi-bold">
                          Street Address
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.street2 && companyProfile.street2.value
                            ? companyProfile.street2.value
                            : ""
                        }
                        id="preuw_street2"
                        type="text"
                        style={
                          companyProfile.street2 && companyProfile.street2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          updateCompanyProfile(event, "street2")
                        }
                        placeholder="Street Address"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="preuw_zip-2" className=" col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor={"zipCode2"}
                        className={"mandatory"}
                        title="Zip Code"
                      >
                        <b className="font-family-montserrat-semi-bold">Zip</b>
                      </label>
                      <NumberFormat
                        name="zipCode2"
                        id={"preuw_zipCode2"}
                        format="#####"
                        type="text"
                        onChange={(event) =>
                          updateCompanyProfile(event, "zipCode2")
                        }
                        value={
                          companyProfile.zipCode2
                            ? companyProfile.zipCode2.value
                            : ""
                        }
                        style={
                          companyProfile.zipCode2 &&
                          companyProfile.zipCode2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onBlur={(event) => setStateAndCity2(event.target.value)}
                        placeholder="Zip"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div id="preuw_city-2" className=" col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor={"cityName2"}
                        className={`mandatory`}
                        title="City"
                      >
                        <b className="font-family-montserrat-semi-bold">City</b>
                      </label>
                      <input
                        id={"preuw_cityName2"}
                        name="cityName2"
                        type="text"
                        onChange={(event) =>
                          updateCompanyProfile(event, "cityName2")
                        }
                        value={
                          companyProfile.cityName2 &&
                          companyProfile.cityName2.value
                            ? companyProfile.cityName2.value
                            : ""
                        }
                        style={
                          companyProfile.cityName2 &&
                          companyProfile.cityName2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="City Name"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div id="preuw_state-2" className=" col-lg-2 col-md-12">
                    <Form.Group>
                      <label className={` mandatory`} title="State">
                        <b className="font-family-montserrat-semi-bold">
                          State
                        </b>
                      </label>
                      <select
                        id={"preuw_state2"}
                        name="state2"
                        type="text"
                        onChange={(event) =>
                          updateCompanyProfile(event, "state2")
                        }
                        value={
                          companyProfile.state2 && companyProfile.state2.value
                            ? companyProfile.state2.value.toLowerCase()
                            : ""
                        }
                        style={
                          companyProfile.state2 && companyProfile.state2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        autoComplete="off"
                      >
                        {allStates2}
                      </select>
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                </div>
                <div className="row no-gutters notes-row">
                  <Form.Group className="ml-1 mt-2">
                    <div className="comments">
                      <label>
                        <b className="font-family-montserrat-semi-bold">
                          {" "}
                          Comments
                        </b>
                      </label>
                      <textarea
                        rows="3"
                        onChange={(event) =>
                          updateCompanyProfile(event, "agpsComment")
                        }
                        value={
                          companyProfile?.agpsComment &&
                          companyProfile?.agpsComment.value
                            ? companyProfile?.agpsComment?.value
                            : ""
                        }
                        style={
                          companyProfile.agpsComment &&
                          companyProfile.agpsComment.error
                            ? { borderColor: "red", width: "100%" }
                            : { width: "100%" }
                        }
                      ></textarea>
                    </div>
                  </Form.Group>
                </div>
              </div>
            )}
          </section>
          <hr />
          <section>
            <div className="pink-header">
              <div className="font-family-montserrat-semi-bold mt-3 ml-3">
                Contact Details
              </div>
              {containerCloseOpen["showContactDetails"] ? (
                <span
                  className="pink-sign noselect mt-3 ml-3"
                  onClick={() => {
                    if (containerCloseOpen["showContactDetails"])
                      setContainerCloseOpen({
                        ...containerCloseOpen,
                        showContactDetails: false,
                      });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!containerCloseOpen["showContactDetails"])
                      setContainerCloseOpen({
                        ...containerCloseOpen,
                        showContactDetails: true,
                      });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )}
            </div>

            {containerCloseOpen["showContactDetails"] && (
              <div>
                {contactDetails &&
                  Object.entries(contactDetails).map(([key, value], index) => (
                    <ContactDetails
                      isdomain={isdomain}
                      specificEmailKey={key}
                      contactDetails={value}
                      updateContactDetails={updateContactDetails}
                      deleteContactDetailsHandler={deleteContactDetailsHandler}
                      emailValidatecheck={emailValidatecheck}
                    />
                  ))}
                <div className="mt-2 ml-3 mb-3">
                  <button
                    // disabled={isdomain}
                    className="btnInvisible addLoc transpButton p-0 btn"
                    onClick={showAddtionalContactDetailsHandler}
                  >
                    <img src={require("../../images/plus4.png")} />
                    <span>&nbsp;&nbsp;</span>
                    <b>Add Contact Details</b>
                  </button>
                </div>
              </div>
            )}
          </section>
          <hr />
          <section>
            <div className="pink-header">
              <div className="font-family-montserrat-semi-bold mt-3 ml-3">
                Locations &amp; Payroll
              </div>
              {containerCloseOpen["showLocationAndPayroll"] ? (
                <span
                  className="pink-sign noselect mt-3 ml-3"
                  onClick={() => {
                    if (containerCloseOpen["showLocationAndPayroll"])
                      setContainerCloseOpen({
                        ...containerCloseOpen,
                        showLocationAndPayroll: false,
                      });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!containerCloseOpen["showLocationAndPayroll"])
                      setContainerCloseOpen({
                        ...containerCloseOpen,
                        showLocationAndPayroll: true,
                      });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )}
            </div>
            {containerCloseOpen["showLocationAndPayroll"] && (
              <>
                <div className="row mb-3 no-gutters">
                  <h6 className="col-lg-12">
                    <b className="pl-3">Class Code Information</b>
                  </h6>
                </div>
                {childrenLoc &&
                  Object.entries(childrenLoc).map(
                    ([locatioNumber, childrenLocs]) => (
                      <>
                        <div id="addressDiv">
                          <p>
                            <b className="pl-3">
                              Location: {+locatioNumber + 1}
                              {locatioNumber == 0 && (
                                <>
                                  <input
                                    className="ml-3 mt-1"
                                    type="checkbox"
                                    id="sameAsPrimary-1"
                                    name={"sameAsPrimary"}
                                    checked={locSameAsPrimary}
                                    onClick={() =>
                                      locationAndPayrollSameAsPrimaryLoc(
                                        locatioNumber
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor="locPayrollSameAsPrimary"
                                    className="text-wrap"
                                  >
                                    <small>Same as Physical Address</small>
                                  </label>
                                </>
                              )}
                            </b>
                          </p>

                          <div className="row no-gutters">
                            <div
                              id="preuw_street"
                              className=" col-lg-5 col-md-12"
                            >
                              <Form.Group className="ml-3">
                                <label htmlFor="street" className={"mandatory"}>
                                  <b className="font-family-montserrat-semi-bold">
                                    Street Address
                                  </b>
                                </label>
                                <input
                                  value={
                                    childrenLocs?.street &&
                                    childrenLocs?.street?.value
                                      ? childrenLocs?.street?.value
                                      : ""
                                  }
                                  id="preuw_street"
                                  type="text"
                                  style={
                                    childrenLocs?.street &&
                                    childrenLocs?.street?.error
                                      ? { borderColor: "red" }
                                      : {}
                                  }
                                  onChange={(event) =>
                                    updateLocationAndPayroll(
                                      event,
                                      locatioNumber,
                                      "street"
                                    )
                                  }
                                  placeholder="Street Address"
                                  autoComplete="off"
                                  className="rectangle1"
                                />
                                <p className="errMsg" />
                              </Form.Group>
                            </div>
                            <div
                              id="preuw_zip-2"
                              className=" col-lg-2 col-md-12"
                            >
                              <Form.Group>
                                <label
                                  htmlFor={"zipCode2"}
                                  className={"mandatory"}
                                  title="Zip Code"
                                >
                                  <b className="font-family-montserrat-semi-bold">
                                    Zip
                                  </b>
                                </label>
                                <NumberFormat
                                  name="zipCode"
                                  id="preuw_zipCode"
                                  format="#####"
                                  type="text"
                                  onChange={(event) =>
                                    updateLocationAndPayroll(
                                      event,
                                      locatioNumber,
                                      "zipCode"
                                    )
                                  }
                                  value={
                                    childrenLocs?.zipCode
                                      ? childrenLocs?.zipCode?.value
                                      : ""
                                  }
                                  style={
                                    childrenLocs?.zipCode &&
                                    childrenLocs?.zipCode?.error
                                      ? { borderColor: "red" }
                                      : {}
                                  }
                                  onBlur={(event) =>
                                    setStateAndCity3(
                                      event.target.value,
                                      locatioNumber
                                    )
                                  }
                                  placeholder="Zip"
                                  autoComplete="off"
                                />
                                <p className="errMsg addressErrs" />
                              </Form.Group>
                            </div>
                            <div
                              id="preuw_city"
                              className=" col-lg-2 col-md-12"
                            >
                              <Form.Group>
                                <label
                                  htmlFor={"cityName"}
                                  className={`mandatory`}
                                  title="City"
                                >
                                  <b className="font-family-montserrat-semi-bold">
                                    City
                                  </b>
                                </label>
                                <input
                                  id={"preuw_cityName"}
                                  name="cityName"
                                  type="text"
                                  onChange={(event) =>
                                    updateLocationAndPayroll(
                                      event,
                                      locatioNumber,
                                      "cityName"
                                    )
                                  }
                                  value={
                                    childrenLocs?.cityName &&
                                    childrenLocs?.cityName?.value
                                      ? childrenLocs?.cityName?.value
                                      : ""
                                  }
                                  style={
                                    childrenLocs?.cityName &&
                                    childrenLocs?.cityName?.error
                                      ? { borderColor: "red" }
                                      : {}
                                  }
                                  placeholder="City Name"
                                  autoComplete="off"
                                />
                                <p className="errMsg addressErrs" />
                              </Form.Group>
                            </div>
                            <div
                              id="preuw_state"
                              className=" col-lg-2 col-md-12"
                            >
                              <Form.Group>
                                <label className={` mandatory`} title="State">
                                  <b className="font-family-montserrat-semi-bold">
                                    State
                                  </b>
                                </label>
                                <select
                                  id={"preuw_state"}
                                  name="state"
                                  type="text"
                                  onChange={(event) =>
                                    updateLocationAndPayroll(
                                      event,
                                      locatioNumber,
                                      "state"
                                    )
                                  }
                                  value={
                                    childrenLocs?.state &&
                                    childrenLocs?.state?.value
                                      ? childrenLocs?.state?.value.toLowerCase()
                                      : ""
                                  }
                                  style={
                                    childrenLocs?.state &&
                                    childrenLocs?.state?.error
                                      ? { borderColor: "red" }
                                      : {}
                                  }
                                  autoComplete="off"
                                >
                                  {allStates2}
                                </select>
                                <p className="errMsg addressErrs" />
                              </Form.Group>
                            </div>
                            <div className=" col-lg-1 col-md-12">
                              <button
                                type="button"
                                className="rmvLoc btnInvisible transpButton mt-3 btn "
                                onClick={() => {
                                  deleteAdditionalLocationHandler(
                                    locatioNumber
                                  );
                                }}
                              >
                                <img
                                  src={require("../../images/delete4.png")}
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>

                          {Object.entries(childrenLocs.classCodesInfo).map(
                            ([addressLocation, classCodesInfo]) => (
                              <div className="row no-gutters">
                                <div className=" col-lg-5 col-md-12">
                                  <Form.Group className="ml-3">
                                    <label
                                      title="Number of full time employees"
                                      for="preuwFullTime"
                                    >
                                      <b className="font-family-montserrat-semi-bold text-nowrap">
                                        Workers’ Comp Class Code or Description
                                        *
                                      </b>
                                    </label>
                                    <AsyncSelect
                                      id="preuw_workerComp"
                                      defaultOptions
                                      placeholder="Enter 3 characters"
                                      value={
                                        classCodesInfo &&
                                        classCodesInfo?.classCodeDescription &&
                                        classCodesInfo?.classCodeDescription
                                      }
                                      onChange={(event) => {
                                        updateWorkerCompDesc(
                                          event,
                                          locatioNumber,
                                          addressLocation,
                                          "classCodeDescription"
                                        );
                                      }}
                                      loadOptions={(inputValue, callback) =>
                                        debouncedLoadOptions(
                                          inputValue,
                                          callback,
                                          childrenLocs?.state?.value
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className=" col-lg-1 col-md-12">
                                  <Form.Group>
                                    <label
                                      title="Number of full time employees"
                                      for="preuwFullTime"
                                    >
                                      <b className="font-family-montserrat-semi-bold text-nowrap">
                                        # Full-Time
                                      </b>
                                    </label>
                                    <NumberFormat
                                      title="Number of full time employees"
                                      id="preuw_FullTime"
                                      isNumericString={true}
                                      name="FT"
                                      className="text-center"
                                      format="#####"
                                      placeholder="# FT"
                                      type="text"
                                      autoComplete="off"
                                      value={
                                        classCodesInfo &&
                                        classCodesInfo?.ft &&
                                        classCodesInfo?.ft?.value
                                      }
                                      onChange={(event) => {
                                        updateWorkerCompRow(
                                          event,
                                          locatioNumber,
                                          addressLocation,
                                          "ft"
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                                <div className=" col-lg-1 col-md-12">
                                  <Form.Group>
                                    <label
                                      for="preuwPartTime"
                                      title="Number of part time employees"
                                    >
                                      <b className="font-family-montserrat-semi-bold">
                                        # Part-Time
                                      </b>
                                    </label>
                                    <NumberFormat
                                      title="Number of part time employees"
                                      id="preuw_partTime"
                                      disabled={false}
                                      name="PT"
                                      className="text-center"
                                      format="#####"
                                      placeholder="# PT"
                                      type="text"
                                      autoComplete="off"
                                      value={
                                        classCodesInfo &&
                                        classCodesInfo?.pt &&
                                        classCodesInfo?.pt?.value
                                      }
                                      onChange={(event) => {
                                        updateWorkerCompRow(
                                          event,
                                          locatioNumber,
                                          addressLocation,
                                          "pt"
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                                <div className=" col-lg-2 col-md-12">
                                  <Form.Group>
                                    <label
                                      for="preuwAnnualPayroll"
                                      title="Annual Payroll"
                                    >
                                      <b className="font-family-montserrat-semi-bold">
                                        Annual Payroll *
                                      </b>
                                    </label>
                                    <NumberFormat
                                      title="Annual Payroll"
                                      id="preuw_AnnualPayroll"
                                      disabled={false}
                                      name="payroll"
                                      placeholder="Annual Payroll"
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className="text-center"
                                      type="text"
                                      autoComplete="off"
                                      value={
                                        classCodesInfo &&
                                        classCodesInfo?.payroll &&
                                        classCodesInfo?.payroll?.value
                                      }
                                      onKeyDown={(event) => {
                                        if (
                                          event.key === "-" ||
                                          event.key === "−"
                                        ) {
                                          event.preventDefault(); // Prevent typing the '-' character
                                        }
                                      }}
                                      onChange={(event) => {
                                        updateWorkerCompRow(
                                          event,
                                          locatioNumber,
                                          addressLocation,
                                          "payroll"
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </div>
                                <div className=" col-lg-2 col-md-12">
                                  <Form.Group>
                                    <label for="PreuwphoneNumber">
                                      <b className="font-family-montserrat-semi-bold">
                                        Phone Number
                                      </b>
                                    </label>
                                    <NumberFormat
                                      autoComplete="off"
                                      id="Preuw_phoneNumber"
                                      format="(###) ###-####"
                                      placeholder="(000) 000-0000"
                                      type="text"
                                      value={
                                        classCodesInfo &&
                                        classCodesInfo?.preuwPhone &&
                                        classCodesInfo?.preuwPhone?.value
                                      }
                                      onChange={(event) => {
                                        updateWorkerCompRow(
                                          event,
                                          locatioNumber,
                                          addressLocation,
                                          "preuwPhone"
                                        );
                                      }}
                                    />
                                    <p className="errMsg" />
                                  </Form.Group>
                                </div>
                                {Object.keys(childrenLocs.classCodesInfo)
                                  .length > 1 && (
                                  <div className=" col-lg-1 col-md-12">
                                    <button
                                      type="button"
                                      className="rmvLoc btnInvisible transpButton mt-3 btn "
                                      onClick={() => {
                                        deleteClasscodeHandler(
                                          locatioNumber,
                                          addressLocation
                                        );
                                      }}
                                    >
                                      <img
                                        src={require("../../images/delete4.png")}
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        <div className="row no-gutters">
                          <div className="calculator ml-3 mt-3 mb-3">
                            <div>
                              <img src={require("../../images/plus4.png")} />
                              <span>&nbsp;&nbsp;</span>
                              <button
                                type="button"
                                className="btnInvisible addLoc transpButton p-0 btn"
                                onClick={() =>
                                  addNewClassCodeHandler(locatioNumber)
                                }
                              >
                                <b>Add class code</b>
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    )
                  )}

                <div className="row no-gutters">
                  <div className="calculator ml-3 mt-3 mb-3">
                    <div>
                      <button
                        type="button"
                        className="btnInvisible addLoc transpButton p-0 btn"
                        onClick={addNewLocationHandler}
                      >
                        <img src={require("../../images/plus4.png")} />
                        <span>&nbsp;&nbsp;</span>
                        <b> Add New Location</b>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
          <hr />
          <section>
            <UploadDashboardFile preuwFile={true} />
          </section>
          <hr />
          {submitError ? (
            <h6
              className="d-flex justify-content-center mt-2 mb-2"
              style={{ color: "red" }}
            >
              <b>Error in data.</b>
            </h6>
          ) : showSubmittedMessage ? (
            <h6 className="d-flex justify-content-center mt-2 mb-2">
              <b>
                <i>Successfull.</i>
              </b>
            </h6>
          ) : (
            ""
          )}
          <section>
            <div class="d-flex justify-content-center mt-3 mb-3 ">
              <button
                className="btn btn-md mr-3 mb-3"
                style={{
                  width: "160px",
                  backgroundColor: "#8ab93f",
                  color: "white",
                }}
                disabled={CheckDomainExistinDb}
                onClick={() => submitHandler("save")}
              >
                Save
              </button>
              <button
                className="btn btn-md ml-3 mb-3"
                disabled={CheckDomainExistinDb}
                style={{
                  width: "160px",
                  backgroundColor: "#8ab93f",
                  color: "white",
                }}
                onClick={() => submitHandler("submit")}
              >
                submit
              </button>
            </div>
            <div> </div>
          </section>
          <hr />
        </main>
      </div>
    </>
  );
};
export default Preuwstageonepage;
