import React, { Component } from "react";

class QuoteRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: undefined,
    };
  }

  componentDidMount() {
    this.setState({
      selected: this.props.quoteRows[this.props.peo][this.props.carrier]
        .selected,
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("prevProps: ", prevProps);
  //   console.log("this pro: ", this.props);
  //   if (
  //     prevProps.quoteRows[prevProps.peo][prevProps.carrier].selected !==
  //     this.props.quoteRows[this.props.peo][this.props.carrier].selected
  //   ) {
  //     this.setState({
  //       selected: this.props.quoteRows[this.props.peo][this.props.carrier]
  //         .selected,
  //     });
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.quoteRows[this.props.peo][this.props.carrier].selected !==
      prevState.selected
    ) {
      this.setState({
        selected: this.props.quoteRows[this.props.peo][this.props.carrier]
          .selected,
      });
    }
  }

  render() {
    return (
      <tr key={this.props.index} className="quote-row">
        <td className="pl-3 pt-3 pb-3 programattributesfirstrow">
          <input
            type="checkbox"
            checked={this.state.selected}
            onChange={(e) =>
              this.props.flipCheckbox(
                this.props.peo,
                this.props.carrier,
                !this.state.selected
              )
            }
          />
        </td>
        <td className="pl-3 pt-3 pb-3 imagesresponsives ">
          {this.props.first}
        </td>
        <td className="pl-3 pt-3 pb-3 Sutaficafuta ">{this.props.second}</td>

        <td className="pl-3 pt-3 pb-3 pr-3 programattributes">
          {this.props.third}
        </td>
      </tr>
    );
  }
}
export default QuoteRow;
