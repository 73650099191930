import React from "react";
import insurecompLogo from "../../../images/new-logo.png";
import affinityLogo from "../../../images/AffinityTech-InsureComp.png";

function GridBanner() {
  return (
    <div className="grid-banner">
      <div className="grid-banner-header text-center">DEMO</div>
      <div className="grid-banner-logo-container d-flex justify-content-between">
        <img
          className="affinity-logo"
          src={affinityLogo}
          alt="Affinity Group Logo"
        />
        <img
          className="insurecomp-logo"
          src={insurecompLogo}
          alt="InsureComp Logo"
        />
      </div>
    </div>
  );
}

export default GridBanner;
