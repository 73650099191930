import { tempURL, apiURL, localURL, awsUrl } from "../config";
import { setCookie } from "./session_data";
import { Auth } from "aws-amplify";
import axios from "axios";
import $ from "jquery";
import { from } from "zen-observable";
import { getUserAuthStatus, showErrAlert } from "./common";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

export async function signIn(e, getLoggedInUser) {
  e.preventDefault();
  let validForm = validateForm();
  if (validForm) {
    $("#loader").css("display", "block");
    const username = $("#userId").val();
    const password = $("#userPassword").val();
    try {
      var userTemp = await Auth.signIn(username.toLowerCase(), password);

      console.log("UserTemp: ", userTemp);
      if (userTemp.challengeName === "NEW_PASSWORD_REQUIRED") {
        $("#link").click();
        $("#loader").css("display", "none");
      } else {
        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("DemoAdmin")
        ) {
          if (getLoggedInUser) {
            getLoggedInUser();
          }
          $("#loader").css("display", "none");
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_AGPSADMIN_GROUP)
        ) {
          console.log(
            "User Role",
            userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./AdminDashboard";
          $("#loader").css("display", "none");
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_AGPSAGENT_GROUP)
        ) {
          console.log(
            "User Role",
            userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./AdminDashboard";
          $("#loader").css("display", "none");
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ADMIN_GROUP)
        ) {
          console.log(
            "User Role",
            userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./AdminDashboard";
          $("#loader").css("display", "none");
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_CARRIER_GROUP)
        ) {
          console.log(
            "User Role",
            userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./PanelUW";
          // $('#loader').css('display','none');
        } else if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_AGENT_GROUP)
        ) {
          let user_email_id = userTemp.username;
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./AdminDashboard";
        } else if (
          userTemp.signInUserSession !== null &&
          (userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("Compliance") ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes("ComplianceAdmin"))
        ) {
          // let user_email_id = userTemp.username;
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = "./PanelCompliance";
        } else {
          Auth.signOut()
            .then(function(res) {})
            .catch((err) => {
              console.log("error in logout: ", err);
            });
          let errMsg;
          $("#loader").css("display", "none");
          errMsg = "User does not exist!";
          $("#loginSubmit")
            .siblings(".errMsg")
            .html("<br /> " + errMsg);
        }
        console.log("In loged in page: ", userTemp);
      }
    } catch (err) {
      let errMsg;
      $("#loader").css("display", "none");
      console.log("error signing in! :", err);
      if (
        err.code === "UserNotFoundException" ||
        err.code === "NotAuthorizedException"
      ) {
        errMsg = err.message;
      } else {
        errMsg =
          "Unable to login! Please contact admin@insurecomp.com for more details!";
      }
      $("#loginSubmit")
        .siblings(".errMsg")
        .html("<br /> " + errMsg);
    }
  }
}

function validateForm() {
  var form = true;
  if (
    $("#userId").val() === "" ||
    document.getElementById("userId").style.borderColor === "red"
  ) {
    $("#userId").css("border-color", "rgb(255, 0, 0)");
    $("#userId")
      .siblings(".errMsg")
      .html("Email is required");
    $("#userId").focus();
    form = false;
  }
  if (
    $("#userPassword").val() === "" ||
    document.getElementById("userId").style.borderColor === "red"
  ) {
    $("#userPassword").css("border-color", "rgb(255, 0, 0)");
    $("#userPassword")
      .siblings(".errMsg")
      .html("Password is required");
    $("#userPassword").focus();
    form = false;
  }
  return form;
}
